/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery($installmentId: ID!) {
    InstallmentRead(installmentId: $installmentId) {
      message
      id
      status
      amount
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

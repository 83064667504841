/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  query MyQuery($accountId: ID) {
    ProfileDetail(accountId: $accountId) {
      message
      status
      email
      fullName
      id
      AccountProject {
        projectName
        projectType
        updatedAt
        id
        BillingInfo {
          id
          message
          status
        }
        UserAdditionalInfo {
          id
          developmentDuration
          message
          status
        }
        Project {
          message
          status
          accountProjectId
          installmentId
          Journey {
            id
            Features {
              displayName
              description
              id
              message
              status
              thumbnailStoredAt
              Feature {
                days
                description
                displayName
                id
                message
                status
                thumbnailStoredAt
                FeatureCost {
                  cost
                  customisedCost
                  id
                  message
                  status
                }
              }
            }
          }
        }
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

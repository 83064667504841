/*
 * IMPORTS
 */
import React from "react";
import {formatCurrency} from "components/CurrencyFormater";


/*
 * CHAKRA UI
 */
import {
  Flex,
  Modal,
  ModalContent,
  Image,
  Text,
  ModalOverlay,
  Button,
  Spinner,
} from "@chakra-ui/react";

/*
 * ICONS AND IMAGES
 */
import { IoClose } from "react-icons/io5";
import Bot from "assets/svg/tool/Bot.svg";
import dummy from "assets/img/dummy.jpeg";

/*
 * PACKAGES
 */
import FeatureAccordian from "components/FeatureAccordian";

function Index({
  isOpen,
  onClose,
  onOpen,
  data,
  totalFeaturesCost,
  totalFeatures,
  _handleSaveInRedux,
}) {


  return (
    <Modal isCentered isOpen={isOpen} size="full" onClose={onClose}>
      <ModalOverlay />
      <ModalContent height="100vh">
        <Flex
          position="relative"
          bg="rgba(222, 223, 226, 0.5)"
          boxShadow="0 2px 12px 0 rgb(0 0 0 / 16%)"
          m="50px"
          h="100%"
          borderRadius="20px 0px 20px 0px"
        >
          <Flex
            cursor="pointer"
            onClick={onClose}
            position="absolute"
            top="0"
            right="0"
            justify="center"
            align="center"
            w="50px"
            h="50px"
          >
            <IoClose size="40px" />
          </Flex>
          <Flex w="100%" h="100%">
            <Flex
              borderRight="1px solid rgba(0, 0, 0, 0.15)"
              w="70%"
              h="100%"
              direction="column"
              p="16px 16px 16px 28px"
              gap="20px"
            >
              <Flex gap="10px" align="center">
                <Image width="60px" height="60px" src={Bot} />
                <Flex justify="center" direction="column">
                  <Text
                    color="rgba(44, 46, 56, 1)"
                    fontWeight="400"
                    fontFamily="Roboto"
                    fontSize="12px"
                  >
                    Build an app like
                  </Text>
                  <Text
                    fontSize="32px"
                    color="rgba(44, 46, 56, 1)"
                    fontWeight="700"
                  >
                    {data?.displayName}
                  </Text>
                </Flex>
              </Flex>
              <Flex gap="5px" justify="left" direction="column">
                <Text
                  fontSize="22px"
                  color="rgba(44, 46, 56, 1)"
                  fontWeight="600"
                  textTransform="capitalize"
                >
                  {data?.Journey?.displayName}
                </Text>
                <Text w="90%">{data?.Journey?.description}</Text>
              </Flex>
              <Flex
                direction="column"
                h="100%"
                border="1px solid rgba(0, 0, 0, 0.15)"
                justify="space-between"
              >
                <Flex
                  align="center"
                  justify="space-between"
                  w="100%"
                  h="50px"
                  bg="white"
                  p="0px 46px 0px 46px"
                  fontWeight="500"
                >
                  <Text color="rgba(146, 150, 170, 1)">FEATURES</Text>
                  <Text color="rgba(146, 150, 170, 1)">COST PER SECTION</Text>
                </Flex>
                <Flex h="500px" overflowY="scroll" direction="column">
                  {data?.Journey?.Features?.map((feature, index) => (
                    <FeatureAccordian feature={feature} index={index} />
                  ))}
                </Flex>
                <Flex
                  align="center"
                  justify="space-between"
                  w="100%"
                  h="100px"
                  bg="white"
                  p="0px 46px 0px 46px"
                  fontWeight="500"
                  fontFamily="Roboto"
                  color="rgba(44, 46, 56, 1)"
                >
                  <Flex align="left" direction="column">
                    <Text
                      fontSize="12px"
                      fontWeight="600"
                      color="rgba(0, 102, 255, 1)"
                    >
                      TOTAL FEATURES
                    </Text>
                    <Text fontSize="24px" fontWeight="700">
                      {totalFeatures}
                    </Text>
                  </Flex>
                  <Flex
                    align="left"
                    direction="column"
                    color="rgba(44, 46, 56, 1)"
                  >
                    <Text
                      fontSize="12px"
                      fontWeight="600"
                      color="rgba(0, 102, 255, 1)"
                    >
                      TOTAL PLATFORM COST
                    </Text>
                    <Text fontSize="24px" fontWeight="700">
                      {formatCurrency(totalFeaturesCost)}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              borderLeft="1px solid rgba(0, 0, 0, 0.15)"
              w="30%"
              h="100%"
              direction="column"
              justify="center"
              align="center"
              gap="20px"
            >
              <Flex
                w="50%"
                boxShadow="0 2px 12px 0 rgb(0 0 0 / 36%)"
                borderRadius="20px"
                p="12px"
                h="500px"
              >
                <Image borderRadius="20px" src={dummy} />
              </Flex>

              <Flex w="80%">
                <Button
                  fontSize="13px"
                  bg="rgba(0, 102, 255, 1)"
                  width="100%"
                  height="38px"
                  borderRadius="8px"
                  color="white"
                  _hover={{
                    bg: "rgba(51, 133, 255, 1)",
                  }}
                  _active={{
                    bg: "blue.400",
                  }}
                  onClick={() => _handleSaveInRedux(data?.id)}
                >
                  ADD
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
}

export default Index;

/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  subscription MySubscription($featuresIds: [ID]!,$featureIds: [ID]!, $ProjectDuration: Int , $accountProjectId: ID) {
    FeaturesPhaseDistribution(
      featuresIds: $featuresIds
      featureIds: $featureIds
      ProjectDuration: $ProjectDuration
      accountProjectId: $accountProjectId
    ) {
      message
      status
      weeks
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

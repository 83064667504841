/*
 * IMPORT
 */
import React from "react";
import { connect } from "react-redux";

/*
 * CHAKRA UI
 */
import {
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  Button,
  Spinner,
  IconButton,
  Text,
  Select,
  Textarea,
} from "@chakra-ui/react";

/*
 * ICONS AND IMAGES
 */
import { FaPlus } from "react-icons/fa6";

/*
 * QUERY
 */

function Index({ isOpen, onClose, loading, title , message, setUserInput, userInput , functionality }) {
  /*
   * STATE
   */

  /*
   * FUNCTIONS
   */

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>
            {message}
          </p>
          <Textarea mt="10px" resize="none" height="50px" onChange={(e) => setUserInput(e.target.value)} />
        </ModalBody>

        <ModalFooter>
          <Button
            bg="primary.500"
            color="white"
            _hover={{ bg: "primary.600" , color: "white" }}
            fontSize="13px"
            onClick={functionality}
            isDisabled={!userInput || loading ? true : false}
          >
            {loading ? <Spinner /> : "Submit"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  account: state.Account,
  project: state.Project,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

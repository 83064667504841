/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $userCost: Float
    $prototype: Boolean
    $numberOfUser: Int
    $mobileAvailability: [String]
    $developmentDuration: Int
    $developmentCost: Float
    $desktopAvailability: [String]
    $designType: String
    $designCost: Float
    $weeks: [JSON]
    $accountProjectId: ID
  ) {
    AdditionalCreate(
      input: {
        userCost: $userCost
        prototype: $prototype
        numberOfUser: $numberOfUser
        mobileAvailability: $mobileAvailability
        developmentCost: $developmentCost
        developmentDuration: $developmentDuration
        desktopAvailability: $desktopAvailability
        designType: $designType
        designCost: $designCost
        weeks: $weeks
        accountProjectId: $accountProjectId
      }
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

/*
 * EXPORTS
 */
export const rowStyle = {
  'fontFamily': 'Poppins',
  'fontWeight': '500',
  'fontSize': '17px',
  'fontSize': 'Clamp(13px, 1.5vw, 15px)',
  'textAlign': 'center',
  'borderWidth': 0,
  'color': '#8D97B5',
}
export const headerStyle = {
  'fontFamily': 'Poppins',
  'fontWeight': '600',
  'fontSize': '17px',
  'height': '50px',
  'color': '#8D97B5',
  'backgroundColor': '#190793',
  'textAlign': 'center',
}
export const cellStyle = {
  'fontFamily': 'Dm Sans',
  'color': 'rgba(43, 54, 116, 1)',
  'fontWeight': '700',
  'fontSize': '18px',
  'lineHeight': '24px',
  'letterSpacing': '-0.36px',
}
export const buttonStyle = {
  'backgroundColor': 'rgba(244, 247, 254, 1)',
  'color': 'rgba(92, 55, 255, 1)',
  'fontFamily': 'Poppins',
  'fontWeight': '500',
  'fontSize': '16px',
  'lineHeight': '32px',
  'letterSpacing': '-0.22px',
  'borderRadius': '10px'
}

/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  subscription MySubscription($accountProjectId: ID! , $installmentType: String  ) {
    AdditionalRead(accountProjectId: $accountProjectId , installmentType: $installmentType) {
      message
      status
      incrementedCost
      installments
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

/*
 * IMPORT
 */
import "reactflow/dist/style.css"; // Npm: React Flow stylesheet.
import React from "react"; // Npm: React library.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import { HiArrowDownCircle, HiArrowUpCircle } from "react-icons/hi2"; // Npm: React icons.
import { useHistory } from "react-router-dom";
import {formatCurrency} from "components/CurrencyFormater";

import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  Image,
} from "@chakra-ui/react"; // Npm: Chakra UI components.
import { FiSearch } from "react-icons/fi"; // Npm: React icons.
import { useQuery } from "@apollo/client";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { TbBulb } from "react-icons/tb";
import { BiMessageSquareDetail } from "react-icons/bi";
/*
 * PACKAGES
 */

/*
 * STYLES
 */

/*
 * OBJECTS
 */
const Index = ({
  account,
  project,
  list,
  updateProjectActiveIndex,
  updateAccountProjectDetails,
}) => {
  const history = useHistory();
  const [refineIdea, setRefineIdea] = React.useState(false);
  const [paymentDetails, setPaymentDetails] = React.useState(false);
  const [paymentOptions, setPaymentOptions] = React.useState(false);

  const formatTimestamp = (timestampStr) => {
    const timestamp = new Date(timestampStr);
    const now = new Date();
    const timeDifference = now - timestamp;

    if (timeDifference < 60 * 60 * 1000) {
      // less than 1 hour
      const minutesDifference = Math.floor(timeDifference / (60 * 1000));
      return `${minutesDifference} mins ago`;
    } else if (timeDifference < 24 * 60 * 60 * 1000) {
      // less than 24 hours
      const hoursDifference = Math.floor(timeDifference / (60 * 60 * 1000));
      return `${hoursDifference} hours ago`;
    } else {
      return timestamp
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, "-");
    }
  };


  const _stepsReached = (r) => {
    if (r?.BillingInfo?.length > 0) {
      setPaymentOptions(true);
      updateProjectActiveIndex(4);
      history.push("/studio");
    } else if (r?.UserAdditionalInfo?.length > 0) {
      setPaymentDetails(true);
      updateProjectActiveIndex(3);
      history.push("/studio");
    } else if (r?.UserAdditionalInfo?.length === 0) {
      setRefineIdea(true);
      updateAccountProjectDetails(r?.Project);
      updateProjectActiveIndex(1);
      history.push("/studio");
    }
  };

  // Return component.
  return (
    <Flex w="100%" flexDir="row" zIndex={10} gap="5%" flexWrap="wrap">
      {list?.AccountProject?.map((r, index) => (
        <Flex
          mt="42px"
          p="32px"
          flexDir="column"
          position="relative"
          flex="42.2% 0 0"
          borderColor="primary.600"
          borderWidth={1}
          borderRadius="0px 0px 0px 24px"
          key={String.random(9)}
          justifyContent="space-between"
        >
          <Flex position="absolute" left="7%" top="2">
            <h3 className="subHeading width maxContent followedLine">
              {r?.BillingInfo?.length > 0 ? "Ready" : "Draft"}
            </h3>
          </Flex>
          <Flex gap="10px" align="center" w="100%">
            <Text mt="6px" fontWeight={800} textTransform="capitalize">
              {r?.projectName}
            </Text>
          </Flex>

          <Text fontSize="13px" w="100%">
            {`Last updated at: ${formatTimestamp(r?.updatedAt)}`}
          </Text>

          <Flex
            mt="10px"
            justifyContent="space-between"
            align="center"
            fontWeight={600}
            fontSize="12px"
            w="100%"
          >
            <Flex>
              {r?.BillingInfo?.length > 0 ? (
                <Flex align="center">
                  <MdOutlineShoppingCartCheckout /> Checkout
                </Flex>
              ) : r?.UserAdditionalInfo?.length > 0 ? (
                <Flex align="center">
                  <BiMessageSquareDetail /> Payment Details
                </Flex>
              ) : (
                <Flex align="center">
                  <TbBulb /> Refine Idea
                </Flex>
              )}
            </Flex>
            <Text>IN PROGRESS</Text>
          </Flex>

          <Flex mt="10px" justifyContent="space-between" w="100%">
            <Flex flexDir="column" align="left">
              <Text>Features</Text>
              <Text fontWeight={700}>
                {
                  _.flatten(_.map(r?.Project?.Journey?.Features, "Feature"))
                    ?.length
                }
              </Text>
            </Flex>
            {r?.UserAdditionalInfo?.length > 0 && (
              <Flex flexDir="column" align="left">
                <Text>Duration</Text>
                <Text fontWeight={700}>
                  {`${Math.ceil(
                    r?.UserAdditionalInfo?.[0]?.developmentDuration / 7
                  )} weeks`}
                </Text>
              </Flex>
            )}

            <Flex flexDir="column" align="left">
              <Text>Platform Cost</Text>
              <Text fontWeight={700}>
                {`${formatCurrency(
                  _.sumBy(r?.Project?.Journey?.Features, (featureGroup) =>
                    _.sumBy(
                      featureGroup?.Feature,
                      (feature) => feature?.FeatureCost?.cost
                    )
                  )
                )}`}
              </Text>
            </Flex>
          </Flex>

          <Button
            bgColor="primary.500"
            position="absolute"
            right="5"
            color="white"
            _hover={{ bgColor: "primary.700" }}
            onClick={() => _stepsReached(r)}
          >
            CONTINUE +
          </Button>
        </Flex>
      ))}
    </Flex>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  account: state.Account,
  project: state.Project,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
  updateProjectActiveIndex: (activeIndex) =>
    __dispatch({ type: "PROJECT_ACTIVE_INDEX_UPDATE", activeIndex }),
  updateAccountProjectDetails: (accountProjectDetails) =>
    __dispatch({ type: "ACCOUNT_PROJECT_DETAILS_UPDATE", accountProjectDetails }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

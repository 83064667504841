/*
 * IMPORT
 */
import "reactflow/dist/style.css"; // Npm: React Flow stylesheet.
import React from "react"; // Npm: React library.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import { HiArrowDownCircle, HiArrowUpCircle } from "react-icons/hi2"; // Npm: React icons.
import { Box, Button, Flex, Input, Text, useToast } from "@chakra-ui/react"; // Npm: Chakra UI components.
import { FiSearch } from "react-icons/fi"; // Npm: React icons.
import { useSubscription } from "@apollo/client";
import { nanoid } from "nanoid";
import _ from "lodash";

/*
 * PACKAGES
 */
import SupportTable from "components/SupportTable";
import TicketGenerator from "components/TicketGenerator";
import CheckoutFooter from "components/CheckoutFooter";

/*
 * STYLES
 */
import "./index.css";

/*
 * SUBSCRIPTION
 */
import TicketRead from "./__subscription__/index.ticketRead.subscription";

/*
 * OBJECTS
 */
const Index = ({ account }) => {
  const _TicketRead = React.useRef([]);
  const _requestId = React.useRef("ADMIN");
  const [forceReRender, setForceReRender] = React.useState([]);
  const toast = useToast();

  console.log(
    useSubscription(TicketRead, {
      variables: {
        skip: 0,
        take: 20,
        loginRequestId: _requestId.current,
      },
      onData: ({ data, error }) => {
        // If error is caught, then report failure.
        if (error) return console.error(error);

        // Make sure data is not empty.
        if (
          _.isEmpty(data) ||
          (!_.isEmpty(data?.data) &&
            (_.isEmpty(data?.data?.TicketRead) ||
              !_.isArray(data?.data?.TicketRead)))
        ) {
          return toast("Something is not right.");
        }

        _TicketRead.current = data?.data?.TicketRead;
        // Force re-render
        return setForceReRender(nanoid());
      },
      onError: (e) => console.log("-==werwer", e),
    }),
    " TicketRead"
  );

  console.log(_TicketRead?.current, "helloworld");

  return (
    <Flex className="studio">
      <section className="gradientWrapper topRight round">
        <canvas className="gradient gradient1 color theme" />
        <div className="overlay occlusion" />
      </section>
      <Flex
        h="100%"
        flexDir="row"
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        px="102px"
        py="52px"
      >
        <Flex w="100%" h="100%" flexDir="column">
          <Text
            as="h1"
            className="heading font gradient greeting"
            lineHeight="unset"
          >
            <span style={{ fontWeight: 200 }}>Studio</span>,{" "}
            <span style={{ fontWeight: 900 }}>Support</span>
          </Text>
          <Flex
            mt={42}
            zIndex={100}
            position="relative"
            justifyContent="flex-start"
            alignItems="center"
            gap="12px"
            className="stepContainer"
          >
            <Flex>
              <h3 className="subHeading width maxContent followedLine">
                Tickets
              </h3>
            </Flex>
          </Flex>
          <Flex w="50%" gap="12px" my="42px" position="relative" zIndex={1000}>
            <Text fontSize="lg" color="gray.500">
              Exclusive Powerhouse of databases, ensuring maximum performance,
              scalability, and security for your data-driven applications and
              websites at affordable pricing.
            </Text>
          </Flex>
          <Flex
            position="absolute"
            width="10px"
            height="100%"
            boxShadow="0 0 400px 40px #FFF"
            left={0}
          />
          <Flex position="relative" w="240px" alignItems="center">
            <Flex flexDir="column">
              <Text p="12px" fontSize="md" color="gray.500" fontWeight={500}>
                Search Ticket
              </Text>
              <Input
                fontSize="14px"
                fontWeight={500}
                bg="gray.200"
                w="240px"
                borderRadius="12px"
                placeholder='e.g "Crashed during"'
              />
            </Flex>
            <Box
              position="absolute"
              right="12px"
              bottom="12px"
              color="gray.500"
            >
              <FiSearch />
            </Box>
          </Flex>
          <Flex
            w="100%"
            py="42px"
            flexDir="row"
            zIndex={10}
            gap="5%"
            justifyContent="space-between"
          >
            <Flex w="65%">
              <SupportTable query={_TicketRead?.current} />
            </Flex>
            <Flex w="30%">
              <TicketGenerator />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({ account: state.Account });
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  subscription MySubscription($loginRequestId: ID!, $accountProjectId: ID) {
    AccountProjectRead(
      loginRequestId: $loginRequestId
      accountProjectId: $accountProjectId
    ) {
      message
      status
      id
      Features {
        createdAt
        description
        displayName
        id
        Feature {
          days
          description
          createdAt
          displayName
          id
          FeatureCost {
            cost
            customisedCost
            createdAt
            id
          }
        }
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

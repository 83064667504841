/*
 * IMPORTS
 */
import { ApolloLink, Observable } from '@apollo/client/core' // Npm: Apollo client core library.
import { print } from 'graphql' // Npm: Graphql library.
import { createClient } from 'graphql-sse' // Npm: Graphql sse library.


/*
 * CLASS
 */
class SSELink extends ApolloLink {
  // Private's
  client

  // Constructor.
  constructor(options) {
    /*
     * Make sure to call super before doing
     * anything else.
     */
    super()

    // Create client.
    this.client = createClient(options)
  }

  /*
   * Handle given operation request. only
   * pass out those with subscription as operation.
   */
  request(operation) {
    return new Observable(sink => this.client.subscribe(
      { ...operation, 'query': print(operation.query) },
      {
        'next': sink.next.bind(sink),
        'complete': sink.complete.bind(sink),
        'error': sink.error.bind(sink)
      }
    ))
  }
}


/*
 * EXPORTS
 */
export default SSELink

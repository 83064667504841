/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($accountProjectId: ID! , $installmentId: ID!) {
    RazorpayPaymentCreate(accountProjectId: $accountProjectId , installmentId: $installmentId) {
      message
      status
      orderJson
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

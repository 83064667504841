/*
 * IMPORT
 */
import 'reactflow/dist/style.css' // Npm: React Flow stylesheet.
import useDarkMode from 'use-dark-mode' // Npm: Library for handling dark mode.
import React from 'react' // Npm: React library.
import ReactFlow, { addEdge, Background, Controls, getBezierPath, useEdgesState, useNodesState } from 'reactflow' // Npm: React flow library.
import { useDropzone } from 'react-dropzone' // Npm: React dropzone library.


/*
 * OBJECTS
 */
const Index = ({ defaultEdges = [], defaultNodes = [] }) => {
  // Component assignment.
  const ConnectionLine = ({ id, source, target, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, markerEnd }) => {
    // Hook assignment.
    const [edgePath, labelX, labelY] = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition
    })

    // Const assignment.
    const _width = Math.abs(targetX - sourceX)
    const _height = Math.abs(targetY - sourceY)

    // Return component.
    return (
      <>
        {isNumber(_width) && 0 < _width ? <path d={edgePath} className='react-flow__edge-path' markerEnd={markerEnd} strokeWidth='1' /> : void 0}
        <foreignObject
          x={isNumber(targetX) && 0 < targetX ? targetX - 15 / 2 : 0}
          y={isNumber(targetY) && 0 < targetY ? targetY - 10 / 2 : 0}
          className={styles.edgeStyle}
          width={isNumber(_width) && 0 < _width ? `${_width}px` : 0}
          height={isNumber(_height) && 0 < _height ? `${_height}px` : 0}
          requiredExtensions='http://www.w3.org/1999/xhtml'>
          <div className={styles.edgeActionButtonWrapper}>
            <button onClick={() => {
              // Update source node and target node.
              setSourceNodeWhichLastConnect(source)
              setTargetNodeWhichLastConnect(target)
            }}>
              <RiSettings5Fill size={7} />
            </button>
            <button onClick={() => setEdges(edges.filter(edge => edge.id !== id))}><BsFillTrashFill size={7} /></button>
          </div>
        </foreignObject>
      </>
    )
  }

  // Hook assignment.
  const [nodes, setNodes, onNodesChange] = useNodesState(defaultNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(defaultEdges)
  const { getRootProps, getInputProps } = useDropzone({ 'onDrop': e => e })
  const _darkMode = useDarkMode()
  const _nodeType = React.useRef({})
  const _edgeType = React.useRef({ 'connectionLineType': ConnectionLine })

  // Object assignment.
  const onConnect = React.useCallback(params => {
    // Open target modal. so that user can add more setting.
    setTargetNodeWhichLastConnect(params.target)

    // Set source node.
    setSourceNodeWhichLastConnect(params.source)

    // Update edges.
    return setEdges(eds => addEdge({ ...params, 'animated': true, 'type': 'connectionLineType' }, eds), [edges])
  })

  // Chakra Color Mode
  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      connectionLineComponent={e => <ConnectionLine {...e} />}
      nodeTypes={React.useMemo(() => _nodeType.current, [])}
      edgeTypes={React.useMemo(() => _edgeType.current, [])}
      fitView>
      <Background color={_darkMode.value ? '#F8F8F8' : '#F8F8F8'} variant='lines' gap={64}/>
      <Controls />
    </ReactFlow>
  )
}


/*
 * EXPORTS
 */
export default Index

/*
 * IMPORTS
 */
import React from "react";
import { useQuery, useMutation , useSubscription } from "@apollo/client";
import { connect } from "react-redux";
import { nanoid } from 'nanoid';
import {formatCurrency} from "components/CurrencyFormater"

/*
 * CHAKRA UI
 */
import {
  Flex,
  Modal,
  ModalContent,
  Image,
  Text,
  ModalOverlay,
  Button,
  Spinner,
  Badge,
  Divider,
  useDisclosure,
} from "@chakra-ui/react";

/*
 * ICONS AND IMAGES
 */
import { IoClose } from "react-icons/io5";
import Bot from "assets/svg/tool/Bot.svg";
import dummy from "assets/img/dummy.jpeg";

/*
 * PACKAGES
 */
import FeaturesSelectCard from "components/FeaturesSelectCard";
import TakeUserInputModal from "components/TakeUserInputModal";

/*
 * SUBSCRIPTION
 */
import FeatureRead from "./__subscription__/index.featureRead.subscription";
import FilteredFeaturesRead from "./__subscription__/index.featuresRead.subscription";


/*
 * MUTATION
 */
import FeatureCopy from "./__mutation__/index.featureCopy.mutation";
import CustomFeaturesCreate from "./__mutation__/index.customFeatureCreate.mutation";

function Index({
  isOpen,
  onClose,
  onOpen,
  project,
  account,
}) {
  const {
    isOpen: isOpenFeaturesSelectModal,
    onOpen: onOpenFeaturesSelectModal,
    onClose: onCloseFeaturesSelectModal,
  } = useDisclosure();

  const {
    isOpen: isOpenAddCustomFeaturesModal,
    onOpen: onOpenAddCustomFeaturesModal,
    onClose: onCloseAddCustomFeaturesModal,
  } = useDisclosure();

  const [suggestedFeaturesID, setSuggestedFeaturesID] = React.useState();
  const [featureId, setFeatureId] = React.useState();
  const [featuresId, setFeaturesId] = React.useState();
  const [featureCostId, setFeatureCostId] = React.useState();
  const [userInput, setUserInput] = React.useState();
  const _requestId = React.useRef("BHANU");
  const [forceReRender, setForceReRender] = React.useState([]);
  const _FeatureList = React.useRef([]);
  const _FeaturesList = React.useRef([]);


  const [MutationFeatureCopy, { loading: LoadingFeatureCopy }] = useMutation(FeatureCopy)
 
  const [
    MutationCustomFeaturesCreate,
    { loading: LoadingCustomFeaturesCreate },
  ] = useMutation(CustomFeaturesCreate)

console.log( useSubscription(FilteredFeaturesRead, {
  variables: {
    projectId: project?.projectDetails?.projectId,
  },
  onData: ({ data, error }) => {

    // If error is caught, then report failure.
    if (error) return console.error(error);

    // Make sure data is not empty.
    if (
      _.isEmpty(data) ||
      (!_.isEmpty(data?.data) &&
        (_.isEmpty(data?.data?.FilteredFeaturesRead) ||
          !_.isArray(data?.data?.FilteredFeaturesRead)))
    ) {
      return toast("Something is not right.");
    }

    _FeaturesList.current = data?.data?.FilteredFeaturesRead;
    // Force re-render
    return setForceReRender(nanoid());
  },
  onError: (e) => console.log("-==werwer", e),
}) , "FilteredFeaturesRead")

console.log(useSubscription(FeatureRead, {
  variables: {
    featuresId: suggestedFeaturesID ? suggestedFeaturesID : _FeaturesList.current?.[0]?.id
  },
  onData: ({ data, error }) => {
  
    // If error is caught, then report failure.
    if (error) return console.error(error);

    // Make sure data is not empty.
    if (
      _.isEmpty(data) ||
      (!_.isEmpty(data?.data) &&
        (_.isEmpty(data?.data?.FeatureRead) ||
          !_.isArray(data?.data?.FeatureRead)))
    ) {
      return toast("Something is not right.");
    }

    _FeatureList.current = data?.data?.FeatureRead
    // Force re-render
    return setForceReRender(nanoid());
  },
  onError: (e) => console.log("-==werwer", e),
}) , "FeatureRead")

  const _handleFeatureCopy = async () => {
    try {
      const response = await MutationFeatureCopy({
        variables: {
          featureId: featureId,
          featuresId: featuresId,
          featureCostId: featureCostId,
          accountProjectId: project?.accountProjectDetails?.accountProjectId,
        },
      });
      if (
        !_.isEmpty(response?.data?.FeatureCopy) &&
        "PUBLISHED_SUCCESSFULLY" === response?.data?.FeatureCopy?.status
      ) {
        onCloseFeaturesSelectModal();
        setFeaturesId(null);
        setFeatureCostId(null);
        setFeatureId(null);
      }
    } catch (error) {
      // onClose();
    }
  };

  const _handleCustomFeaturesCreate = async () => {
    try {
      const response = await MutationCustomFeaturesCreate({
        variables: {
          displayName: userInput,
          accountprojectId: project?.accountProjectDetails?.accountProjectId,
        },
      });
      if (
        !_.isEmpty(response?.data?.CustomFeaturesCreate) &&
        "PUBLISHED_SUCCESSFULLY" ===
          response?.data?.CustomFeaturesCreate?.status
      ) {
        onCloseAddCustomFeaturesModal();
        setUserInput(null);
      }
    } catch (error) {
      // onClose();
    }
  };

  return (
    <>
      <Modal isCentered isOpen={isOpen} size="full" onClose={onClose}>
        <ModalOverlay />
        <ModalContent height="100vh">
          <Flex
            position="relative"
            bg="rgba(222, 223, 226, 0.5)"
            boxShadow="0 2px 12px 0 rgb(0 0 0 / 16%)"
            m="50px"
            h="100%"
            borderRadius="20px 0px 20px 0px"
          >
            <Flex
              cursor="pointer"
              onClick={onClose}
              position="absolute"
              top="0"
              right="0"
              justify="center"
              align="center"
              w="50px"
              h="50px"
            >
              <IoClose size="40px" />
            </Flex>
            <Flex w="100%" h="100%">
              <Flex w="100%" h="90vh" p="16px 16px 16px 16px" gap="20px">
                <Flex
                  w="15%"
                  h="100%"
                  gap="10px"
                  align="left"
                  direction="column"
                  borderRight="1px solid black"
                >
                  <Text fontWeight="600" fontSize="20">
                    Suggested Features
                  </Text>
                  <Flex overflowY="scroll" gap="10px" direction="column">
                    {_FeaturesList?.current?.map(
                      (r, index) => (
                        <Text
                          w="max-content"
                          fontWeight={500}
                          m={0}
                          cursor="pointer"
                          onClick={() => setSuggestedFeaturesID(r?.id)}
                        >
                          {r?.displayName}&nbsp;&nbsp;
                        </Text>
                      )
                    )}
                  </Flex>
                  <Flex marginTop="auto">
                    <Button
                      bg="primary.500"
                      _hover={{ bg: "primary.600", color: "white" }}
                      color="white"
                      onClick={() => {
                        onOpenAddCustomFeaturesModal();
                      }}
                    >
                      Add Custome Features
                    </Button>
                  </Flex>
                </Flex>
                <Flex
                  w="80%"
                  justify="center"
                  flexWrap="wrap"
                  gap="50px"
                  overflowY="scroll"
                >
                  {_FeatureList.current?.map((r, index) => (
                    <Flex
                      w="25%"
                      p="16px"
                      h="400px"
                      bg="white"
                      borderRadius="20px 0px 20px 0px"
                      position="relative"
                      justify="space-between"
                    >
                      <Flex
                        direction="column"
                        justifyContent="space-between"
                        w="100%"
                      >
                        <Flex
                          gap="20px"
                          w="70%"
                          h="80%"
                          overflowY="scroll"
                          direction="column"
                        >
                          {r?.isRecommended && (
                            <Text
                              bg="blue.100"
                              textAlign="center"
                              w="50%"
                              borderRadius="5px"
                            >
                              Optional
                            </Text>
                          )}

                          <Text>{r.displayName}</Text>
                          <Text>{r.description}</Text>
                        </Flex>
                        <Divider />
                        <Flex
                          h="20%"
                          position="relative"
                          justify="center"
                          direction="column"
                        >
                          <Text>
                            Cost :{" "}
                            <span>{formatCurrency(r?.FeatureCost?.cost)}</span>
                          </Text>
                          <Text>
                            Duration : <span>{`${r.days} Days`}</span>
                          </Text>
                          <Flex
                            w="30px"
                            h="30px"
                            borderradius="5px"
                            bg="gray.100"
                            justify="center"
                            align="center"
                            position="absolute"
                            right="5"
                            onClick={() => {
                              onOpenFeaturesSelectModal();
                              setFeatureId(r?.id);
                              setFeatureCostId(r?.FeatureCost?.id);
                            }}
                            cursor="pointer"
                          >
                            +
                          </Flex>
                          {featureId === r?.id && (
                            <FeaturesSelectCard
                              isOpen={isOpenFeaturesSelectModal}
                              onClose={onCloseFeaturesSelectModal}
                              setFeaturesId={setFeaturesId}
                              functionality={_handleFeatureCopy}
                              loading={LoadingFeatureCopy}
                            />
                          )}
                        </Flex>
                      </Flex>
                      <Image
                        height="35%"
                        width="25%"
                        position="absolute"
                        top="5"
                        right="5"
                        objectFit="fill"
                        src={dummy}
                      />
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>

      <TakeUserInputModal
        isOpen={isOpenAddCustomFeaturesModal}
        onClose={onCloseAddCustomFeaturesModal}
        title="Add Custom Feature"
        message="A new custom feature will be added to your Buildcard. Give this custom feature a new name."
        loading={LoadingCustomFeaturesCreate}
        setUserInput={setUserInput}
        userInput={userInput}
        functionality={_handleCustomFeaturesCreate}
      />
    </>
  );
}

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  account: state.Account,
  project: state.Project,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORTS
 */
import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { useMutation } from "@apollo/client";
import { formatCurrency } from "components/CurrencyFormater";

/*
 * CHAKRA UI
 */
import {
  Box,
  Flex,
  Modal,
  ModalContent,
  Image,
  Text,
  ModalOverlay,
  Button,
  Spinner,
  Divider,
  useDisclosure,
} from "@chakra-ui/react";

/*
 * ICONS AND IMAGES
 */
import { IoClose, IoMove } from "react-icons/io5";
import { MdOutlineNoteAlt, MdDeleteOutline } from "react-icons/md";
import Bot from "assets/svg/tool/Bot.svg";
import dummy from "assets/img/dummy.jpeg";

/*
 * PACKAGES
 */
import FeaturesSelectCard from "components/FeaturesSelectCard";
import TakeUserInputModal from "components/TakeUserInputModal";
import SuggestedFeaturesModal from "components/SuggestedFeaturesModal";

/*
 * QUERY
 */

/*
 * MUTATION
 */
import FeatureMove from "./__mutation__/index.featureMove.mutation";
import CreateFeatureNote from "./__mutation__/index.featureAddNote.mutation";
import AccountProjectDelete from "./__mutation__/index.featureRemove.mutation";

function Index({
  account,
  project,
  isOpen,
  onClose,
  onOpen,
  featuresSpecificID,
  _ProjectRead,
  updateAccountProjectDetails,
}) {
  
  /*
   * STATE
   */
  const [featureId, setFeatureId] = React.useState(null);
  const [featuresId, setFeaturesId] = React.useState(null);
  const [featureCostId, setFeatureCostId] = React.useState(null);
  const [userInput, setUserInput] = React.useState(null);
  const _requestId = React.useRef("BHANU");

  const _FeatureRead = _ProjectRead?.Features?.filter(
    (features) => features?.id === featuresSpecificID
  )?.[0];

  const {
    isOpen: isOpenFeatureMoveModal,
    onOpen: onOpenFeatureMoveModal,
    onClose: onCloseFeatureMoveModal,
  } = useDisclosure();

  const {
    isOpen: isSuggestedFeaturesOpen,
    onOpen: onOpenSuggestedFeatures,
    onClose: onCloseSuggestedFeatures,
  } = useDisclosure();

  const {
    isOpen: isOpenAddNoteModal,
    onOpen: onOpenAddNoteModal,
    onClose: onCloseAddNoteModal,
  } = useDisclosure();

  const [
    MutationCreateFeatureNote,
    { loading: LoadingCreateFeatureNote },
  ] = useMutation(CreateFeatureNote);

  const [
    MutationAccountProjectDelete,
    { loading: LoadingAccountProjectDelete },
  ] = useMutation(AccountProjectDelete);

  const [MutationFeatureMove, { loading: LoadingFeatureMove }] = useMutation(
    FeatureMove
  );

  /*
   * FUNCTIONS
   */
  const _handleFeatureNoteAdd = async () => {
    try {
      const response = await MutationCreateFeatureNote({
        variables: {
          featureId: featureId,
          userNote: userInput,
        },
      });
      if (
        !_.isEmpty(response?.data?.CreateFeatureNote) &&
        "PUBLISHED_SUCCESSFULLY" === response?.data?.CreateFeatureNote?.status
      ) {
        onCloseAddNoteModal();
        setUserInput(null);
      }
    } catch (error) {
      onClose();
    }
  };

  const _handleFeatureRemove = async (ID) => {
    try {
      const response = await MutationAccountProjectDelete({
        variables: {
          featureId: ID,
          featuresId: featuresSpecificID,
          loginRequestId: _requestId?.current,
          accountProjectId: _ProjectRead?.id,
        },
      });
      if (
        !_.isEmpty(response?.data?.AccountProjectDelete) &&
        "PUBLISHED_SUCCESSFULLY" ===
          response?.data?.AccountProjectDelete?.status
      ) {
        console.log("Feature Removed Successfully");
      }
    } catch (error) {
      onClose();
    }
  };

  const _handleFeatureMove = async (id) => {
    try {
      const response = await MutationFeatureMove({
        variables: {
          featureId: featureId,
          oldFeaturesId: featuresSpecificID,
          newfeaturesId: featuresId,
          featureCostId: featureCostId,
          accountProjectId: _ProjectRead?.id,
        },
      });
      if (
        !_.isEmpty(response?.data?.FeatureMove) &&
        "PUBLISHED_SUCCESSFULLY" === response?.data?.FeatureMove?.status
      ) {
        onCloseFeatureMoveModal();
        setFeaturesId(null);
        setFeatureCostId(null);
        setFeatureId(null);
      }
    } catch (error) {
      onClose();
    }
  };

  return (
    <Modal isCentered isOpen={isOpen} size="full" onClose={onClose}>
      <ModalOverlay />
      <ModalContent height="100vh">
        <Flex
          position="relative"
          bg="rgba(222, 223, 226, 0.5)"
          boxShadow="0 2px 12px 0 rgb(0 0 0 / 16%)"
          m="50px"
          height="90vh"
          overflowY="scroll"
          borderRadius="20px 0px 20px 0px"
          direction="column"
        >
          <Flex gap="10px" align="center">
            <Image width="60px" height="60px" src={Bot} />
            <Flex justify="center" direction="column">
              <Text
                fontSize="32px"
                color="rgba(44, 46, 56, 1)"
                fontWeight="700"
              >
                {_FeatureRead?.displayName}
              </Text>
              <Text
                color="rgba(44, 46, 56, 1)"
                fontWeight="400"
                fontFamily="Roboto"
                fontSize="12px"
              >
                {`${
                  _FeatureRead?.Feature?.length
                } Features  |  ${formatCurrency(
                  _.sumBy(
                    _FeatureRead?.Feature,
                    (feature) => feature?.FeatureCost?.cost
                  )
                )} /-  |  ${_.sumBy(
                  _FeatureRead?.Feature,
                  (feature) => feature?.days
                )} Days`}
              </Text>
            </Flex>
          </Flex>

          <Flex
            cursor="pointer"
            onClick={onClose}
            position="absolute"
            top="0"
            right="0"
            justify="center"
            align="center"
            w="50px"
            h="50px"
          >
            <IoClose size="40px" />
          </Flex>

          <Flex direction="column">
            {_FeatureRead?.Feature?.length === 0 ? (
              <Flex justify="center" align="center" h="400px">
                <Text
                  onClick={() => onOpenSuggestedFeatures()}
                  cursor="pointer"
                  className="heading theme primary"
                >
                  Add Feature
                </Text>
              </Flex>
            ) : (
              <Flex
                w="100%"
                h="600px"
                p="16px 16px 16px 28px"
                gap="50px"
                justify="flex-start"
                overflowX="scroll"
                overflowY="hidden"
              >
                {_FeatureRead?.Feature?.map((r, index) => (
                  <Flex gap="10px" direction="column">
                    <Flex
                      key={index}
                      position="relative"
                      boxShadow="0 2px 12px 0 rgb(0 0 0 / 36%)"
                      borderRadius="20px"
                      p="10px"
                      h="500px"
                      w="250px"
                      justify="center"
                      _hover={{
                        ".overlay": { opacity: 1, visibility: "visible" },
                      }}
                      flex="0 0 auto"
                    >
                      <Image objectFit="fill" borderRadius="20px" src={dummy} />
                      <Flex
                        className="overlay"
                        position="absolute"
                        top="0"
                        left="0"
                        w="100%"
                        h="100%"
                        borderRadius="20px"
                        bg="rgba(0, 0, 0, .8)"
                        color="white"
                        opacity="0"
                        visibility="hidden"
                        transition="opacity 0.3s, visibility 0.3s"
                        direction="column"
                        p="20px"
                        justify="space-between"
                      >
                        <Text w="100%">{r?.description}</Text>
                        <Flex direction="column" gap="10px" mt="10px">
                          <Flex
                            align="center"
                            gap="5px"
                            cursor="pointer"
                            onClick={() => {
                              setFeatureId(r?.id);
                              onOpenAddNoteModal();
                            }}
                          >
                            <MdOutlineNoteAlt /> Add note
                          </Flex>
                          <Flex
                            align="center"
                            gap="5px"
                            cursor="pointer"
                            onClick={() => {
                              setFeatureId(r?.id);
                              setFeatureCostId(r?.FeatureCost?.id);
                              onOpenFeatureMoveModal();
                            }}
                          >
                            <IoMove /> Move
                          </Flex>
                          <Flex
                            onClick={() => {
                              _handleFeatureRemove(r?.id);
                            }}
                            align="center"
                            gap="5px"
                            cursor="pointer"
                          >
                            <MdDeleteOutline /> Remove
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex align="center" direction="column">
                      <Text>{r?.displayName}</Text>
                      <Flex gap="10px">
                        <Text>{`${r?.days} Days`}</Text>
                        <Divider
                          orientation="vertical"
                          borderColor="blackAlpha"
                        />
                        <Text>{formatCurrency(r?.FeatureCost?.cost)}</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            )}
          </Flex>
        </Flex>
        <FeaturesSelectCard
          isOpen={isOpenFeatureMoveModal}
          onClose={onCloseFeatureMoveModal}
          loading={LoadingFeatureMove}
          setFeaturesId={setFeaturesId}
          featuresId={featuresId}
          featureCostId={featureCostId}
          functionality={_handleFeatureMove}
        />
        <TakeUserInputModal
          isOpen={isOpenAddNoteModal}
          onClose={onCloseAddNoteModal}
          title="Add Note"
          message="Describe how you want this feature to work, the user exprience and
          any specific requirements."
          loading={LoadingCreateFeatureNote}
          setUserInput={setUserInput}
          userInput={userInput}
          functionality={_handleFeatureNoteAdd}
        />
        <SuggestedFeaturesModal
          isOpen={isSuggestedFeaturesOpen}
          onOpen={onOpenSuggestedFeatures}
          onClose={onCloseSuggestedFeatures}
        />
      </ModalContent>
    </Modal>
  );
}

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  account: state.Account,
  project: state.Project,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
  updateAccountProjectDetails: (accountProjectDetails) =>
    __dispatch({
      type: "ACCOUNT_PROJECT_DETAILS_UPDATE",
      accountProjectDetails,
    }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

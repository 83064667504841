/*
 * IMPORT
 */
import React from "react"; // Npm: React library.
import { TwitterPicker } from "react-color"; // Npm: Radial color picker.
import { useDropzone } from "react-dropzone"; // Npm: React dropzone.
import { HiPhoto } from "react-icons/hi2"; // Npm: React icons.
import _ from "lodash"; // Npm: Underscore for utility.
import { connect } from "react-redux";
/*
 * CHAKRA UI
 */
import {
  Checkbox,
  CheckboxGroup,
  Flex,
  Input,
  Stack,
  Text,
  FormControl,
  FormLabel,
} from "@chakra-ui/react"; // Npm: Chakra UI component library.

/*
 * ASSETS
 */

/*
 * PACKAGES
 */
import CheckoutFooter from "components/CheckoutFooter";

/*
 * MUTATIONS
 */
import AccountProjectCreate from "./__mutation__/index.accountProjectCreate.mutation";
import { useMutation } from "@apollo/client";

/*
 * STYLES
 */
import "./index.css";

/*
 * OBJECTS
 */
const Index = ({
  project,
  updateAccountProjectName,
  updateProjectActiveIndex,
  _nextStep,
}) => {
  /*
   * STATE
   */
  const [preferredProjectType, setPreferredProjectType] = React.useState([]);
  const [preferredPlatformName, setPreferredPlatformName] = React.useState("");
  const [preferredProjectLogo, setPreferredProjectLogo] = React.useState("");
  const [websiteUrl, setWebsiteUrl] = React.useState("");
  const [brandColors, setBrandColors] = React.useState([]);
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const _requestId = React.useRef("BHANU");


  const { getRootProps, getInputProps } = useDropzone({ onDrop: (e) => e });

  const [
    MutationAccountProjectCreate,
    { loading: LoadingAccountProjectCreate },
  ] = useMutation(AccountProjectCreate);

  /*
   * FUNCTION
   */

  const validateForm = () => {
    let formErrors = {};
    if (!preferredPlatformName.trim()) {
      formErrors.preferredPlatformName = "This field is required.";
    }
    setErrors(formErrors);
    return _.isEmpty(formErrors);
  };

  const _handleProjectCreate = async (id) => {
    if (!validateForm()) {
      return;
    }
    try {
      const response = await MutationAccountProjectCreate({
        variables: {
          color: brandColors,
          featureIds: project?.projectDetails?.featureIds,
          featuresIds: project?.projectDetails?.featuresIds,
          projectId: project?.projectDetails?.projectId,
          projectName: preferredPlatformName,
          projectType: preferredProjectType.current,
          thumbnailStoredAt: preferredProjectLogo,
          websiteUrl: websiteUrl,
          loginRequestId: _requestId?.current
        },
      });
      if (
        !_.isEmpty(response?.data?.AccountProjectCreate) &&
        "PUBLISHED_SUCCESSFULLY" === response?.data?.AccountProjectCreate?.status
      ) {
        updateAccountProjectName(preferredPlatformName);
        updateProjectActiveIndex(1);
      }
    } catch (error) {}
  };

  // Object assignment.
  const _onColorChange = (c) => {
    // Push given color to brand color.
    setBrandColors([...brandColors, c.hex]);

    // Dismiss color picker.
    showColorPicker && setShowColorPicker(false);
  };
  const _onColorRemove = (r) => {
    // Remove color at given index.
    setBrandColors(brandColors.filter((_, i) => i !== r));
  };

  // Chakra Color Mode
  return (
    <Flex className="studioAppInformation">
      <Flex direction="column" gap="30px" w="100%" p="0px 0px 40px 0px">
        <h3 className="heading font weight200 gradient">
          {project?.projectDetails?.projectName}
        </h3>
        <Flex
          fontWeight="400"
          fontSize="15px"
          lineHeight="17px"
          letterSpacing="1%"
          direction="row"
          alignItems="center"
          gap="16px"
        >
          <Flex
            w="45px"
            h="45px"
            borderRadius="100%"
            overflow="hidden"
            justify="center"
            align="center"
            direction="column"
            bg="primary.600"
            color="white"
            {...getRootProps()}
            cursor="pointer"
          >
            <input {...getInputProps()} />
            <HiPhoto size="16px" />
          </Flex>
          <Text fontWeight="500">Upload a logo (if you have one)</Text>
        </Flex>
        <CheckboxGroup
          onChange={setPreferredProjectType}
          value={preferredProjectType}
        >
          <Flex gap="16px" direction="column">
            <Text fontSize="15px" lineHeight="14.52px" fontWeight={500}>
              Choose Your Preferred Platform's
              <Text as="span" color="red.500">
                *
              </Text>
            </Text>
            <Stack direction="row" gap="12px">
              <Checkbox value="APPLICATION" colorScheme="primary">
                <Text fontSize="15px">Mobile App</Text>
              </Checkbox>
              <Checkbox value="WEBSITE" colorScheme="primary">
                <Text fontSize="15px">Desktop App</Text>
              </Checkbox>
            </Stack>
          </Flex>
        </CheckboxGroup>
        <Flex
          w="100%"
          fontWeight="400"
          fontSize="15px"
          lineHeight="17px"
          letterSpacing="1%"
          direction="column"
          gap="16px"
        >
          <FormControl isRequired isInvalid={errors.preferredPlatformName}>
            <FormLabel fontSize="15px" lineHeight="14.52px" fontWeight={500}>
              What should we call your software? Can be changed later.
            </FormLabel>

            <Input
              height="50px"
              placeholder='e.g. "HealthCare Application"'
              bgColor="primary.500"
              border="none"
              color="white"
              borderRadius={16}
              _placeholder={{ color: "white" }}
              value={preferredPlatformName}
              borderColor="gray.300"
              onChange={(e) => setPreferredPlatformName(e.target.value)}
              type="text"
            />
            {errors.preferredPlatformName && (
              <Text color="red.500">{errors.preferredPlatformName}</Text>
            )}
          </FormControl>
        </Flex>

        <Flex
          w="100%"
          fontWeight="400"
          fontSize="15px"
          lineHeight="17px"
          letterSpacing="1%"
          direction="column"
          gap="16px"
        >
          <Text fontSize="15px" lineHeight="14.52px" fontWeight={500}>
            Already have website in mind ? Share with us.
            <Text as="span" color="red.500">
              *
            </Text>
          </Text>
          <Input
            height="50px"
            placeholder='e.g. "https://www.netflix.com/"'
            bgColor="primary.500"
            border="none"
            color="white"
            borderRadius={16}
            _placeholder={{ color: "white" }}
            value={websiteUrl}
            borderColor="gray.300"
            onChange={(e) => setWebsiteUrl(e.target.value)}
            type="text"
          />
        </Flex>
        <Flex
          fontWeight="400"
          fontSize="15px"
          lineHeight="17px"
          letterSpacing="1%"
          gap="16px"
          direction="column"
          mb="4rem"
        >
          <Text fontSize="15px" lineHeight="14.52px" fontWeight={500}>
            Kindly Share your Brand Colors.
          </Text>
          <Flex justify="start" position="relative">
            <Flex flexDir="row" gap="12px">
              {brandColors.map((k, __index) => (
                <Flex
                  w="35px"
                  h="35px"
                  bgColor="gray.100"
                  borderRadius="12px"
                  flexDir="row"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  onClick={() => _onColorRemove(__index)}
                  bg={k}
                  color="white"
                  key={String.random(9)}
                >
                  -
                </Flex>
              ))}
            </Flex>
            <Flex
              ml={0 === brandColors?.length ? 0 : "12px"}
              w="35px"
              h="35px"
              bgColor="gray.100"
              borderRadius="12px"
              flexDir="row"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={setShowColorPicker}
            >
              +
            </Flex>
            {showColorPicker && (
              <Flex position="absolute">
                <TwitterPicker onChange={_onColorChange} />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex position="fixed" w="100%" left={0} bottom={0} zIndex={100}>
        <CheckoutFooter
          heading="OYO, Mobile App"
          description="Continue to refine your idea by moving on to the next step"
          _function={_handleProjectCreate}
          _nextStep={_nextStep}
          _loading={LoadingAccountProjectCreate}
        />
      </Flex>
    </Flex>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  project: state.Project,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),

  updateProjectActiveIndex: (activeIndex) =>
    __dispatch({ type: "PROJECT_ACTIVE_INDEX_UPDATE", activeIndex }),

  updateAccountProjectName: (accountProjectName) =>
    __dispatch({ type: "ACCOUNT_PROJECT_NAME_UPDATE", accountProjectName }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORT
 */
import 'react-device-frameset/styles/marvel-devices.min.css' // Npm: React device frameset.
import React from 'react' // Npm: React library.
import { DeviceFrameset } from 'react-device-frameset' // Npm: React device frameset.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI component library.


/*
 * OBJECTS
 */
const Index = ({ landscape }) =>
  // Chakra Color Mode
  (
    <Flex w='100%' h='100%' alignItems='center' justifyContent='center'>
      <DeviceFrameset device='iPhone X' color='gold' landscape={landscape}>
        <iframe width='100%' height='100%' src='https://rootandleaves.com' />
      </DeviceFrameset>
    </Flex>
  )



/*
 * EXPORTS
 */
export default Index

/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($accountProjectId: ID!, $tracker: String!) {
    AccountProjectUpdate(
      accountProjectId: $accountProjectId
      tracker: $tracker
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

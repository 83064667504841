/*
 * IMPORTS
 */
import React from "react";
import {formatCurrency} from "components/CurrencyFormater";

/*
 * CHAKRA UI
 */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
} from "@chakra-ui/react";

const Index = ({ isOpen, onClose, installments, billingCycle }) => {

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {billingCycle === "WEEKLY" ? "Weekly" : "Monthly"} installment plan
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>INSTALLMENT</Th>
                <Th>DUE DATE</Th>
                <Th>AMOUNT</Th>
              </Tr>
            </Thead>
            <Tbody>
              {installments?.map((installment, index) => (
                <Tr key={index}>
                  <Td>{installment?.installmentNumber}</Td>
                  <Td>{new Date(installment.dueDate).toLocaleDateString()}</Td>
                  <Td>{formatCurrency(installment.amount)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Index;

/*
 * GLOBALS
 */
export const _initialState = {
  accountProjectDetails: null,
  accountProjectName: null,
  projectDetails: null,
  activeIndex: 0,
};

/*
 * OBJECTS
 */
const _ProjectReducer = (__prevState = _initialState, __action) => {
  /*
   * Switch case for handling actions on
   * account store.
   */
  switch (__action.type) {
      case "PROJECT_DETAILS_UPDATE":
        // Update the project details in the state.
        return {
          ...__prevState,
          projectDetails: __action.projectDetails,
        };
        case "PROJECT_ACTIVE_INDEX_UPDATE":
        // Update the project details in the state.
        return {
          ...__prevState,
          activeIndex: __action.activeIndex,
        };
    case "ACCOUNT_PROJECT_DETAILS_UPDATE":
      // Update the fake project deatils in the state.
      return {
        ...__prevState,
        accountProjectDetails: __action.accountProjectDetails,
      };
      case "ACCOUNT_PROJECT_NAME_UPDATE":
      // Update the fake project name in the state.
      return {
        ...__prevState,
        accountProjectName: __action.accountProjectName,
      };
      case "PROJECT_CLEAR":
      // Return combined state.
      return {
        ..._initialState,
      };
    default:
      // Return old state.
      return __prevState;
  }
};

/*
 * EXPORTS
 */
export default _ProjectReducer;

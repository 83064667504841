/*
 * IMPORT
 */
import React from "react"; // Npm: React library.
import _ from "underscore"; // Npm: Underscore for utility.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import { useSubscription} from "@apollo/client";
import useSpeechToText from "react-hook-speech-to-text";
import { nanoid } from 'nanoid';

/*
 * CHAKRA UI
 */
import { Button, Flex, Input, Text , useToast } from "@chakra-ui/react"; // Npm: Chakra UI components.

/*
 * ICONS AND IMAGES
 */
import { HiArrowUturnRight } from "react-icons/hi2"; // Npm: React icons.

/*
 * PACKAGES
 */
import Flower from "lib/Flower";
import FeatureList from "components/FeatureList";
import SearchList from "components/SearchList";

/*
 * SUBSCRIPTION
 */
import ProjectRead from "./__subscription__/index.chatResult.subscription";


/*
 * STYLES
 */
import "./index.css";

/*
 * OBJECTS
 */
const Index = ({ id, showOnlyAsFooter , list }) => {
  // Hook assignment.
  const _primaryColor = "#190793";
  const _secondaryColor = "#02044A";
  const _ternaryColor = "#190793";
  const _sphereRef = React.useRef({});

  const toast = useToast();

  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    crossBrowser: true,
    useLegacyResults: false,
  });

  const _requestId = React.useRef("ADMIN");
  const [forceReRender, setForceReRender] = React.useState([]);
  const _projectList = React.useRef([]);

  console.log( useSubscription(ProjectRead, {
    variables: {
      loginRequestId: _requestId.current,
    },
    onData: ({ data, error }) => {
      // If error is caught, then report failure.
      if (error) return console.error(error);
  
      // Make sure data is not empty.
      if (
        _.isEmpty(data) ||
        (!_.isEmpty(data?.data) &&
          (_.isEmpty(data?.data?.ProjectRead) ||
            !_.isArray(data?.data?.ProjectRead)))
      ) {
        return toast("Something is not right.");
      }
  
      _projectList.current = data?.data?.ProjectRead;
  
      // Force re-render
      return setForceReRender(nanoid());
    },
    onError: (e) => console.log("-==werwer", e),
  })
   , "ProjectDetails")

  /*
   * USE EFFECT
   */
  // Event handler.
  React.useEffect(() => {
    // Const assignment.
    const _el = document.getElementById(id ?? "BuilderAiListeningSphere");

    // Only initialize if document is not ready.
    if (
      "complete" === document.readyState &&
      !_.isEmpty(_el) &&
      _.isEmpty(_sphereRef.current)
    ) {
      // Render sphere.
      _sphereRef.current = new Flower({
        damping: 0.0,
        container: _el,
        primaryColor: _primaryColor,
        secondaryColor: _secondaryColor,
        ternaryColor: _ternaryColor,
      });
    }
  }, []);
  React.useEffect(() => {
    // Const assignment.
    const _el = document.getElementById(id ?? "BuilderAiListeningSphere");

    // Only initialize if document is not ready.
    if ("complete" === document.readyState && !_.isEmpty(_el)) {
      /*
       * If _sphere already exists.
       * then dispose it before creating new one.
       */
      if (!_.isEmpty(_sphereRef.current)) _sphereRef.current?.dispose();

      // Render sphere.
      _sphereRef.current = new Flower({
        damping: 0.0,
        container: _el,
        primaryColor: _primaryColor,
        secondaryColor: _secondaryColor,
        ternaryColor: _ternaryColor,
      });
    }
  }, []);

  // Return component.
  return (
    <Flex zIndex={1000}  position="relative" className="builder">
      <Flex
        h={showOnlyAsFooter ? "90px" : "100%"}
        flexDir={showOnlyAsFooter ? "row" : "column"}
        justifyContent="space-between"
      >
        {showOnlyAsFooter ? (
          void 0
        ) : (
          <Flex p="42px" h="100%" flexDir="column" justifyContent="center">
            <Text fontWeight="500">Holla, Groot</Text>
            <Text fontWeight="900">Listening..</Text>
          </Flex>
        )}
        <Flex
          cursor="pointer"
          onClick={isRecording ? stopSpeechToText : startSpeechToText}
          flexDir="row"
          alignItems="center"
        >
          <div className="sphereWrapper">
            <div id={id ?? "BuilderAiListeningSphere"} />
          </div>
          {showOnlyAsFooter ? (
            <Flex py="42px" h="100%" flexDir="column" justifyContent="center">
              <Text fontWeight="500">Holla, Groot</Text>
             
              <Text fontWeight="900">{interimResult ? interimResult : 'Listening..'}</Text>
              <ul>
                {/* {results.map((result) => (
                  <li key={result.timestamp}>{result.transcript}</li>
                ))} */}
              </ul>
            </Flex>
          ) : (
            void 0
          )}
          {showOnlyAsFooter ? (
            void 0
          ) : (
            <Flex flexDir="row" minW="320px" gap="12px">
              <Input
                placeholder="Type your message here.."
                bgColor="primary.600"
                borderWidth={0}
                borderRadius="12px"
                color="white"
                _placeholder={{ color: "#FFF" }}
                fontSize="15px"
              />
              <Button bg="primary.600">
                <HiArrowUturnRight fontSize={24} />
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
      {showOnlyAsFooter ? (
        void 0
      ) : (
        <Flex flexDir="column" width="70%" p="124px" overflow="auto">
          <Text className="heading font white weight900" fontSize={24}>
            Found Something That Will Help You..
          </Text>
          <Text className="description font white">
            Groot has suggested some platforms for you based on your
            requirement. Your requirement is to build an app like OYO Rooms.
          </Text>
          <Flex  flexWrap="wrap" gap="12px" mt="42px">
            {id === "StudioBuilderAppDevelopmentInformationFlow" ? (
              <FeatureList list={list} />
            ) : (
              <SearchList list={_projectList?.current} />
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({  });
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($featureId: ID! , $featuresId: ID! , $loginRequestId: ID! , $accountProjectId:ID!) {
    AccountProjectDelete(featureId: $featureId  , featuresId: $featuresId , loginRequestId: $loginRequestId , accountProjectId: $accountProjectId) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

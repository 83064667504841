/*
 * IMPORTS
 */
import React from "react"; // NPM: React-redux library.
import { connect } from "react-redux"; // NPM: React-redux library.
import _ from "underscore"; // NPM: Lodash for utility.
import { createIcon } from "@chakra-ui/icons"; // Npm: Chakra UI icons.
import { Button, Flex, Text, Spinner } from "@chakra-ui/react"; // NPM: Chakra UI component library.
import { useLocation, useHistory } from "react-router-dom";

/*
 * MUTATIONS
 */
import LinkedinAccount from "./__mutation__/index.linkedinAccount.mutation";
import AccountSignInWithlinkedIn from "./__mutation__/index.linkedinAccountSignin.mutation";
import GoogleAccount from "./__mutation__/index.googleAccount.mutation";
import AccountSingnInwithGoogle from "./__mutation__/index.googleAccountSignin.mutation";

import AccountLoginWithGoogle from "./__mutation__/index.googleAccount.mutation";
import AccountLoginWithGoogleSubscription from "./__subscription__/index.accountLoginWithGoogle.subscription";

import { useMutation, useSubscription } from "@apollo/client";

/*
 * GLOBALS
 */
const Logo = createIcon({
  displayName: "Logo",
  viewBox: "0 0 52 61",
  path: (
    <>
      <path
        d="M34.2867 0.362305H0V33.8598C0 42.8677 7.30237 50.1701 16.3103 50.1701C16.3103 48.767 17.0118 42.9795 26.3946 43.0672C33.9008 43.1373 35.602 47.8317 35.5143 50.1701H52V18.0756C52 8.29283 44.0695 0.362305 34.2867 0.362305Z"
        fill="#3311DB"
      />
      <path
        d="M32.531 17.4605L29.3751 17.3744C28.7436 16.3923 27.2414 16.1467 26.5692 16.1467V12.3761C27.2005 12.0955 27.5922 11.4407 27.7091 11.1484C27.7968 10.7392 27.7968 9.76293 27.0953 9.13156C26.3938 8.5002 25.4584 8.63466 25.0785 8.78081C24.7277 8.89772 24.0086 9.41217 23.9385 10.5346C23.8683 11.657 24.7862 12.2299 25.2538 12.3761V16.1467C23.5 16.0766 22.6524 16.9359 22.4478 17.3744L19.2045 17.4621C15.3341 17.5667 11.9708 20.1506 10.8726 23.8634C10.0835 24.2142 8.52475 24.5561 8.41726 27.8095C8.33035 30.4402 9.70356 31.6094 10.6097 31.9309C11.1596 36.6305 15.1416 40.1737 19.8734 40.1737H32.3449C36.9246 40.1737 40.7044 36.5917 40.9503 32.0186C40.9503 31.8432 41.2484 31.8257 41.3887 31.7555C41.5641 31.6678 42.5287 31.2294 43.0547 29.8263C43.4492 28.7741 43.397 27.371 43.2301 26.4941C42.8795 24.6526 41.6224 24.0388 40.8625 23.8634C39.7644 20.1505 36.4015 17.566 32.531 17.4605Z"
        fill="white"
      />
      <rect
        x="14.0314"
        y="22.6353"
        width="23.6762"
        height="12.1012"
        rx="6.05059"
        fill="#D2DCFF"
      />
      <circle
        cx="31.3929"
        cy="28.7737"
        r="1.49072"
        fill="#3311DB"
        stroke="#3311DB"
        strokeWidth="0.175379"
      />
      <circle
        cx="20.519"
        cy="28.7737"
        r="1.49072"
        fill="#3311DB"
        stroke="#3311DB"
        strokeWidth="0.175379"
      />
      <path
        d="M12.5397 25.6168C13.5922 19.1278 20.2566 17.8417 22.4488 17.3741C17.5382 17.111 15.6673 18.1632 14.9073 18.6893C12.2415 20.3028 11.1074 22.8107 10.8736 23.863C9.11978 24.354 8.51265 25.8798 8.41829 27.1075C8.15542 30.5274 9.70444 31.6089 10.6106 31.9304C12.7151 39.7348 18.2397 40.1733 22.2733 40.1733C16.0999 39.3314 13.3289 34.7365 12.7151 32.5443C12.0136 29.8083 12.3059 26.786 12.5397 25.6168Z"
        fill="#D2DCFF"
      />
      <path
        d="M24.8179 10.008C25.5194 8.88559 26.6301 8.95574 27.0978 9.13112C25.2037 8.00869 24.2625 9.24804 24.0287 10.008C23.6078 11.2707 24.5548 12.0541 25.0809 12.2879C24.3093 11.3058 24.584 10.3588 24.8179 10.008Z"
        fill="#D2DCFF"
      />
      <rect
        x="22.7997"
        y="40.1733"
        width="7.01518"
        height="3.33221"
        fill="#D2DCFF"
      />
      <path
        d="M51.9993 50.082L35.5137 50.1697L51.9993 60.7802V50.082Z"
        fill="#3311DB"
      />
      <path
        d="M35.5135 50.1697H16.3094C16.3096 46.7498 18.7648 44.5575 20.2555 43.856C22.7108 42.8037 25.6922 42.8914 26.9197 42.8914C29.4629 42.8914 31.5673 43.6222 32.3565 44.1191C34.9521 45.6624 35.5427 48.8543 35.5135 50.1697Z"
        fill="white"
      />
    </>
  ),
});

/*
 * OBJECTS
 */
const Index = ({ AccountUpdate, account }) => {
  /*
   * STATE
   */
  const location = useLocation();
  const history = useHistory();
  const [googleToken, setGoogleToken] = React.useState(null);
  const [linkedinToken, setLinkedinToken] = React.useState(null);

  const [
    MutationAccountLoginWithGoogle,
    { loading: MutationAccountLoginWithGoogleResponse },
  ] = useMutation(AccountLoginWithGoogle);

  const [
    MutationGoogleAccount,
    { loading: LoadingGoogleAccount },
  ] = useMutation(GoogleAccount);

  const [
    MutationAccountSingnInwithGoogle,
    { loading: LoadingAccountSingnInwithGoogle },
  ] = useMutation(AccountSingnInwithGoogle);

  const [
    MutationLinkedinAccount,
    { loading: LoadingLinkedinAccount },
  ] = useMutation(LinkedinAccount);

  const [
    MutationAccountSignInWithlinkedIn,
    { loading: LoadingAccountSignInWithlinkedIn },
  ] = useMutation(AccountSignInWithlinkedIn);

  /*
   * FUNCTIONS
   */
  const _handleLinkedinAccount = async () => {
    try {
      const response = await MutationLinkedinAccount();
      if (
        !_.isEmpty(response?.data?.LinkedinAccount) &&
        "GET_SUCCESSFUL" === response?.data?.LinkedinAccount?.status
      ) {
        setLinkedinToken(response?.data?.LinkedinAccount?.token);
      }
    } catch (error) {}
  };

  const _handleLinkedinAccountSignIn = async () => {
    try {
      const _codeParam = new URLSearchParams(location.search).get("code");

      const response = await MutationAccountSignInWithlinkedIn({
        variables: {
          code: _codeParam,
        },
      });
      if (!_.isEmpty(response?.data?.AccountSignInWithlinkedIn)) {
        history.push("/");
        AccountUpdate({
          isUserLoggedIn: true,
          ...response?.data?.AccountSignInWithlinkedIn,
        });
      }
    } catch (error) {}
  };

  const _handleGoogleAccount = async () => {
    try {
      const response = await MutationGoogleAccount();
      if (
        !_.isEmpty(response?.data?.GoogleAccount) &&
        "GET_SUCCESSFUL" === response?.data?.GoogleAccount?.status
      ) {
        setGoogleToken(response?.data?.GoogleAccount?.token);
      }
    } catch (error) {}
  };

  // Object assignment.
  const _AccountLoginWithGoogle = async () => {
    // Error handling.
    try {
      // Const assignment.
      const _codeParam = new URLSearchParams(location.search).get("code");
      const _MutationAccountLoginWithGoogle = await MutationAccountLoginWithGoogle(
        {
          variables: {
            loginRequestId: String.random(),
            googleToken: _codeParam,
          },
        }
      );

      console.log("----XX", _MutationAccountLoginWithGoogle);

      // Conditionally handling _MutationAccountLoginWithGoogle from google login.
      if (
        !_.isEmpty(_MutationAccountLoginWithGoogle?.data?.GoogleAccount) &&
        "GET_SUCCESSFUL" ===
          _MutationAccountLoginWithGoogle?.data?.GoogleAccount?.status
      ) {
        // Update google token state.
        setGoogleToken(
          _MutationAccountLoginWithGoogle?.data?.GoogleAccount?.token
        );
      }

      /*
       * If (!_.isEmpty(_MutationAccountLoginWithGoogle?.data?.AccountLoginWithGoogle)) {
       *   history.push('/')
       *   AccountUpdate({
       *     'isUserLoggedIn': true,
       *     ..._MutationAccountLoginWithGoogle?.data?.AccountLoginWithGoogle
       *   })
       * }
       */

      // Report failure.
      return void 0;
    } catch (error) {
      console.log(error);

      // Report failure.
      return error;
    }
  };

  const _handleGoogleAccountSignIn = async () => {
    try {
      const _codeParam = new URLSearchParams(location.search).get("code");
      const response = await MutationAccountSingnInwithGoogle({
        variables: {
          googleToken: _codeParam,
        },
      });
      if (!_.isEmpty(response?.data?.AccountSingnInwithGoogle)) {
        history.push("/");
        AccountUpdate({
          isUserLoggedIn: true,
          ...response?.data?.AccountSingnInwithGoogle,
        });
      }
    } catch (error) {}
  };

  const _SwitchToGoogleAuthScreen = () => {
    localStorage.setItem("authProvider", "GOOGLE");
    window.location.href = googleToken;
  };

  const _SwitchToLinkedinAuthScreen = () => {
    localStorage.setItem("authProvider", "LINKEDIN");
    window.location.href = linkedinToken;
  };

  // Event handler.
  // useSubscription(AccountLoginWithGoogleSubscription, {
  //   variables: {
  //     loginRequestId,
  //   },
  //   onData: ({ data, error }) => {
  //     // If subscription caught an exception then report failure.
  //     if (error instanceof Error) return error;

  //     console.log(data);
  //   },
  // });

  /*
   * USE EFFECT
   */
  // React.useEffect(() => {
  //   const _codeParam = new URLSearchParams(location.search).get("code");
  //   const _authProvider = localStorage.getItem("authProvider");

  //   if (!_.isEmpty(_codeParam)) {
  //     if (_authProvider === "GOOGLE") {
  //       localStorage.setItem("m", "bhanu");
  //       _handleGoogleAccountSignIn(_codeParam);
  //     } else if (_authProvider === "LINKEDIN") {
  //       localStorage.setItem("my", "name");
  //       _handleLinkedinAccountSignIn(_codeParam);
  //     }
  //   } else {
  //     _handleGoogleAccount();
  //     _handleLinkedinAccount();
  //   }
  // }, [AccountUpdate, location.search, googleToken]);

  return (
    <Flex
      w="calc(100% - 105px)"
      h="100%"
      direction="column"
      position="fixed"
      p="32px"
      backgroundColor="rgba(255, 255, 255, 0.29)"
      boxShadow="inset 0 0 100px 400px #FFF, 0 0 100px 400px rgba(255, 255, 255, 0.93)"
      zIndex={10000}
    >
      <Flex flexDir="column" w="420px" m="auto">
        <Flex flexDir="row" alignItems="center" justifyContent="center">
          <Logo w="100%" h="100%" />
        </Flex>
        <h3 className="heading font gradient width maxContent">
          <bold style={{ fontWeight: 900, lineHeight: "normal" }}>Login</bold>,{" "}
          <span className="font weight200">Groot</span>
        </h3>
        <Flex flexDirection="column" mt="32px" w="100%">
          <Text mb="22px" fontSize="15px" lineHeight="14.52px" fontWeight={500}>
            Kindly choose one social media platform to login with.{" "}
            <Text as="span" color="red.500">
              *
            </Text>
          </Text>
          <Flex flexDir="row" alginItems="center" gap="22px">
            <Button
              w="55px"
              h="55px"
              p="0"
              borderRadius="100%"
              bgColor="gray.100"
              color="gray.600"
              mt="12px"
              // isDisabled={
              //   LoadingGoogleAccount || LoadingAccountSingnInwithGoogle
              // }
              // onClick={_SwitchToGoogleAuthScreen}
              isDisabled={MutationAccountLoginWithGoogleResponse}
              onClick={_AccountLoginWithGoogle}
            >
              {MutationAccountLoginWithGoogleResponse ? (
                <Spinner />
              ) : (
                <svg
                  viewBox="0 0 46 46"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M32.64 23.2045C32.64 22.5664 32.5827 21.9527 32.4764 21.3636H24V24.845H28.8436C28.635 25.97 28.0009 26.9232 27.0477 27.5614V29.8195H29.9564C31.6582 28.2527 32.64 25.9454 32.64 23.2045Z"
                    fill="#4285F4"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 32C26.43 32 28.4673 31.1941 29.9564 29.8195L27.0477 27.5614C26.2418 28.1014 25.2109 28.4204 24 28.4204C21.6559 28.4204 19.6718 26.8373 18.9641 24.71H15.9573V27.0418C17.4382 29.9832 20.4818 32 24 32Z"
                    fill="#34A853"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.9641 24.71C18.7841 24.17 18.6818 23.5932 18.6818 23C18.6818 22.4068 18.7841 21.83 18.9641 21.29V18.9582H15.9573C15.3477 20.1732 15 21.5477 15 23C15 24.4523 15.3477 25.8268 15.9573 27.0418L18.9641 24.71Z"
                    fill="#FBBC05"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24 17.5795C25.3214 17.5795 26.5077 18.0336 27.4405 18.9255L30.0218 16.3441C28.4632 14.8918 26.4259 14 24 14C20.4818 14 17.4382 16.0168 15.9573 18.9582L18.9641 21.29C19.6718 19.1627 21.6559 17.5795 24 17.5795Z"
                    fill="#EA4335"
                  />
                </svg>
              )}
            </Button>
            <Button
              w="55px"
              h="55px"
              p="4px"
              borderRadius="100%"
              gap="12px"
              bgColor="#007EBB"
              mt="12px"
              onClick={_SwitchToLinkedinAuthScreen}
              isDisabled={
                LoadingLinkedinAccount || LoadingAccountSignInWithlinkedIn
              }
            >
              {LoadingLinkedinAccount || LoadingAccountSignInWithlinkedIn ? (
                <Spinner />
              ) : (
                <svg
                  width="266"
                  height="267"
                  viewBox="0 0 266 267"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M265.634 133.547C265.634 200.572 215.949 255.981 151.408 264.975C145.372 265.815 139.197 266.251 132.931 266.251C125.695 266.251 118.596 265.67 111.672 264.554C48.4919 254.384 0.227783 199.593 0.227783 133.54C0.227783 60.2496 59.6409 0.836487 132.931 0.836487C206.221 0.836487 265.634 60.2496 265.634 133.54V133.547Z"
                    fill="#007EBB"
                  />
                  <path
                    d="M64.799 88.7297C61.261 85.4439 59.4957 81.3786 59.4957 76.5415C59.4957 71.7044 61.261 67.4557 64.799 64.1698C68.3371 60.8839 72.8914 59.241 78.4773 59.241C84.0633 59.241 88.4343 60.8839 91.9647 64.1698C95.5028 67.4557 97.268 71.5821 97.268 76.5415C97.268 81.5008 95.5028 85.4439 91.9647 88.7297C88.4267 92.0156 83.9334 93.6586 78.4773 93.6586C73.0213 93.6586 68.3371 92.0156 64.799 88.7297ZM94.2878 107.574V208.267H62.476V107.574H94.2878Z"
                    fill="#FEFFFC"
                  />
                  <path
                    d="M200.207 117.516C207.138 125.042 210.608 135.381 210.608 148.54V206.494H180.385V152.628C180.385 145.996 178.666 140.838 175.227 137.162C171.788 133.486 167.165 131.652 161.358 131.652C155.55 131.652 150.927 133.494 147.488 137.162C144.05 140.838 142.33 145.996 142.33 152.628V206.494H111.932V107.299H142.33V120.458C145.41 116.071 149.559 112.61 154.778 110.057C159.99 107.513 165.859 106.237 172.377 106.237C183.992 106.237 193.269 110.004 200.2 117.523L200.207 117.516Z"
                    fill="#FEFFFC"
                  />
                </svg>
              )}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  account: state.Account,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORT
 */
import React from "react"; // Npm: React library.
import _ from "lodash"; // Npm: Underscore for utility.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import { useHistory } from "react-router-dom";
import {formatCurrency} from "components/CurrencyFormater";


/*
 * CHAKRA UI
 */
import { Button, Flex, Image, Text, useDisclosure , Spinner } from "@chakra-ui/react"; // Npm: Chakra UI components.
import { GrOverview } from "react-icons/gr";

/*
 * MUTATIONS
 */

/*
 * PACKAGES
 */
import ProjectDetailModal from "components/ProjectDetailModal";

/*
 * OBJECTS
 */
const Index = ({
  list,
  updateProjectDetails,
  updateProjectActiveIndex,
  updateAccountProjectName,
  updateAccountProjectDetails
}) => {
  /*
   * STATE
   */
  // Hook assignment.
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [specificProjectID, setSpecificProjectID] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);


  /*
   * FUNCTIONS
   */
  const _handleSaveInRedux = (id) => {
    setIsLoading(true);
    const response = list?.filter((r) => r?.id === id)?.[0];
    const featureIds = _.flatMap(
      response?.Journey?.Features,
      (featureGroup) => _.map(featureGroup.Feature, "id")
    );
    const featuresIds = _.map(
      response?.Journey?.Features,
      (featuresID) => featuresID.id
    );
  
    const projectId = response?.id
    const projectName = response?.displayName
    updateAccountProjectName(null)
    updateAccountProjectDetails(null)
    updateProjectDetails({featureIds:featureIds , featuresIds:featuresIds , projectId:projectId , projectName:projectName});
    updateProjectActiveIndex(0)
    setIsLoading(false);
    onClose();
    history.push("/studio");
  };

  // Return component.
  return list?.map((r) => (
    <Flex
      mt="42px"
      p="32px"
      flexDir="row"
      flex="32.2% 0 0"
      borderColor="primary.600"
      borderWidth={1}
      borderRadius="24px"
      key={String.random(9)}
    >
      <Flex position="relative" w="100%" flexDir="column">
        <Image
          p="4px"
          bgColor="primary.700"
          borderRadius="12px"
          w="45px"
          h="45px"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/OYO_Rooms_%28logo%29.png/1200px-OYO_Rooms_%28logo%29.png"
          // src={r?.thumbnail}
        />
        <Text mt="12px">Build an App like</Text>
        <Text mt="6px" fontWeight={800} textTransform="capitalize">
          {r?.displayName}
        </Text>
        {/* <ul className="featureList">
          <li>
            <Text>Share Photos</Text>
          </li>
          <li>
            <Text>Explore Trends</Text>
          </li>
          <li>
            <Text>Connect Socially</Text>
          </li>
        </ul> */}
        <Text mt="6px" h="60px" overflowY="scroll" w="100%">
          {r?.description}
        </Text>
        <Flex w="100%" flexDir="column">
          <Flex
            mb="16px"
            w="100%"
            flexDir="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text>Features</Text>
            <Text fontWeight={700}>
              {_.flatten(_.map(r?.Journey?.Features, "Feature"))?.length}
            </Text>
          </Flex>
          <Flex
            mb="16px"
            w="100%"
            flexDir="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text>Platform Cost</Text>
            <Text fontWeight={700}>
              {`${formatCurrency(
                _.sumBy(r?.Journey?.Features, (featureGroup) =>
                  _.sumBy(
                    featureGroup?.Feature,
                    (feature) => feature?.FeatureCost?.cost
                  )
                )
              )}`}
            </Text>
          </Flex>
          <Flex
            w="100%"
            flexDir="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text></Text>
            <Flex
              textDecoration="underline"
              onClick={() => {
                setSpecificProjectID(r?.id);
                onOpen();
              }}
              align="center"
              gap="10px"
              cursor="pointer"
            >
              View Detail
              <GrOverview color="white" />
            </Flex>
          </Flex>
        </Flex>
        {specificProjectID === r?.id && (
          <ProjectDetailModal
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            data={r}
            totalFeaturesCost={_.sumBy(r?.Journey?.Features, (featureGroup) =>
              _.sumBy(
                featureGroup?.Feature,
                (feature) => feature?.FeatureCost?.cost
              )
            )}
            totalFeatures={
              _.flatten(_.map(r?.Journey?.Features, "Feature"))?.length
            }
            _handleSaveInRedux={_handleSaveInRedux}
          />
        )}
        <Button
          bgColor="primary.700"
          position="absolute"
          right="0"
          _hover={{ bgColor: "primary.700" }}
          onClick={() => _handleSaveInRedux(r?.id)}
        >
          {
            isLoading ? <Spinner /> : "CONTINUE +"
          }
        </Button>
      </Flex>
    </Flex>
  ));
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({ });
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
  updateProjectDetails: (projectDetails) =>
    __dispatch({ type: "PROJECT_DETAILS_UPDATE", projectDetails }),
    updateProjectActiveIndex: (activeIndex) =>
    __dispatch({ type: "PROJECT_ACTIVE_INDEX_UPDATE", activeIndex }),
    updateAccountProjectName: (accountProjectName) =>
    __dispatch({ type: "ACCOUNT_PROJECT_NAME_UPDATE", accountProjectName }),
    updateAccountProjectDetails: (accountProjectDetails) =>
    __dispatch({ type: "ACCOUNT_PROJECT_DETAILS_UPDATE", accountProjectDetails }),
    
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

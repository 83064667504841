/*
 * EXPORTS
 */
export const menuStyle = {
    color: 'rgba(22, 22, 22, 0.5)',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '-.26px',
    borderRadius: '24px',
    right: -50,
    top: 0,
    position: 'sticky',
    boxShadow: '14px 17px 40px 4px rgba(112, 144, 176, 0.18)'
  }
  
/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
query GrootSayQuery($message: String!) {
  GrootSay(message: $message) {
    response,
    status
  }
}
`


/*
 * EXPORTS
 */
export default Index

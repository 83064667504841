/*
 * IMPORTS
 */
  import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

  /*
   * GRAPH
   */
  const Index = gql`
  subscription MySubscription($loginRequestId: ID!, $ticketId: ID!, $take: Int, $skip: Int) {
    TicketReplyRead(
      loginRequestId: $loginRequestId
      skip: $skip
      take: $take
      ticketId: $ticketId
    ) {
      ticketCategory
      ticketDescription
      ticketImage
      ticketNumber
      ticketSeverity
      ticketStatus
      id
      createdAt
      updatedAt
      TicketReply {
        repliedBy
        replyDescription
        updatedAt
        createdAt
      }
    }
  }
  `;
  
  /*
   * EXPORTS
   */
  export default Index;
  
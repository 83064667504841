import React from "react";
// Chakra imports
import { Flex } from "@chakra-ui/react";

function SignIn() {
  // Chakra color mode

  /*
   * FUNCTION
   */

  /*
   * USE EFFECT
   */

  return <Flex direction="column" position="relative"></Flex>;
}

/*
 * EXPORTS
 */
export default SignIn;

/*
 * IMPORT
 */
import "reactflow/dist/style.css"; // Npm: React Flow stylesheet.
import React from "react"; // Npm: React library.
import _ from "underscore"; // Npm: Underscore for utility.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"; // Npm: Chakra UI components.
import { Connect } from "react-connect-lines"; // Npm: React Connect Lines for connecting lines between elements.
import {
  HiPlusCircle,
  HiArrowUpCircle,
  HiArrowDownCircle,
} from "react-icons/hi2"; // Npm: React icons.
import { useSubscription } from "@apollo/client"; // Npm: Apollo client for GraphQL.
import { nanoid } from "nanoid";

/*
 * STYLES
 */
import "./index.css";

/*
 * PACKAGES
 */
import CheckoutFooter from "components/CheckoutFooter";
import Builder from "container/Builder";
import SuggestedFeaturesModal from "components/SuggestedFeaturesModal";
// import SuggestedFeaturesSpecificModal from "components/SuggestedFeaturesSpecificModal";
import FeaturesModal from "components/FeaturesModal";

/*
 * SUBSCRIPTION
 */
import AccountProjectRead from "./__subscription__/index.accountProjectRead.subscription";

/*
 * OBJECTS
 */
const Index = ({
  account,
  project,
  _nextStep,
  updateProjectActiveIndex,
  updateAccountProjectDetails,
}) => {
  /*
   * STATE
   */
  const toast = useToast();
  const [isBuilderOpen, setIsBuilderOpen] = React.useState(false);
  const [
    suggestedFeaturesSpecificID,
    setSuggestedFeaturesSpecificID,
  ] = React.useState();
  const [featuresSpecificID, setFeaturesSpecificID] = React.useState();
  const _requestId = React.useRef("BHANU");
  const [forceReRender, setForceReRender] = React.useState([]);
  const _accountProjectRead = React.useRef([]);
  const {
    isOpen: isSuggestedFeaturesOpen,
    onOpen: onOpenSuggestedFeatures,
    onClose: onCloseSuggestedFeatures,
  } = useDisclosure();
  const {
    isOpen: isSuggestedFeaturesSpecificOpen,
    onOpen: onOpenSuggestedFeaturesSpecific,
    onClose: onCloseSuggestedFeaturesSpecific,
  } = useDisclosure();

  const {
    isOpen: isFeaturesOpen,
    onOpen: onOpenFeatures,
    onClose: onCloseFeatures,
  } = useDisclosure();

  const variants = {
    hidden: { opacity: 0, y: "100vh" },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: "easeOut",
      },
    },
    exit: {
      opacity: 0,
      y: "100vh",
      transition: {
        duration: 1,
        ease: "easeIn",
      },
    },
  };

  const _handlePlanDelivery = () => {
    updateProjectActiveIndex(2);
  };

  console.log(project, "project");

  console.log(
    useSubscription(AccountProjectRead, {
      variables: {
        loginRequestId: _requestId.current,
        accountProjectId: project?.accountProjectDetails?.accountProjectId,
      },
      onData: ({ data, error }) => {
        // If error is caught, then report failure.
        if (error) return console.error(error);

        // Make sure data is not empty.
        if (
          _.isEmpty(data) ||
          (!_.isEmpty(data?.data) &&
            (_.isEmpty(data?.data?.AccountProjectRead) ||
              !_.isArray(data?.data?.AccountProjectRead)))
        ) {
          return toast("Something is not right.");
        }

        _accountProjectRead.current =
          data?.data?.AccountProjectRead?.[
            data?.data?.AccountProjectRead.length - 1
          ];
        updateAccountProjectDetails({
          accountProjectId:
            data?.data?.AccountProjectRead?.[
              data?.data?.AccountProjectRead.length - 1
            ]?.id,
          accountProjectFeatures:
            data?.data?.AccountProjectRead?.[
              data?.data?.AccountProjectRead.length - 1
            ]?.Features,
        });
        // Force re-render
        return setForceReRender(nanoid());
      },
      onError: (e) => console.log("-==werwer", e),
    }),
    " AccountProjectRead"
  );

  console.log(project, "project");

  // Return component.
  return (
    <>
      <Flex mb="32px" flexDir="column" className="studioFeatureInformation">
        <h3 className="heading font weight200 gradient">
          {project?.accountProjectName}
        </h3>
        <Text as="p" className="description width maxContent">
          Adding Features Changing delivery details will result in updated
          pricing and timeline.
        </Text>
        <Flex my="42px" direction="column" gap="12px" w="100%">
          <p className="subHeading followedLine width maxContent">
            Included Features
          </p>
          {_accountProjectRead?.current?.Features?.map((r, index) => (
            <Connect
              key={r?.id}
              id={`element-${index + 1}`}
              connectWith={[
                { id: "Element", color: "#E6E6E6", stroke: "dashed" },
              ]}
            >
              <Text
                w="max-content"
                fontWeight={500}
                m={0}
                cursor="pointer"
                onClick={() => {
                  onOpenFeatures();
                  setFeaturesSpecificID(r?.id);
                }}
                // className="font gradient"
              >
                {r?.displayName}&nbsp;&nbsp;
              </Text>
            </Connect>
          ))}
        </Flex>
        <Text
          onClick={() => onOpenSuggestedFeatures()}
          cursor="pointer"
          className="subHeading width maxContent theme primary"
        >
          Add More
        </Text>

        <Flex position="fixed" w="100%" left={0} bottom={0} zIndex={100}>
          <Button
            onClick={() => setIsBuilderOpen(!isBuilderOpen)}
            position="absolute"
            left="50%"
            bg="none"
            _hover={{ bg: "none" }}
            color="#FFF"
            top={10}
            cursor="pointer"
            zIndex={100}
          >
            {isBuilderOpen ? (
              <HiArrowDownCircle cursor="pointer" size={31} />
            ) : (
              <HiArrowUpCircle cursor="pointer" size={31} />
            )}
          </Button>
          <CheckoutFooter
            heading="OYO, Mobile App"
            description="Continue towards your plan delivery by moving on to the next step"
            _function={_handlePlanDelivery}
            _nextStep={_nextStep}
          />
        </Flex>

        {isBuilderOpen && (
          <Flex
            position="fixed"
            w="100%"
            h={true ? "100%" : "90px"}
            bottom={0}
            left={0}
            bgColor="primary.500"
            pl="105px"
            color="white"
          >
            <Builder
              id="StudioBuilderAppDevelopmentInformationFlow"
              showOnlyAsFooter={false}
              list={_accountProjectRead?.current?.Features}
            />
          </Flex>
        )}
      </Flex>
      <SuggestedFeaturesModal
        isOpen={isSuggestedFeaturesOpen}
        onOpen={onOpenSuggestedFeatures}
        onClose={onCloseSuggestedFeatures}
      />
      {/* <SuggestedFeaturesSpecificModal
        isOpen={isSuggestedFeaturesSpecificOpen}
        onOpen={onOpenSuggestedFeaturesSpecific}
        onClose={onCloseSuggestedFeaturesSpecific}
        project={project}
        suggestedFeaturesSpecificID={suggestedFeaturesSpecificID}
        _ProjectRead={_accountProjectRead?.current?.Features}
        account={account}
      /> */}
      <FeaturesModal
        isOpen={isFeaturesOpen}
        onOpen={onOpenFeatures}
        onClose={onCloseFeatures}
        featuresSpecificID={featuresSpecificID}
        _ProjectRead={_accountProjectRead?.current}
        account={account}
      />
    </>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  account: state.Account,
  project: state.Project,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
  updateProjectActiveIndex: (activeIndex) =>
    __dispatch({ type: "PROJECT_ACTIVE_INDEX_UPDATE", activeIndex }),
  updateAccountProjectDetails: (accountProjectDetails) =>
    __dispatch({
      type: "ACCOUNT_PROJECT_DETAILS_UPDATE",
      accountProjectDetails,
    }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORT
 */
import React from "react"; // Npm: React library.
import _ from "underscore"; // Npm: Underscore for utility.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import { Flex, Box } from "@chakra-ui/react"; // Npm: Chakra UI components.

/*
 * PACKAGES
 */
import Builder from "container/Builder";
import BannerOne from "components/BannerOne";

/*
 * OBJECTS
 */
const Index = () => {
  /*
   * STATE
   */
  // Hook assignment.
  const [showFullPage, setShowFullPage] = React.useState(false);

  // Return component.
  return (
    <Flex w="100%" flexDir="row" alignItems="center">
      <Box w="100%">
        <BannerOne
          setShowFullPage={setShowFullPage}
          showFullPage={showFullPage}
        />
      </Box>
      <Flex
        position="fixed"
        w="100%"
        h={showFullPage ? "100%" : "90px"}
        bottom={0}
        left={0}
        bgColor="primary.500"
        pl="105px"
        color="white"
      >
        <Builder showOnlyAsFooter={!showFullPage} />
      </Flex>
    </Flex>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({  });
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop-types library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useColorModeValue } from '@chakra-ui/react' // Npm: Chakra UI components.
import { createIcon } from '@chakra-ui/icons' // Npm: Chakra UI icons.


/*
 * GLOBALS
 */
export const Logo = createIcon({
  'displayName': 'Logo',
  'viewBox': '0 0 52 61',
  'path': (
    <>
      <path
        d='M34.2867 0.362305H0V33.8598C0 42.8677 7.30237 50.1701 16.3103 50.1701C16.3103 48.767 17.0118 42.9795 26.3946 43.0672C33.9008 43.1373 35.602 47.8317 35.5143 50.1701H52V18.0756C52 8.29283 44.0695 0.362305 34.2867 0.362305Z'
        fill='#3311DB'
      />
      <path
        d='M32.531 17.4605L29.3751 17.3744C28.7436 16.3923 27.2414 16.1467 26.5692 16.1467V12.3761C27.2005 12.0955 27.5922 11.4407 27.7091 11.1484C27.7968 10.7392 27.7968 9.76293 27.0953 9.13156C26.3938 8.5002 25.4584 8.63466 25.0785 8.78081C24.7277 8.89772 24.0086 9.41217 23.9385 10.5346C23.8683 11.657 24.7862 12.2299 25.2538 12.3761V16.1467C23.5 16.0766 22.6524 16.9359 22.4478 17.3744L19.2045 17.4621C15.3341 17.5667 11.9708 20.1506 10.8726 23.8634C10.0835 24.2142 8.52475 24.5561 8.41726 27.8095C8.33035 30.4402 9.70356 31.6094 10.6097 31.9309C11.1596 36.6305 15.1416 40.1737 19.8734 40.1737H32.3449C36.9246 40.1737 40.7044 36.5917 40.9503 32.0186C40.9503 31.8432 41.2484 31.8257 41.3887 31.7555C41.5641 31.6678 42.5287 31.2294 43.0547 29.8263C43.4492 28.7741 43.397 27.371 43.2301 26.4941C42.8795 24.6526 41.6224 24.0388 40.8625 23.8634C39.7644 20.1505 36.4015 17.566 32.531 17.4605Z'
        fill='white'
      />
      <rect
        x='14.0314'
        y='22.6353'
        width='23.6762'
        height='12.1012'
        rx='6.05059'
        fill='#D2DCFF'
      />
      <circle
        cx='31.3929'
        cy='28.7737'
        r='1.49072'
        fill='#3311DB'
        stroke='#3311DB'
        strokeWidth='0.175379'
      />
      <circle
        cx='20.519'
        cy='28.7737'
        r='1.49072'
        fill='#3311DB'
        stroke='#3311DB'
        strokeWidth='0.175379'
      />
      <path
        d='M12.5397 25.6168C13.5922 19.1278 20.2566 17.8417 22.4488 17.3741C17.5382 17.111 15.6673 18.1632 14.9073 18.6893C12.2415 20.3028 11.1074 22.8107 10.8736 23.863C9.11978 24.354 8.51265 25.8798 8.41829 27.1075C8.15542 30.5274 9.70444 31.6089 10.6106 31.9304C12.7151 39.7348 18.2397 40.1733 22.2733 40.1733C16.0999 39.3314 13.3289 34.7365 12.7151 32.5443C12.0136 29.8083 12.3059 26.786 12.5397 25.6168Z'
        fill='#D2DCFF'
      />
      <path
        d='M24.8179 10.008C25.5194 8.88559 26.6301 8.95574 27.0978 9.13112C25.2037 8.00869 24.2625 9.24804 24.0287 10.008C23.6078 11.2707 24.5548 12.0541 25.0809 12.2879C24.3093 11.3058 24.584 10.3588 24.8179 10.008Z'
        fill='#D2DCFF'
      />
      <rect
        x='22.7997'
        y='40.1733'
        width='7.01518'
        height='3.33221'
        fill='#D2DCFF'
      />
      <path
        d='M51.9993 50.082L35.5137 50.1697L51.9993 60.7802V50.082Z'
        fill='#3311DB'
      />
      <path
        d='M35.5135 50.1697H16.3094C16.3096 46.7498 18.7648 44.5575 20.2555 43.856C22.7108 42.8037 25.6922 42.8914 26.9197 42.8914C29.4629 42.8914 31.5673 43.6222 32.3565 44.1191C34.9521 45.6624 35.5427 48.8543 35.5135 50.1697Z'
        fill='white'
      />
    </>
  )
})


/*
 * OBJECTS
 */
const Index = ({ platform }) => {
  // Hook assignment.
  const logoColor = useColorModeValue('navy.700', 'white')

  // Return Component.
  return platform?.logo ? (
    <img
      style={{ 'width': '45px', 'height': '45px', 'borderRadius': 100 }}
      src={platform.logo}
      alt='logo'
    />
  ) : (
    <Logo h='106px' w='100%' color={logoColor} />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'platform': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'platform': __state.Platform })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)

/*
 * IMPORT
 */
import React from "react"; // Npm: React library.
import _ from "underscore"; // Npm: Underscore for utility.
import { connect } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
/*
 * CHAKRA UI
 */
import {
  Button,
  Flex,
  Input,
  Text,
  RadioGroup,
  Radio,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  FormControl,
  FormLabel,
  Spinner,
} from "@chakra-ui/react"; // Npm: Chakra UI component library.

/*
 * ASSETS
 */
import { BiWorld } from "react-icons/bi";
import { MdOutlineFileUpload } from "react-icons/md";
import  Logo  from "../Sidebar/Content/Brand/index.js";

/*
 * PACKAGES
 */
import CheckoutFooter from "components/CheckoutFooter";
/*
 * MUTATIONS
 */
import RazorpayPaymentCreate from "./__mutation__/index.createRazorPay.payment.mutation";
import RazorpayPaymentUpdate from "./__mutation__/index.updateRazorPay.payment.mutation";

/*
 * QUERY
 */
import InstallmentRead from "./__query__/index.installmentRead.query";

/*
 * STYLES
 */
import "./index.css";

/*
 * OBJECTS
 */
const Index = ({ account, project, _nextStep, updateProjectActiveIndex }) => {
  /*
   * STATE
   */

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [paymentModes, setPaymentModes] = React.useState("ONLINE");

  const _InstallmentRead = useQuery(InstallmentRead, {
    variables: {
      installmentId: project?.accountProjectDetails?.installmentId,
    },
  });


  const [
    MutationRazorpayPaymentCreate,
    { loading: LoadingRazorpayPaymentCreate },
  ] = useMutation(RazorpayPaymentCreate);
  const [
    MutationRazorpayPaymentUpdate,
    { loading: LoadingRazorpayPaymentUpdate },
  ] = useMutation(RazorpayPaymentUpdate);

  /*
   * FUNCTION
   */
  const handleScreenShotUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setVideoUploadFile(file);
    }
  };

  const _handleRazorPayPaymentCreate = async (e) => {
    e.preventDefault();
    try {
      const response = await MutationRazorpayPaymentCreate({
        variables: {
          accountProjectId: project.accountProjectDetails.accountProjectId,
          installmentId: project.accountProjectDetails.installmentId,
        },
      });

      if (
        !_.isEmpty(response?.data?.RazorpayPaymentCreate) &&
        "CREATE_SUCCESSFUL" === response?.data?.RazorpayPaymentCreate?.status
      ) {
        const orderId = response.data.RazorpayPaymentCreate?.orderJson?.id;
        const amount = response.data.RazorpayPaymentCreate?.orderJson?.amount;

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          amount: amount,
          currency: "INR",
          name: "Rootandleaves",
          description: "Test Transaction",
          image: <Logo />,
          order_id: orderId,
          handler: async function (response) {
            await MutationRazorpayPaymentUpdate({
              variables: {
                orderId: response.razorpay_order_id,
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
                installmentId: project.accountProjectDetails.installmentId,
              },
            });
          },
          prefill: {
            name: name,
            email: email,
            contact: contact,
          },
          notes: {
            address: "Customer address",
          },
          theme: {
            color: "#190793",
          },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          alert(`Payment failed: ${response.error.description}`);
        });

        rzp1.open();
      }
    } catch (error) {
      console.error("Payment creation failed:", error);
    }
  };

  return (
    <Flex w="700px">
      <Flex direction="column" gap="30px" w="100%" p="0px 0px 40px 0px">
        <h3 className="heading font weight200 gradient">Payment options</h3>
        <Text fontSize="20px" fontWeight="500">
          How do you want to pay?
        </Text>
        
        <Flex direction="column" gap="16px">
          <RadioGroup onChange={setPaymentModes} value={paymentModes}>
            <Flex w="100%" justify="space-between">
              <Flex
                boxShadow="0 0 100px 0 var(--chakra-colors-ternary-500)"
                borderRadius="20px"
                p="16px"
                w="40%"
              >
                <Radio value="ONLINE">
                  <Text fontSize="20px" fontWeight="500">
                    Online Modes
                  </Text>
                </Radio>
              </Flex>
              <Flex
                boxShadow="0 0 100px 0 var(--chakra-colors-ternary-500)"
                borderRadius="20px"
                p="16px"
                w="40%"
              >
                <Radio value="WIRE">
                  <Text fontSize="20px" fontWeight="500">
                    Wire Transfer
                  </Text>
                </Radio>
              </Flex>
            </Flex>
          </RadioGroup>
          {paymentModes === "ONLINE" && (
            <Flex w="100%" h="calc(100vh - 550px)" direction="column">
              <Tabs variant="enclosed" isFitted>
                <TabList mb="1em">
                  <Tab>UPI</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Flex direction="column">
                      <Text>
                        Amount:{" "}
                        {_InstallmentRead?.data?.InstallmentRead?.[0]?.amount}
                      </Text>
                      <form onSubmit={(e) => _handleRazorPayPaymentCreate(e)}>
                        <FormControl id="name" isRequired>
                          <FormLabel>Name</FormLabel>
                          <Input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormControl>

                        <FormControl id="email" isRequired mt="4">
                          <FormLabel>Email</FormLabel>
                          <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormControl>

                        <FormControl id="contact" isRequired mt="4">
                          <FormLabel>Contact</FormLabel>
                          <Input
                            type="text"
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                          />
                        </FormControl>

                        <Button
                          mt="6"
                          colorScheme="teal"
                          type="submit"
                          isDisabled={
                            LoadingRazorpayPaymentCreate ||
                            LoadingRazorpayPaymentUpdate
                          }
                        >
                          {LoadingRazorpayPaymentCreate ||
                          LoadingRazorpayPaymentUpdate ? (
                            <Spinner />
                          ) : (
                            "Pay  Now"
                          )}
                        </Button>
                      </form>
                    </Flex>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          )}

          {paymentModes === "WIRE" && (
            <Flex gap="10px" mt="20px" direction="column">
              <Text fontSize="15px" lineHeight="17px" fontWeight="500">
                Wire transfer details
              </Text>
              <Text fontSize="12px" lineHeight="15px" fontWeight="500">
                Make your payment now and we'll contact you, as soon as we
                receive it.
              </Text>
              <Text fontSize="12px" lineHeight="15px" fontWeight="500">
                Send us a screenshot of your payment confirmation to speed up
                the process.
              </Text>
              <Flex direction="column">
                <Flex gap="5px" align="center">
                  <BiWorld color="blue" />
                  World - transfer details
                </Flex>
                <Flex direction="column" m="10px 0px 10px 0px" gap="5px">
                  <Flex justify="space-between">
                    <Text fontWeight="500">Company Name:</Text>
                    <Text>ENGINEER.AI INDIA PRIVATE LIMITED</Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text fontWeight="500">Account No:</Text>
                    <Text>103105000129</Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text fontWeight="500">Account Type:</Text>
                    <Text>Trade Current Account</Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text fontWeight="500">IFSC Code:</Text>
                    <Text>ICIC0001031</Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text fontWeight="500">Swift Code:</Text>
                    <Text>ICICINBBCTS</Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text fontWeight="500">Bank:</Text>
                    <Text>ICICI BANK LIMITED</Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text fontWeight="500">Bank Address:</Text>
                    <Text>
                      SCO 59 & 60 Old Judicial Complex, Near More Chowk,
                      Sector-15, Gurgaon(HR) - 122001
                    </Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text fontWeight="500">Branch Code:</Text>
                    <Text>001031</Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text fontWeight="500">PAN No:</Text>
                    <Text>AAHCS3816N</Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text fontWeight="500">CIN:</Text>
                    <Text>U72900DL2003PTC121854</Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text fontWeight="500">HSN Code:</Text>
                    <Text>
                      998314 - Information technology (IT) design and
                      development services
                    </Text>
                  </Flex>
                  <Flex justify="space-between">
                    <Text fontWeight="500">Reference:</Text>
                    <Text>646749</Text>
                  </Flex>
                </Flex>
                <Flex gap="10px" direction="column">
                  <Text fontSize="15px" fontWeight="500">
                    Already made the transfer?
                  </Text>
                  <Text fontSize="12px" fontWeight="500">
                    If you've already completed the transfer, please feel free
                    to share a screenshot of your payment confirmation with us
                    to expedite the procedure.
                  </Text>
                  <Flex w="40%">
                    <Button
                      bg="primary.500"
                      color="white"
                      _hover={{ bg: "primary.600", color: "white" }}
                      onClick={() => {
                        // Trigger the hidden file input when the button is clicked
                        document.getElementById("transferInput").click();
                      }}
                      w="100%"
                    >
                      <MdOutlineFileUpload size="25px" />
                      Upload Transfer ScreenShot
                    </Button>
                    {/* Hidden file input */}
                    <Input
                      type="file"
                      id="transferInput"
                      onChange={handleScreenShotUpload}
                      display="none"
                    />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex position="fixed" w="100%" left={0} bottom={0} zIndex={100}>
        <CheckoutFooter
          heading="OYO, Mobile App"
          description="Continue to refine your idea by moving on to the next step"
          _nextStep={_nextStep}
        />
      </Flex>
    </Flex>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  account: state.Account,
  project: state.Project,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
  updateProjectActiveIndex: (activeIndex) =>
  __dispatch({ type: "PROJECT_ACTIVE_INDEX_UPDATE", activeIndex }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORTS
 */
import { thunk } from 'redux-thunk' // Npm: redux thunk library.
import AutoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2' // Npm: redux state reconcile.
import { applyMiddleware, combineReducers, createStore } from 'redux' // Npm: redux library.
import { persistReducer, persistStore } from 'redux-persist' // Npm: redux persist state library.
import { encryptTransform } from 'redux-persist-transform-encrypt' // Npm: redux encryption library.


/*
 * SIBLINGS
 */
import * as Reducer from './reducer'


/*
 * STORAGE
 */
import Storage from './Storage'

/*
 * GLOBALS
 */
const _configuration = {
  'key': 'primaryKey_1',
  "blackList" : [],
  "whitelist" : ["Project" , "Account" , "Ticket"],
  'storage': Storage,
  'transforms': [
    encryptTransform({
      'secretKey': 'I5OmQl4QFf',
      'onError': error => { throw error }
    })
  ],
  'stateReconciler': AutoMergeLevel2
}
const _PersistedReducer = persistReducer(_configuration, combineReducers(Reducer))
const _store = createStore(_PersistedReducer, applyMiddleware(thunk))


/*
 * PERSIST
 */
const Persist = persistStore(_store)


/*
 * EXPORTS
 */
export default _store
export { Persist }

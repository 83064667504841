/*
 * IMPORT
 */
import React from "react"; // Npm: React library.
import _ from "underscore"; // Npm: Underscore for utility.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import { HiChatBubbleOvalLeftEllipsis } from "react-icons/hi2"; // Npm: React icons.
import { Flex, Input, Button } from "@chakra-ui/react"; // Npm: Chakra UI components.
import { useLazyQuery } from "@apollo/client";

/*
 * PACKAGES
 */
import Flower from "lib/Flower";

/*
 * STYLES
 */
import "./index.css";

/*
 * QUERY
 */
import GrootSayQuery from "./__query__/index.groot.say.query";

/*
 * OBJECTS
 */
const Index = ({ inView, setShowFullPage, showFullPage }) => {
  /*
   * STATE
   */
  const _primaryColor = "#190793";
  const _secondaryColor = "#02044A";
  const _ternaryColor = "#190793";
  const _sphereRef = React.useRef({});
  const [conversation, setConversation] = React.useState([]);
  const [message, setMessage] = React.useState("");

  const [
    MutationGrootSayQuery,
    MutationGrootSayQueryResponse,
  ] = useLazyQuery(GrootSayQuery, { context: { clientName: "Python" } });

  /*
   * FUNCTIONS
   */
  const _OnSubmit = async () => {
    // Mutation request.
    const _MutationGrootSay = await MutationGrootSayQuery({
      variables: { message },
    });

    /*
     * If MutationGrootSay caught any exception
     * then report failure.
     */
    if (_MutationGrootSay?.error instanceof Error)
      throw new Error(_MutationGrootSay?.error);

    // Update conversation.
    setConversation([
      ...conversation,
      {
        message: message,
        response: _MutationGrootSay?.data?.GrootSay?.response,
      },
    ]);

    // Clear input.
    setMessage("");
  };

  /*
   * USEEFFECT
   */
  React.useEffect(() => {
    // Const assignment.
    const _el = document.getElementById("BannerSphere");

    // Only initialize if document is not ready.
    if ("complete" === document.readyState && !_.isEmpty(_el)) {
      // If _sphere already exists.
      // then dispose it before creating new one.
      if (!_.isEmpty(_sphereRef.current)) _sphereRef.current?.dispose();

      // Render sphere.
      _sphereRef.current = new Flower({
        damping: 0.0,
        container: _el,
        primaryColor: _primaryColor,
        secondaryColor: _secondaryColor,
        ternaryColor: _ternaryColor,
      });
    }
  }, [inView]);

  return (
    <Flex flexDir="column" className="componentBannerOne">
      <div className="sphereWrapper">
        <div id="BannerSphere" />
      </div>
      <section className="gradientWrapper topLeft round">
        <canvas className="gradient gradient1 color theme" />
        <div className="overlay occlusion" />
      </section>
      <Flex className="gradient" position="relative" flexDirection="column">
        <Flex
          pt="70px"
          justify="flex-start"
          align="center"
          direction="column"
          w="100%"
          h="100%"
        >
          <h3 className="heading font gradient">
            <bold style={{ fontWeight: 900 }}>Holla</bold>,{" "}
            <span className="font weight200">Groot</span>
          </h3>
          <p
            className="description"
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: 0,
              marginBottom: 42,
            }}
          >
            Build Application, <bold style={{ fontWeight: 900 }}>Faster</bold>,{" "}
            <bold style={{ fontWeight: 900 }}>Safer</bold> and most important{" "}
            <bold style={{ fontWeight: 900 }}>Scalable</bold>
          </p>
          <Flex
            px="32px"
            width="30%"
            flexDir="row"
            position="relative"
            justifyContent="center"
            alignItems="center"
            bg="primary.500"
            borderRadius="100px"
            boxShadow="0 0 100px 10px #3311DB"
          >
            <Input
              p="0"
              w="100%"
              _placeholder={{ color: "white" }}
              h={20}
              fontSize="16px"
              borderWidth="0"
              placeholder="Start Conversation.."
              outline="0"
              color="white"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              _focus={{ border: "none", borderWidth: 0, bg: "none" }}
              _hover={{ border: "none", borderWidth: 0, bg: "none" }}
            />
            <Button
              color="white"
              position="absolute"
              right="20px"
              bg="none"
              onClick={() => setShowFullPage(!showFullPage)}
            >
              {MutationGrootSayQueryResponse.loading ? (
                <Spinner size="sm" />
              ) : (
                <HiChatBubbleOvalLeftEllipsis size={21} />
              )}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  theme: state.Theme,
  account: state.Account,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORTS
 */
import React from "react"; // Npm: React.js library.
import PropTypes from "prop-types"; // Npm: React.js prop types for type checking.
import _ from "underscore"; // Npm: Utility module.
import { Route, Switch, useLocation, Redirect } from "react-router-dom"; // Npm: React router dom library.
import { connect } from "react-redux"; // Npm: react native redux library.
import { Toaster } from "react-hot-toast"; // Npm: React hot toast.
import { ApolloProvider } from "@apollo/client"; // Npm: Apollo client for handling graphql.
import { HiCodeBracketSquare } from "react-icons/hi2"; // Npm: React icons.
import { Flex, useTheme } from "@chakra-ui/react"; // Npm: A simple, modular and accessible component library for React.js.
import { RiCustomerService2Line } from "react-icons/ri"; // Npm: React icons.

/*
 * PACKAGES
 */
import "iife";
import Sidebar from "components/Sidebar";
import GradientStripe from "lib/Gradient";

/*
 * APOLLO
 */
import { ApolloClientProvider } from "./index.apollo.client";

/*
 * STYLES
 */
import "./index.style.css";

/*
 * SCREENS
 */
import Page404 from "Pages/404";
import PageHome from "Pages/Home";
import PageStudio from "Pages/Studio";
import PageSupport from "Pages/Support";
import PageProfile from "Pages/Profile";
import PageSupportConversation from "Pages/Support/conversation";
import Login from "components/Login";

/*
 * OBJECTS
 */
const Index = ({
  account,
  project,
  ticket,
  AccountUpdate,
  AccountLogout,
  ClearEverything,
  ...props
}) => {

  
  // Const assignment.
  const _routes = [
    {
      name: "Home",
      path: "/",
      icon: (
        <div style={{ width: "55px", height: "55px" }} id="SideMenuGroot" />
      ),
      component: PageHome,
    },
    {
      name: "Build App",
      path: "/studio",
      icon: <HiCodeBracketSquare size={28} />,
      component: PageStudio,
      disabled: !account.isProject,
    },
    {
      name: "Support",
      path: "/support",
      icon: <HiCodeBracketSquare size={28} />,
      component: PageSupport,
    },
    {
      name: "Support Conversation",
      path: "/support/conversation",
      icon: <RiCustomerService2Line size={24} />,
      hide: true,
      component: PageSupportConversation,
    },
    {
      name: "Profile",
      path: "/profile",
      icon: <HiCodeBracketSquare size={28} />,
      component: PageProfile,
    },
  ];
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const _ApolloClientInstance_ = React.useRef(
    ApolloClientProvider(account, { ClearEverything })
  );
  const _locationRef = useLocation();
  const _themeRef = useTheme();
  const _gradient1Ref = React.useRef({});
  const _gradient2Ref = React.useRef({});
  const _gradient3Ref = React.useRef({});
  const _gradient4Ref = React.useRef({});
  const _routeRef = React.useRef([
    ..._routes,
    ..._.compact(_.flatten(_.pluck(_routes, "items"))),
  ]);

  // Object assignment.
  const _GetActiveNavBar = (routes) => {
    // Local variable.
    let i;

    // Const assignment.
    const _activeNavBar = false;

    // Loop through routes.
    for (i = 0; i < routes.length; i++) {
      // If route is collapsible.
      if (routes[i].collapse) {
        // Const assignment.
        const _collapseActiveNavbar = _GetActiveNavBar(routes[i].items);

        // If route is collapsible.
        if (_collapseActiveNavbar !== _activeNavBar)
          return _collapseActiveNavbar;
      } else if (routes[i].category) {
        // Const assignment.
        const _collapseActiveNavbar = _GetActiveNavBar(routes[i].items);

        // If route is category.
        if (_collapseActiveNavbar !== _activeNavBar)
          return _collapseActiveNavbar;
      } else if (
        -1 !== window.location.href.indexOf(routes[i].layout + routes[i].path)
      ) {
        // If route is not collapsible.
        return routes[i].secondary;
      }
    }

    // Return active navbar.
    return _activeNavBar;
  };
  const _GetActiveNavBarText = (routes) => {
    // Local variable.
    let i;

    // Const assignment.
    const _activeNavBar = false;

    // Loop through routes.
    for (i = 0; i < routes.length; i++) {
      // If route is collapsible.
      if (routes[i].collapse) {
        // Const assignment.
        const _collapseActiveNavbar = _GetActiveNavBarText(routes[i].items);

        // If route is collapsible.
        if (_collapseActiveNavbar !== _activeNavBar)
          return _collapseActiveNavbar;
      } else if (routes[i].category) {
        // Const assignment.
        const _categoryActiveNavBar = _GetActiveNavBarText(routes[i].items);

        // If route is category.
        if (_categoryActiveNavBar !== _activeNavBar)
          return _categoryActiveNavBar;
      } else if (
        -1 !== window.location.href.indexOf(routes[i].layout + routes[i].path)
      ) {
        // If route is not collapsible.
        return routes[i].messageNavbar;
      }
    }

    // Return active navbar.
    return _activeNavBar;
  };

  // Event handler.
  React.useEffect(() => {
    // Update apollo client instance.
    _ApolloClientInstance_.current = ApolloClientProvider(account, {
      ClearEverything,
    });

    // If user is not logged in then redirect to login page.
    // if (
    //   "/login" !== _locationRef.pathname &&
    //   account &&
    //   !account.isUserLoggedIn
    // )
      setIsLoggedIn(false);
  }, []);

  React.useEffect(() => {
    // Only initialize if document is available.
    if (document) {
      // Const assignment.
      const _gradientContainer = {
        gradient1: _gradient1Ref,
        gradient2: _gradient2Ref,
        gradient3: _gradient3Ref,
        gradient4: _gradient4Ref,
      };

      /*
       * Loop over gradient container and initialize all
       * Available gradients.
       */
      for (const [classNameOfContainer, refOfContainer] of Object.entries(
        _gradientContainer
      )) {
        // Const assignment.
        const _g1 = document.querySelector(`.${classNameOfContainer}`);

        /*
         * Play each gradient respective to condition
         * Where it is available.
         */
        if (_g1) {
          // Const assignment.
          const _r = _.random(1000);

          // Initialize gradient.
          refOfContainer.current = new GradientStripe({
            seed: _r,
          }).initGradient(`.${classNameOfContainer}`);

          // Play gradient.
          refOfContainer.current?.play?.();
        }
      }

      /*
       * If the component is unmounted, unsubscribe
       * From the event with the `off` method:
       */
      return () => {
        /*
         * Loop over gradient container and initialize all
         * available gradients.
         */
        for (const [classNameOfContainer, refOfContainer] of Object.entries(
          _gradientContainer
        )) {
          // Const assignment.
          const _g1 = document.querySelector(`.${classNameOfContainer}`);

          /*
           * Play each gradient respective to condition
           * Where it is available.
           */
          if (_g1) {
            // Play gradient.
            refOfContainer.current?.pause?.();
          }
        }
      };
    }
  }, []);


  // Return component.
  return (
    <ApolloProvider client={_ApolloClientInstance_.current}>
      <Switch>
        {_routeRef.current.map((i, index) => (
          <Route
            key={index}
            exact
            path={i.path}
            render={(__props) => {
              // Const assignment.
              const Component = i.component;

              if (!project.projectDetails && !project.accountProjectDetails && i.path === "/studio") {
                return <Redirect to="/" />;
              }

              if (!ticket?.ticketId && i.path === "/support/conversation") {
                return <Redirect to="/support" />;
              }

              // Return component.
              return account.isUserLoggedIn ? (
                <Flex w="calc(100% - 105px)">
                  <Login />
                  <Component {...__props} />
                </Flex>
              ) : (
                <Flex w="100%" justifyContent="space-between">
                  <Flex w="105px" position="relative" zIndex={1000}>
                    <Sidebar
                      routes={_routes}
                      display="none"
                      {...props}
                      inView={true}
                    />
                  </Flex>
                  <Flex
                    w="calc(100% - 105px)"
                    flexDirection="column"
                    position="relative"
                    overflowY={"/" === i.path ? void 0 : "hidden"}
                  >
                    {account.isUserLoggedIn ? <Login /> : void 0}
                    <Component {...__props} />
                    <Toaster
                      position="bottom-left"
                      reverseOrder={false}
                      containerClassName="toaster"
                      toastOptions={{
                        duration: 3000,
                        style: {
                          background: _themeRef.colors.purple[700],
                          color: _themeRef.colors.white,
                        },
                      }}
                    />
                  </Flex>
                </Flex>
              );
            }}
          />
        ))}
        <Route path="*" component={Page404} />
      </Switch>
    </ApolloProvider>
  );
};

/*
 * PROPTYPES
 */
Index.propTypes = {
  account: PropTypes.object.isRequired,
  AccountUpdate: PropTypes.func.isRequired,
  AccountLogout: PropTypes.func.isRequired,
  ClearEverything: PropTypes.func.isRequired,
};
Index.defaultProps = {};

/*
 * REDUX
 */
const _MapStateToProps = (__state) => ({
  account: __state.Account,
  ticket: __state.Ticket,
  platform: __state.Platform,
  project: __state.Project,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
  ClearEverything: () => {
    __dispatch({ type: "ACCOUNT_CLEAR" });
    __dispatch({ type: "PROJECT_CLEAR" });
    __dispatch({ type: "TICKET_CLEAR" });
    __dispatch({ type: "PASS_ON_CLEAR" });
  },
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

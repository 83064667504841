/*
 * IMPORT
 */
import "reactflow/dist/style.css"; // Npm: React Flow stylesheet.
import React from "react"; // Npm: React library.
import _ from "underscore"; // Npm: Underscore for utility.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import { Button, Flex, Text } from "@chakra-ui/react"; // Npm: Chakra UI components.
import { HiDevicePhoneMobile } from "react-icons/hi2"; // Npm: React icons.
import { Connect, ConnectProvider } from "react-connect-lines"; // Npm: React Connect Lines for connecting lines between elements.

/*
 * PACKAGES
 */
import StudioAppInformation from "components/StudioAppInformation";
import StudioFeatureInformation from "components/StudioFeatureInformation";
import PlanDeliveryInformation from "components/PlanDeliveryInformation";
import PaymentDetailInformation from "components/PaymentDetailInformation";
import PaymentOptionsInformation from "components/PaymentOptionsInformation";
import Prototype from "components/Prototype";
import ReactFlow from "components/ReactFlow";

/*
 * STYLES
 */
import "./index.css";

/*
 * OBJECTS
 */
const Index = ({ account , project }) => {
  /*
   * STATE
   */
  const [landscape, setLandscape] = React.useState(false);

  const headings = [
    "Look a like",
    "Refine IDEA!",
    "Plan Delivery",
    "Payment Details",
    "Payment Options",
  ];


  // Return component.
  return (
    <ConnectProvider>
      <Flex className="studio">
        <section className="gradientWrapper topRight round">
          <canvas className="gradient gradient1 color theme" />
          <div className="overlay occlusion" />
        </section>
        <Flex
          h="100%"
          flexDir="row"
          w="calc(100% - 55px)"
          alignItems="center"
          justifyContent="space-between"
          px="102px"
          py="52px"
        >
          <Flex h="100%" flexDir="column">
            <Text
              zIndex={10}
              as="h1"
              className="heading font gradient greeting"
            >
              <span style={{ fontWeight: 200 }}>Holla</span>,{" "}
              <bold style={{ fontWeight: 900 }}>Groot</bold>
            </Text>
            <Flex
              mt={42}
              zIndex={10}
              position="relative"
              justifyContent="flex-start"
              alignItems="center"
              gap="12px"
              className="stepContainer"
            >
              {headings.map((heading, index) => (
                <Flex>
                  <h3
                    className={`subHeading ${
                      project?.activeIndex === index ? "" : "inactive"
                    } width maxContent followedLine`}
                  >
                    {heading}
                  </h3>
                </Flex>
              ))}
            </Flex>

            <Flex gap="12px" mt="42px" position="relative" zIndex={10}>
              <Button
                color={landscape ? void 0 : "primary.500"}
                minW="35px"
                w="35px"
                h="35px"
                borderRadius="100%"
                p="2px"
                transform="rotate(0deg)"
                onClick={() => setLandscape(false)}
              >
                <HiDevicePhoneMobile size={21} />
              </Button>
              <Button
                color={landscape ? "primary.500" : void 0}
                minW="35px"
                w="35px"
                h="35px"
                borderRadius="100%"
                p="2px"
                transform="rotate(90deg)"
                onClick={() => setLandscape(true)}
              >
                <HiDevicePhoneMobile size={21} />
              </Button>
            </Flex>
            <Flex
              position="absolute"
              width="10px"
              height="100%"
              boxShadow="0 0 400px 40px #FFF"
              left={0}
            />
            <Flex py="42px" flexDir="column" zIndex={10}>
              {project?.activeIndex === 0 && (
                <StudioAppInformation
                  account={account}
                  _nextStep={headings?.[1]}
                />
              )}
              {project?.activeIndex === 1 && (
                <StudioFeatureInformation
                  account={account}
                  _nextStep={headings?.[2]}
                />
              )}
              {project?.activeIndex === 2 && (
                <PlanDeliveryInformation
                  account={account}
                  _nextStep={headings?.[3]}
                />
              )}
              {project?.activeIndex === 3 && (
                <PaymentDetailInformation
                  account={account}
                  _nextStep={headings?.[4]}
                />
              )}
              {project?.activeIndex === 4 && (
                <PaymentOptionsInformation
                  account={account}
                  _nextStep="Submit"
                />
              )}
            </Flex>
            <Flex
              zIndex={0}
              position="absolute"
              w="100%"
              h="100%"
              right={0}
              top={0}
            >
              <ReactFlow />
            </Flex>
          </Flex>
          <Connect id="Element">
            <Flex
              zIndex={9}
              position="absolute"
              top="20"
              right={landscape ? -20 : 40}
            >
              <Prototype landscape={landscape} />
            </Flex>
          </Connect>
        </Flex>
      </Flex>
    </ConnectProvider>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({ account: state.Account , project: state.Project });
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

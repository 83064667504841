/*
 * IMPORT
 */
import "reactflow/dist/style.css"; // Npm: React Flow stylesheet.
import "react-quill/dist/quill.snow.css"; // Npm: React Quill stylesheet.
import React from "react"; // Npm: React library.
import ReactQuill from "react-quill"; // Npm: React Quill.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import { Button, Flex, Text, Input, useToast } from "@chakra-ui/react"; // Npm: Chakra UI components.
import { HiChatBubbleLeftEllipsis } from "react-icons/hi2"; // Npm: React icons.
import useSpeechToText from "react-hook-speech-to-text";
import { HiArrowUturnRight } from "react-icons/hi2"; // Npm: React icons.
import { useMutation, useSubscription } from "@apollo/client";
import { nanoid } from "nanoid";
import _ from "lodash";

/*
 * PACKAGES
 */
import MdRenderer from "components/MdRenderer";
import Flower from "lib/Flower";

/*
 * MUTATION
 */
import TicketStatus from "./__mutation__/index.resolveTicket.mutation";
import TicketReplyCreate from "./__mutation__/index.ticketReply.mutation";

/*
 * SUBSCRIPTION
 */
import TicketReplyRead from "./__subscription__/index.ticketConversation.subscription";

/*
 * STYLES
 */
import "../index.css";

/*
 * OBJECTS
 */
const Index = ({ ticket }) => {
  /*
   * STATE
   */
  const toast = useToast();
  const [value, setValue] = React.useState("");
  const _sphereRef = React.useRef({});
  const _primaryColor = "#190793";
  const _secondaryColor = "#02044A";
  const _ternaryColor = "#190793";
  const _TicketReplyRead = React.useRef([]);
  const _requestId = React.useRef("ADMIN");
  const [forceReRender, setForceReRender] = React.useState([]);
  const [replyDescription, setReplyDescription] = React.useState("");

  const {
    error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    crossBrowser: true,
    useLegacyResults: false,
  });

  const [MutationTicketStatus, { loading: LoadingTicketStatus }] = useMutation(
    TicketStatus
  );

  const [
    MutationTicketReplyCreate,
    { loading: LoadingTicketReplyCreate },
  ] = useMutation(TicketReplyCreate);

  /*
   * FUNCTIONS
   */
  const _handleTicketStatus = async () => {
    try {
      const response = await MutationTicketStatus({
        variables: {
          ticketId: ticket?.ticketId,
          ticketStatus: false,
        },
      });
      if (response?.data?.TicketStatus?.status === "PUBLISH_SUCCESSFUL") {
      }
    } catch (error) {
      console.error("Error changing ticket status", error);
    }
  };

  const _handleTicketReply = async () => {
    try {
      const response = await MutationTicketReplyCreate({
        variables: {
          ticketId: ticket?.ticketId,
          loginRequestId: _requestId.current,
          repliedBy: "USER",
          replyDescription: replyDescription,
        },
      });
      if (
        response?.data?.TicketReplyCreate?.status === "PUBLISHED_SUCCESSFULLY"
      ) {
        setReplyDescription("");
      }
    } catch (error) {
      console.error("Error changing ticket status", error);
    }
  };

  console.log(
    useSubscription(TicketReplyRead, {
      variables: {
        skip: 0,
        take: 20,
        loginRequestId: _requestId.current,
        ticketId: ticket?.ticketId,
      },
      onData: ({ data, error }) => {
        // If error is caught, then report failure.
        if (error) return console.error(error);

        // Make sure data is not empty.
        if (
          _.isEmpty(data) ||
          (!_.isEmpty(data?.data) &&
            (_.isEmpty(data?.data?.TicketReplyRead) ||
              !_.isArray(data?.data?.TicketReplyRead)))
        ) {
          return toast("Something is not right.");
        }

        _TicketReplyRead.current = data?.data?.TicketReplyRead?.[0];
        // Force re-render
        return setForceReRender(nanoid());
      },
      onError: (e) => console.log("-==werwer", e),
    }),
    " TicketReadReply"
  );

  /*
   * USE EFFECT
   */
  React.useEffect(() => {
    // Const assignment.
    const _el = document.getElementById("BuilderAiListeningSphere");

    // Only initialize if document is not ready.
    if (
      "complete" === document.readyState &&
      !_.isEmpty(_el) &&
      _.isEmpty(_sphereRef.current)
    ) {
      // Render sphere.
      _sphereRef.current = new Flower({
        damping: 0.0,
        container: _el,
        primaryColor: _primaryColor,
        secondaryColor: _secondaryColor,
        ternaryColor: _ternaryColor,
      });
    }
  }, []);
  React.useEffect(() => {
    // Const assignment.
    const _el = document.getElementById("BuilderAiListeningSphere");

    // Only initialize if document is not ready.
    if ("complete" === document.readyState && !_.isEmpty(_el)) {
      /*
       * If _sphere already exists.
       * then dispose it before creating new one.
       */
      if (!_.isEmpty(_sphereRef.current)) _sphereRef.current?.dispose();

      // Render sphere.
      _sphereRef.current = new Flower({
        damping: 0.0,
        container: _el,
        primaryColor: _primaryColor,
        secondaryColor: _secondaryColor,
        ternaryColor: _ternaryColor,
      });
    }
  }, []);

  // Return component.
  return (
    <>
      <section className="gradientWrapper topRight round">
        <canvas className="gradient gradient1 color theme" />
        <div className="overlay occlusion" />
      </section>
      <Flex
        flexDir="column"
        h="100%"
        w="100%"
        alignItems="start"
        justifyContent="space-between"
        px="102px"
        py="52px"
      >
        <Text
          as="h1"
          className="heading font gradient greeting"
          lineHeight="unset"
        >
          <span style={{ fontWeight: 200 }}>Studio</span>,{" "}
          <span style={{ fontWeight: 900 }}>Case</span>
        </Text>
        <Flex
          mt={42}
          zIndex={100}
          position="relative"
          justifyContent="flex-start"
          alignItems="center"
          gap="12px"
          className="stepContainer"
        >
          <Flex>
            <h3 className="subHeading width inactive maxContent followedLine">
              SUPPORT
            </h3>
          </Flex>
          &gt;
          <Flex>
            <h3 className="subHeading width maxContent followedLine">
              {_TicketReplyRead.current?.ticketNumber}
            </h3>
          </Flex>
        </Flex>
        <MdRenderer data={_TicketReplyRead.current} />
        <Button onClick={() => _handleTicketStatus()}>Change status</Button>
        <Flex
          w="450px"
          h="100%"
          flexDir="column"
          position="fixed"
          bottom="32px"
          right="22px"
        >
          <Flex
            h="100%"
            flexDir="column"
            m="20px 20px 10px 0px"
            justifyContent="space-between"
          >
            {!_TicketReplyRead.current ? (
              <Flex p="42px" h="100%" flexDir="column" justifyContent="center">
                <Text fontWeight="500">Holla, Groot</Text>
                <Text fontWeight="900">
                  {interimResult ? interimResult : "Listening.."}
                </Text>
                <ul>
                  {/* {results.map((result) => (
                  <li key={result.timestamp}>{result.transcript}</li>
                ))} */}
                </ul>
              </Flex>
            ) : (
              <Flex p="30px" gap="20px" overflowY="scroll" direction="column">
                {_TicketReplyRead.current?.TicketReply?.slice()
                  .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                  .map((reply, index) => (
                    <Flex
                      key={index}
                      justify={
                        reply.repliedBy === "USER" ? "flex-end" : "flex-start"
                      }
                      w="100%"
                    >
                      <Flex
                        direction="column"
                        bg={
                          reply.repliedBy === "USER" ? "blue.500" : "gray.200"
                        }
                        color={reply.repliedBy === "USER" ? "white" : "black"}
                        borderRadius="10px"
                        p="10px"
                        maxW="70%"
                      >
                        <Text fontSize="14px" fontWeight="bold">
                          {reply.repliedBy}
                        </Text>
                        <Text fontSize="14px">{reply.replyDescription}</Text>
                        <Text
                          fontSize="12px"
                          alignSelf="flex-end"
                          color={reply.repliedBy === "USER" ? "white" : "black"}
                        >
                          {new Date(reply.createdAt).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )}
                        </Text>
                      </Flex>
                    </Flex>
                  ))}
              </Flex>
            )}

            <Flex cursor="pointer" flexDir="row" alignItems="center">
              <div
                onClick={isRecording ? stopSpeechToText : startSpeechToText}
                className="sphere"
              >
                <div id="BuilderAiListeningSphere" />
              </div>
              <Flex flexDir="row" minW="320px" gap="12px">
                <Input
                  placeholder="Type your message here.."
                  bgColor="primary.600"
                  borderWidth={0}
                  borderRadius="12px"
                  color="white"
                  _placeholder={{ color: "#FFF" }}
                  fontSize="15px"
                  onChange={(e) => setReplyDescription(e.target.value)}
                  value={replyDescription}
                />
                <Button onClick={_handleTicketReply} bg="primary.500">
                  <HiArrowUturnRight fontSize={24} color="white" />
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({ ticket: state.Ticket });
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

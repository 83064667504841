/*
 * IMPORT
 */
import React from "react"; // Npm: React library.
import _ from "underscore"; // Npm: Underscore for utility.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import { HiArrowRightCircle } from "react-icons/hi2"; // Npm: React icons.
import { Button, Flex, Text , Spinner } from "@chakra-ui/react"; // Npm: Chakra UI components.

/*
 * STYLES
 */
import "./index.css";

/*
 * OBJECTS
 */
const Index = ({ heading, description, _function, _nextStep, _loading , _agree }) => (
  // Return component.
  <Flex
    w="100%"
    h="100%"
    p="22px"
    flexDir="row"
    alignItems="center"
    justifyContent="center"
    className="checkoutFooter"
  >
    <Flex w="70%" flexDir="column">
      <Text
        fontSize={17}
        color="white"
        fontWeight={500}
        position="relative"
        top="5px"
      >
        {heading ?? "Digital Bhikari"}
      </Text>
      <Text
        mt="12px"
        color="white"
        fontWeight={600}
        fontSize="sm"
        position="relative"
        top="-5px"
      >
        {description ??
          "It is a long established fact that a reader will be distracted by the readable content of a page."}
      </Text>
    </Flex>
    <Button
      onClick={_function}
      h="52px"
      borderRadius="24px"
      fontSize="15px"
      bg="none"
      color="gray.300"
      bgColor="primary.600"
      _hover={{ bg: "primary.700" }}
      isDisabled={_agree && !_agree}
    >
      Continue to {_nextStep}&nbsp;&nbsp;
      {_loading ? <Spinner /> : <HiArrowRightCircle size={21} />}
    </Button>
  </Flex>
);

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  theme: state.Theme,
  account: state.Account,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

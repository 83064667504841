/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $ticketCategory: String
    $ticketDescription: String
    $ticketImage: JSON
    $ticketSeverity: Int
    $loginRequestId: ID!
  ) {
    TicketCreate(
      input: {
        ticketCategory: $ticketCategory
        ticketDescription: $ticketDescription
        ticketImage: $ticketImage
        ticketSeverity: $ticketSeverity
      }
      loginRequestId: $loginRequestId
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

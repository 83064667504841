/*
 * IMPORT
 */
import React from "react";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";

/*
 * CHAKRA UI
 */
import {
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  Button,
  Spinner,
  IconButton,
  Text,
  Select,
} from "@chakra-ui/react";

/*
 * ICONS AND IMAGES
 */
import { FaPlus } from "react-icons/fa6";

/*
 * QUERY
 */
function Index({
  account,
  project,
  isOpen,
  onClose,
  loading,
  setFeaturesId,
  setFeatureCostId,
  featuresId,
  featureCostId,
  functionality,
}) {

  /*
   * STATE
   */


  /*
   * FUNCTIONS
   */

  function handleFeaturesID(e) {
    setFeaturesId(e.target.value);
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Your Journey</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>Select where you want to add this feature</p>
          <Select placeholder="Select Features" onChange={handleFeaturesID}>
            {project?.accountProjectDetails?.accountProjectFeatures?.map(
              (r) => (
                <option value={r?.id}>{r?.displayName}</option>
              )
            )}
          </Select>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="purple"
            fontSize="13px"
            mr={3}
            onClick={functionality}
            isDisabled={featuresId === null || loading ? true : false}
          >
            {loading ? <Spinner /> : "ADD FEATURE"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  account: state.Account,
  project: state.Project,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
   updateAccountProjectDetails: (accountProjectDetails) =>
    __dispatch({ type: "ACCOUNT_PROJECT_DETAILS_UPDATE", accountProjectDetails }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  subscription AccountLoginWithGoogleSubscription($loginRequestId: ID!) {
    AccountLoginWithGoogle(loginRequestId: $loginRequestId) {
      message
      status
      token
    }
  }
`

/*
 * EXPORTS
 */
export default Index

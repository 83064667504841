/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $color: [String]
    $featureIds: [ID]
    $featuresIds: [ID]
    $projectId: ID
    $projectName: String
    $projectType: [String]
    $thumbnailStoredAt: JSON
    $websiteUrl: String
    $loginRequestId: ID!
  ) {
    AccountProjectCreate(
      color: $color
      featureIds: $featureIds
      featuresIds: $featuresIds
      projectId: $projectId
      projectName: $projectName
      projectType: $projectType
      thumbnailStoredAt: $thumbnailStoredAt
      websiteUrl: $websiteUrl
      loginRequestId: $loginRequestId
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

/*
 * GLOBALS
 */
export const _initialState = {
    ticketId: null,
  };
  
  /*
   * OBJECTS
   */
  const _TicketReducer = (__prevState = _initialState, __action) => {
    /*
     * Switch case for handling actions on
     * account store.
     */
    switch (__action.type) {
        case "TICKET_ID_UPDATE":
          // Update the project details in the state.
          return {
            ...__prevState,
            ticketId: __action.ticketId,
          };
        case "TICKET_CLEAR":
        // Return combined state.
        return {
          ..._initialState,
        };
      default:
        // Return old state.
        return __prevState;
    }
  };
  
  /*
   * EXPORTS
   */
  export default _TicketReducer;
  
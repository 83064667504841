/*
 * IMPORT
 */
import React from "react"; // Npm: react.js library.
import PropTypes from "prop-types"; // Npm: Prop types for React.
import { IoMenuOutline } from "react-icons/io5"; // Npm: React icons.
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react"; // Npm: Chakra UI components.

/*
 * SIBLINGS
 */
import Content from "./Content";

/*
 * PACKAGES
 */
import Flower from "lib/Flower";

/*
 * OBJECTS
 */
const Index = ({ routes, inView }) => {
  // Const assignment.
  const history = useHistory();
  const _variantChange = "0.2s linear";
  const _sidebarRadius = "30px";
  const _sidebarMargins = "0px";
  const _locationRef = useLocation();
  const _sphereRef = React.useRef({});
  const _primaryColor = "#190793";
  const _secondaryColor = "#02044A";
  const _ternaryColor = "#190793";

  // Hook assignment.
  const _sidebarBgRef = useColorModeValue("white", "navy.800");

  React.useEffect(() => {
    // Const assignment.
    const _el = document.getElementById("SideMenuGroot");

    // Only initialize if document is not ready.
    if ("complete" === document.readyState && !_.isEmpty(_el)) {
      /*
       * If _sphere already exists.
       * then dispose it before creating new one.
       */
      if (!_.isEmpty(_sphereRef.current)) _sphereRef.current?.dispose();

      // Render sphere.
      _sphereRef.current = new Flower({
        damping: 0.0,
        container: _el,
        primaryColor: _primaryColor,
        secondaryColor: _secondaryColor,
        ternaryColor: _ternaryColor,
      });
    }
  }, [inView, _locationRef]);

  const _profile = routes.filter((perv) => perv.name !== "Profile");

  // Return component.
  return (
    <Box
      w="6%"
      display={{ sm: "none", xl: "block" }}
      position="fixed"
      minH="100%"
      fontSize={["clamp(13px, 1.5vw, 15px)"]}
    >
      <Box
        transition={_variantChange}
        ms={{ sm: "10px" }}
        my={{ sm: "10px" }}
        h="calc(100vh - 22px)"
        borderRadius="36px"
        boxShadow="0 2px 12px 0 rgb(0 0 0 / 6%)"
        align="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
        minH="100%"
      >
        <Content routes={_profile} />
        <Box
          position="absolute"
          margin="auto"
          bottom="22px"
          maxW="45px"
          minW="45px"
          w="45px"
          h="45px"
          bgColor="primary.600"
          borderRadius="100px"
          onClick={() => history.push("/profile")}
          cursor="pointer"
        ></Box>
      </Box>
    </Box>
  );
};
const SidebarResponsive = ({ routes }) => {
  // Hook assignment.
  const _menuColorRef = useColorModeValue("gray.400", "white");
  const _buttonRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Return component.
  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems="center">
      <Flex ref={_buttonRef} w="max-content" h="max-content" onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={_menuColorRef}
          my="auto"
          w="20px"
          h="20px"
          me="10px"
          m="inherit"
          _hover={{ cursor: "pointer" }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={"rtl" === document.documentElement.dir ? "right" : "left"}
        finalFocusRef={_buttonRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w="285px"
          maxW="285px"
          ms={{ sm: "16px" }}
          my={{ sm: "16px" }}
          borderRadius="16px"
        >
          <DrawerCloseButton
            zIndex="3"
            onClose={onClose}
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW="285px" px="0rem" pb="0">
            <Content routes={routes} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

/*
 * PROPTYPES
 */
Index.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};
SidebarResponsive.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
};

/*
 * EXPORTS
 */
export default Index;
export { SidebarResponsive };

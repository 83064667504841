
export const validateField = (name, value) => {
  switch (name) {
    case "firstName":
      if (!value) return "First Name is required.";
      return "";
    case "lastName":
      if (!value) return "Last Name is required.";
      return "";
    case "phone":
      if (!value) return "Phone number is required.";
      if (!/^\d{10}$/.test(value)) return "Phone number must be 10 digits.";
      return "";
    case "email":
      if (!value) return "Email is required.";
      if (!/\S+@\S+\.\S+/.test(value)) return "Email is invalid.";
      return "";
    case "companyName":
      if (!value) return "Company Name is required.";
      return "";
    case "address":
      if (!value) return "Address is required.";
      return "";
    case "country":
      if (!value) return "Country is required.";
      return "";
    case "code":
      if (!value) return "Postcode/ZIP/Pin is required.";
      return "";
    case "state":
      if (!value) return "State is required.";
      return "";
    case "city":
      if (!value) return "City is required.";
      return "";
    case "gstNumber":
      if (!value) return "GST number is required.";
      return "";
    case "registrationType":
      if (!value) return "Registration type is required.";
      return "";
    case "panNumber":
      if (!value) return "PAN number is required.";
      return "";
    case "tanNumber":
      if (!value) return "TAN number is required.";
      return "";
    case "gstTanNumber":
      if (!value) return "GST TAN number is required.";
      return "";
    default:
      return "";
  }
};

export const validateForm = (refs, conditions) => {
  const newErrors = {};
  newErrors.firstName = validateField(
    "firstName",
    refs.firstName.current.value
  );
  newErrors.lastName = validateField("lastName", refs.lastName.current.value);
  newErrors.phone = validateField("phone", refs.phone.current.value);
  newErrors.email = validateField("email", refs.email.current.value);
  newErrors.companyName = validateField(
    "companyName",
    refs.companyName.current.value
  );
  newErrors.address = validateField("address", refs.address.current.value);
  newErrors.country = validateField("country", refs.country.current.value);
  newErrors.code = validateField("code", refs.code.current.value);
  newErrors.state = validateField("state", refs.state.current.value);
  newErrors.city = validateField("city", refs.city.current.value);

  if (conditions.gstStatus) {
    newErrors.gstNumber = validateField(
      "gstNumber",
      refs.gstNumber.current.value
    );
  }

  if (conditions.taxStatus) {
    if (conditions.gstRegistered === "YES") {
      newErrors.gstNumber = validateField(
        "gstNumber",
        refs.gstNumber.current.value
      );
      newErrors.registrationType = validateField(
        "registrationType",
        refs.registrationType.current.value
      );
    }
    if (conditions.panNumber === "YES") {
      newErrors.panNumber = validateField(
        "panNumber",
        refs.panNumber.current.value
      );
    }
    if (conditions.tds === "YES") {
      newErrors.tanNumber = validateField(
        "tanNumber",
        refs.tanNumber.current.value
      );
    }
    if (conditions.govEntity === "YES") {
      newErrors.gstTanNumber = validateField(
        "gstTanNumber",
        refs.gstTanNumber.current.value
      );
    }
  }

  return newErrors;
};

/*
 * IMPORT
 */
import React from 'react' // Npm: React.
import ReactDOM from 'react-dom' // Npm: React dom.
import { Provider } from 'react-redux' // Npm: Redux provider.
import { PersistGate } from 'redux-persist/integration/react' // Npm: Redux persist gate.
import { ChakraProvider } from '@chakra-ui/react' // Npm: Chakra UI provider.
import { BrowserRouter as Router } from 'react-router-dom' // Npm: React router dom library.


/*
 * PACKAGES
 */
import theme from 'theme'
import Redux, { Persist } from "./Redux"


/*
 * SIBLINGS
 */
import App from 'index.app.js'


/*
 * RENDER
 */
ReactDOM.render(
  <Provider store={Redux}>
    <PersistGate persistor={Persist}>
      <ChakraProvider theme={theme}>
        <React.StrictMode>
          <Router>
            <App isAnimating />
          </Router>
        </React.StrictMode>
      </ChakraProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

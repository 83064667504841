/*
 * IMPORTS
 */
import { mode } from '@chakra-ui/theme-tools' // Npm: Chakra UI theme tools.


/*
 * EXPORTS
 */
export const globalStyles = {
  'colors': {
    'primary': {
      '500': '#3311DB',
      '600': '#190793',
      '700': '#02044A'
    },
    'secondary': {
      '500': '#8E2DE2',
      '600': '#8E2DE2',
      '700': '#8E2DE2'
    },
    'ternary': {
      '500': '#3311DB',
      '600': '#190793',
      '700': '#02044A'
    },
    'red': {
      '100': '#FEEFEE',
      '500': '#EE5D50',
      '600': '#E31A1A'
    },
    'blue': {
      '50': '#EFF4FB',
      '500': '#3965FF'
    },
    'purple': {
      '100': '#c1a9f4',
      '500': '#c1a9f4'
    },
    'greyish': {
      '100': '#edf2f6',
      '500': '#edf2f6'
    },
    'orange': {
      '100': '#FFF6DA',
      '500': '#FFB547'
    },
    'green': {
      '100': '#E6FAF5',
      '500': '#01B574'
    },
    'navy': {
      '50': '#d0dcfb',
      '100': '#aac0fe',
      '200': '#a3b9f8',
      '300': '#728fea',
      '400': '#3652ba',
      '500': '#1b3bbb',
      '600': '#24388a',
      '700': '#1B254B',
      '800': '#111c44',
      '900': '#0b1437'
    },
    'gray': {
      '100': '#EDF2F7',
      '200': '#EDF2F7'
    },
    'grayShade': '#A3AED0',
    'textBlue': '#2B3674'
  },
  'fontSize': {
    'flexible': 'clamp(12px, 1.5vw, 10px)'
  },
  'styles': {
    'global': props => ({
      'body': {
        'overflowX': 'hidden',
        'bg': mode('#fdfeff', 'navy.900')(props),
        'fontFamily': 'Poppins',
        'letterSpacing': '-0.5px'
      },
      'input': {
        'color': '#2B3674'
      },
      'select': {
        'color': '#2B3674'
      },
      'html': {
        'fontFamily': "'Poppins', Helvetica, Arial, sans-serif"
      }
    })
  }
}

/*
 * IMPORTS
 */
import React from "react"; // Npm: React LIBRARY.
import PropTypes from "prop-types"; // Npm: Prop types for React.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import { Flex, Stack } from "@chakra-ui/react"; // Npm: Chakra UI for design system.


/*
 * SIBLINGS
 */
import Brand from "./Brand";
import Links from "./Links";


/*
 * OBJECTS
 */
const Index = ({ routes }) => {
  // Return component.
  return (
    <Flex
      direction="column"
      minH="100%"
      height="max-content">
      <Brand />
      <Stack align="center" direction="column" mb="auto" mt="44px">
        <Links routes={routes} />
      </Stack>
    </Flex>
  );
};

/*
 * PROPTYPES
 */
Index.propTypes = {
  routes: PropTypes.array.isRequired,
  account: PropTypes.object.isRequired,
  platform: PropTypes.object.isRequired,
};

/*
 * REDUX
 */
const _MapStateToProps = (__state) => ({
  account: __state.Account,
  platform: __state.Platform,
});

/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index);

/*
 * IMPORTS
 */
  import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

  /*
   * GRAPH
   */
  const Index = gql`
  mutation MyMutation($loginRequestId: String, $repliedBy: String, $replyDescription: String, $ticketId: String) {
    TicketReplyCreate(
      input: {loginRequestId: $loginRequestId, repliedBy: $repliedBy, replyDescription: $replyDescription, ticketId: $ticketId}
    ) {
      message
      status
    }
  }
  `;
  
  /*
   * EXPORTS
   */
  export default Index;
  
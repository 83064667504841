/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($featureId: ID!, $newfeaturesId: ID!, $oldFeaturesId: ID!, $featureCostId: ID , $accountProjectId:ID!) {
    FeatureMove(
      featureId: $featureId
      newfeaturesId: $newfeaturesId
      oldFeaturesId: $oldFeaturesId
      featureCostId: $featureCostId
      accountProjectId: $accountProjectId
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

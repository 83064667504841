/*
 * IMPORTS
 */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useMutation } from "@apollo/client";
import { useDropzone } from "react-dropzone";

/*
 * CHAKRA UI IMPORTS
 */
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
  Textarea,
  Spinner,
  Input,
} from "@chakra-ui/react";

/*
 * ICONS AND IMAGES
 */
import { SlSupport } from "react-icons/sl";
import { IoTicketSharp } from "react-icons/io5";

/*
 * MUTATION
 */
import TicketCreate from "./__mutation__/index.ticketCreate.mutation";

/*
 * OBJECTS
 */
const Index = ({ account, query }) => {
  /*
   * REFS
   */
  const ticketCategoryRef = React.useRef();
  const ticketDescriptionRef = React.useRef();
  const ticketSeverityRef = React.useRef();
  const _requestId = React.useRef("ADMIN");

  const [
    MutationTicketCreate,
    { loading: LoadingTicketCreate },
  ] = useMutation(TicketCreate)

  const [selectedFile, setSelectedFile] = React.useState(null);

  /*
   * FUNCTIONS
   */
  const _handleTicketCreate = async (event) => {
    event.preventDefault();

    try {
      const response = await MutationTicketCreate({
        variables: {
          ticketCategory: ticketCategoryRef.current.value,
          ticketDescription: ticketDescriptionRef.current.value,
          ticketImage: selectedFile,
          ticketSeverity: parseInt(ticketSeverityRef.current.value),
          loginRequestId: _requestId.current
        },
      });
      if (response?.data?.TicketCreate?.status === "PUBLISHED_SUCCESSFULLY") {
        ticketCategoryRef.current.value = "";
        ticketDescriptionRef.current.value = "";
        ticketSeverityRef.current.value = "";
        setSelectedFile(null);
      }
    } catch (error) {
      console.error("Error generating ticket", error);
    }
  };

  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  return (
    <Flex
      as="form"
      w="100%"
      flexDir="column"
      borderRadius="24px"
      bg={`linear-gradient(210deg, var(--chakra-colors-primary-500) 0%, var(--chakra-colors-secondary-500) 100.1%, var(--chakra-colors-ternary-500) 200%)`}
      overflow="hidden"
      boxShadow="0 0 100px 0 rgba(0, 0, 0, 0.33)"
      onSubmit={_handleTicketCreate}
    >
      <Flex flexDir="column" p="32px" color="white">
        <Flex gap="12px" alignItems="center" mb="18px">
          <SlSupport size={21} />
          <Text fontSize="xl">Generate Ticket</Text>
        </Flex>
        <Text fontSize="md">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas in
          semper libero.
        </Text>
      </Flex>
      <Flex px="32px" pb="32px" flexDir="column" gap="22px">
        <FormControl isRequired>
          <FormLabel color="white">Severity</FormLabel>
          <Select
            ref={ticketSeverityRef}
            placeholder="Select Severity"
            fontSize={["Clamp(13px, 1.5vw, 15px)"]}
            bg="primary.700"
            color="white"
            borderRadius={18}
            name="zoneSelect"
            border="0"
            h="52px"
            boxShadow="0 0 100px 10px #3311DB"
            sx={{
              "&": {
                fontSize: "15px",
                color: "white",
              },
              "& option[value='']": { fontSize: "15px", color: "black" },
              "& :not(option[value=''])": { fontSize: "15px", color: "black" },
            }}
          >
            {[{ title: "High" , value: 1 }, { title: "Medium" , value: 2 }, { title: "Low" , value: 3 }].map((e) => (
              <option key={e.title} value={e.value}>
                <Text as="span">{e.title}</Text>
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl isRequired>
          <FormLabel color="white">Ticket Category</FormLabel>
          <Select
            ref={ticketCategoryRef}
            placeholder="Select Category"
            fontSize={["Clamp(13px, 1.5vw, 15px)"]}
            bg="primary.700"
            color="white"
            borderRadius={18}
            name="zoneSelect"
            border="0"
            h="52px"
            boxShadow="0 0 100px 10px #3311DB"
            sx={{
              "&": {
                fontSize: "15px",
                color: "white",
              },
              "& option[value='']": { fontSize: "15px", color: "black" },
              "& :not(option[value=''])": { fontSize: "15px", color: "black" },
            }}
          >
            {[
              { title: "Payment" },
              { title: "Billing" },
              { title: "Build App" },
              { title: "Others" },
            ].map((e) => (
              <option key={e.title} value={e.title}>
                <Text as="span">{e.title}</Text>
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl isRequired>
          <FormLabel color="white">Detailed Issue</FormLabel>
          <Textarea
            ref={ticketDescriptionRef}
            fontSize={["Clamp(13px, 1.5vw, 15px)"]}
            placeholder='e.g. "Payment is not processing"'
            _placeholder={{ color: "white" }}
            color="white"
            name="displayName"
            borderRadius={22}
            border="none"
            h="120px"
            resize="none"
            bg="primary.700"
            p="26px"
            type="textArea"
          />
        </FormControl>
        <FormControl>
          <FormLabel color="white">File Attachment</FormLabel>
          <Text
            fontSize="md"
            color="gray.200"
            fontWeight={400}
            letterSpacing=".4px"
          >
            You can attach a file to help us understand the issue better. Just
            drop your file here.
          </Text>
          <div
            {...getRootProps()}
            style={{
              border: "2px dashed #cccccc",
              borderRadius: "8px",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <input {...getInputProps()} />
            {selectedFile ? (
              <Text color="white">{selectedFile.name}</Text>
            ) : (
              <Text color="white">
                Drag 'n' drop some files here, or click to select files
              </Text>
            )}
          </div>
        </FormControl>
        <Button
          h="52px"
          fontSize="sm"
          mt="12px"
          w="max-content"
          ml="auto"
          mr="0"
          p="22px"
          py="24px"
          color="white"
          boxShadow="0 0 100px 10px rgba(255, 255, 255, 0.032)"
          bg="primary.500"
          gap="6px"
          _hover={{ bg: "primary.700" }}
          type="submit"
        >
          {LoadingTicketCreate ? (
            <Spinner size="sm" />
          ) : (
            <IoTicketSharp fontSize={17} />
          )}
          Submit Ticket
        </Button>
      </Flex>
    </Flex>
  );
};

/*
 * PROPTYPES
 */
Index.propTypes = {
  account: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({ account: state.Account });
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

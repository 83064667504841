/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($featureCostId: ID, $featureId: ID!, $featuresId: ID! , $accountProjectId: ID) {
    FeatureCopy(
      featureId: $featureId
      featuresId: $featuresId
      featureCostId: $featureCostId
      accountProjectId: $accountProjectId
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

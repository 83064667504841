/*
 * IMPORTS
 */
import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { formatCurrency } from "components/CurrencyFormater";

/*
 * CHAKRA UI
 */
import {
  Flex,
  Text,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  useToast,
} from "@chakra-ui/react";

function Index({ feature, index }) {


  return (
    <Flex bg="white" m="16px" borderRadius="10px">
      <Accordion p="0px" w="100%" allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton p="16px 0px 16px 0px">
              <Flex justify="space-between" p="0px 25px 0px 25px" w="100%">
                <Text
                  textAlign="left"
                  color="gray.400"
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  {`${index + 1}. ${feature?.displayName}`}
                </Text>

                <Flex gap="50px">
                  <Text color="gray.400" fontSize="sm" fontWeight="semibold">
                    {`${formatCurrency(_.sumBy(
                      feature?.Feature,
                      (feature) => feature?.FeatureCost?.cost
                    ))}`}
                  </Text>
                  <AccordionIcon />
                </Flex>
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Flex>
              <TableContainer width="100%">
                <Table variant="striped" colorScheme="gray">
                  <Tbody>
                    {feature?.Feature.map((f, i) => (
                      <Tr key={i}>
                        <Td>{f.displayName}</Td>
                        <Td w="18%">{`${formatCurrency(f?.FeatureCost?.cost)}`}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
}

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  theme: state.Theme,
  account: state.Account,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $fullName: String
    $GST: String
    $GstTan: String
    $email: String
    $PAN: String
    $TAN: String
    $address: String
    $city: String
    $country: String
    $paymentType: String
    $phone: String
    $pincode: String
    $companyname: String
    $registrationType: String
    $state: String
    $installments: [JSON]
    $totalCost: Float
    $requestId: ID!
  ) {
    BillingCreate(
      input: {
        fullName: $fullName
        GST: $GST
        GstTan: $GstTan
        email: $email
        PAN: $PAN
        TAN: $TAN
        address: $address
        city: $city
        country: $country
        paymentType: $paymentType
        phone: $phone
        pincode: $pincode
        companyname: $companyname
        registrationType: $registrationType
        state: $state
        totalCost: $totalCost
        requestId: $requestId
      }
      installments: $installments
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

/*
 * IMPORT
 */
import React from "react"; // Npm: React library.
import _ from "lodash"; // Npm: Underscore for utility.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import {  useMutation , useSubscription } from "@apollo/client";
import {formatCurrency} from "components/CurrencyFormater";
import { motion } from "framer-motion";
import { nanoid } from 'nanoid';


/*
 * CHAKRA UI
 */
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  Input,
  useToast,
} from "@chakra-ui/react"; // Npm: Chakra UI components.

/*
 * ICONS
 */
import { HiClock } from "react-icons/hi2"; // Npm: React icons.
import { HiUsers } from "react-icons/hi";
import {
  BsFillEmojiAstonishedFill,
  BsFillEmojiKissFill,
  BsFillEmojiSunglassesFill,
  BsFillEmojiSurpriseFill,
  BsFillEmojiTearFill,
} from "react-icons/bs"; // Npm: React icons.
import { TiHeartFullOutline } from "react-icons/ti"; // Npm: React icons.

/*
 * PACKAGES
 */
import CheckoutFooter from "components/CheckoutFooter";

/*
 * MUTATIONS
 */
import AdditionalCreate from "./__mutation__/index.planDelivery.mutation";

/*
 * SUBSCRIPTION
 */
import FeatureDurationRange from "./__subscription__/index.developmentSpeed.subscription";
import FeaturesPhaseDistribution from "./__subscription__/index.phaseDistribution.subscription";

/*
 * STYLES
 */
import "./index.css";
import "reactflow/dist/style.css"; // Npm: React Flow stylesheet.

/*
 * OBJECTS
 */
const Index = ({ _nextStep, updateProjectActiveIndex, project, account }) => {
  
  /*
   * STATE
   */
  const toast = useToast();

  const dataPointsUser = [
    { value: 0, users: 50, cost: 100, icon: HiUsers, color: "#832be1" },
    { value: 25, users: 150, cost: 500, icon: HiUsers, color: "#832be1" },
    { value: 50, users: 200, cost: 2000, icon: HiUsers, color: "#832be1" },
    { value: 75, users: 250, cost: 2500, icon: HiUsers, color: "#832be1" },
    { value: 100, users: 500, cost: 5000, icon: HiUsers, color: "#832be1" },
  ];

  const [
    preferredMobileAvailability,
    setPreferredMobileAvailability,
  ] = React.useState(["ANDROID"]);
  const [
    preferredDesktopAvailability,
    setPreferredDesktopAvailability,
  ] = React.useState(["WINDOWS"]);
  const [developmentSpeed, setDevelopmentSpeed] = React.useState(0);
  const [userCount, setUserCount] = React.useState(25);
  const [userCost, setUserCost] = React.useState(500);
  const [user, setUser] = React.useState(150);
  const [sliderValue, setSliderValue] = React.useState(50);
  const [selectedString, setSelectedString] = React.useState(
    "GROOT will handle it"
  );
  const [file, setFile] = React.useState(null);
  const [agree, setAgree] = React.useState(true);
  const [isCheckedProtoType, setIsCheckedProtoType] = React.useState(true);
  const [showBox, setShowBox] = React.useState(false);
  const [weeks, setWeeks] = React.useState();
  const [cost, setCost] = React.useState();
  const [forceReRender, setForceReRender] = React.useState([]);
  const _PhaseDistribution = React.useRef();
  const _FeatureDurationRange = React.useRef();

  const markers = [0, 25, 50, 75, 100];

 console.log( useSubscription(FeatureDurationRange, {
  variables: {
    featureIds: _.flatMap(
      project?.accountProjectDetails?.accountProjectFeatures,
      (featureGroup) => _.map(featureGroup.Feature, "id")
    ),
  },
  onData: ({ data, error }) => {
    // If error is caught, then report failure.
    if (error) return console.error(error);

    // Make sure data is not empty.
    if (
      _.isEmpty(data) ||
      (!_.isEmpty(data?.data) &&
        (_.isEmpty(data?.data?.FeatureDurationRange)))
    ) {
      return toast("Something is not right.");
    }

    _FeatureDurationRange.current = data?.data?.FeatureDurationRange 
    setWeeks(data?.data?.FeatureDurationRange?.actualDays / 7);
    setCost(data?.data?.FeatureDurationRange?.actualCost);
    // Force re-render
    return setForceReRender(nanoid());
  },
  onError: (e) => console.log("-==werwer", e),
}) , "FeatureDurationRange")


  const baseWeeksMin =
  _FeatureDurationRange?.current?.actualDays / 7;
  const baseWeeksMax =
  _FeatureDurationRange?.current?.minDays / 7;
  const baseCostMin =
  _FeatureDurationRange?.current?.maxCost;
  const baseCostMax =
  _FeatureDurationRange?.current?.actualCost;

  const [
    MutationAdditionalCreate,
    { loading: LoadingAdditionalCreate },
  ] = useMutation(AdditionalCreate)


  /*
   * FUNCTIONS
   */
  const handleSliderChange = (value) => {
    setSliderValue(value);
    switch (value) {
      case 0:
        setSelectedString("You Have Design");
        break;
      case 50:
        setSelectedString("GROOT will handle it");
        break;
      default:
        setSelectedString("");
    }
  };
  const handleCheckboxChange = (event) => {
    setIsCheckedProtoType(event.target.checked);
  };
  const handleFileChange = (event) => {
    // Handle the file change here
    setFile(event.target.files[0]);
  };
  const _handleChangeUser = (value) => {
    setUserCount(value);
    const dataPoint = dataPointsUser.find((point) => point.value === value);
    if (dataPoint) {
      setUserCost(dataPoint.cost);
      setUser(dataPoint.users);
    }
  };
  
  const interpolateValue = (min, max, percentage) => {
    return min + (max - min) * (percentage / 100);
  };

  const handleSliderChangeSpeed = (value) => {
    setDevelopmentSpeed(value);
    setShowBox(true);
  };

  const handleSliderChangeEnd = (value) => {
    const newWeeks = interpolateValue(baseWeeksMin, baseWeeksMax, value);
    const newCost = interpolateValue(baseCostMin, baseCostMax, value);
    setWeeks(newWeeks);
    setCost(newCost);
    setTimeout(() => setShowBox(false), 2000);
  };

  const _handlePlanDelivery = async (id) => {
    try {
      const response = await MutationAdditionalCreate({
        variables: {
          designCost: parseFloat(10000),
          designType: selectedString,
          desktopAvailability: preferredDesktopAvailability,
          developmentCost: cost,
          developmentDuration: parseInt(weeks * 7),
          mobileAvailability: preferredMobileAvailability,
          numberOfUser: parseInt(user),
          prototype: isCheckedProtoType,
          userCost: parseFloat(userCost),
          weeks: _PhaseDistribution?.current?.weeks,
          accountProjectId: project?.accountProjectDetails?.accountProjectId,
        },
      });
      if (
        !_.isEmpty(response?.data?.AdditionalCreate) &&
        "PUBLISHED_SUCCESSFULLY" ===
          response?.data?.AdditionalCreate?.status
      ) {
        updateProjectActiveIndex(3);
      }
    } catch (error) {}
  };

  function formatDateToReadable(dateStr) {
    const date = new Date(dateStr);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "short" });

    const daySuffix = (day) => {
      if (day === 1 || day === 21 || day === 31) return "st";
      if (day === 2 || day === 22) return "nd";
      if (day === 3 || day === 23) return "rd";
      return "th";
    };

    return `${day}${daySuffix(day)} ${month}`;
  }

  console.log(useSubscription(FeaturesPhaseDistribution, {
    variables: {
      featuresIds: _.map(
        project?.accountProjectDetails?.accountProjectFeatures,
        (featuresID) => featuresID.id
      ),
      featureIds: _.flatMap(
        project?.accountProjectDetails?.accountProjectFeatures,
        (featureGroup) => _.map(featureGroup.Feature, "id")
      ),
      ProjectDuration: parseInt(weeks * 7),
      accountProjectId: project?.accountProjectDetails?.accountProjectId,
    },
    onData: ({ data, error }) => {
    console.log(data , "bhanupratap")
      // If error is caught, then report failure.
      if (error) return console.error(error);
  
      // Make sure data is not empty.
      if (
        _.isEmpty(data) ||
        (!_.isEmpty(data?.data) &&
          (_.isEmpty(data?.data?.FeaturesPhaseDistribution)))
      ) {
        return toast("Something is not right.");
      }
  
      _PhaseDistribution.current = data?.data?.FeaturesPhaseDistribution;
      // Force re-render
      return setForceReRender(nanoid());
    },
    onError: (e) => console.log("-==werwer", e),
  }) , "FeaturesPhaseDistribution")

  /*
   * USE EFFECT
   */
  React.useEffect(() => {
    if (sliderValue === 0) {
      document.getElementById("designInput").click();
    }
  }, [sliderValue]);

 
  return (
    <>
      <Flex mb="32px" flexDir="column" className="planDeliveryInformation">
        <Text as="p" className="description width maxContent">
          Changing delivery details will result in updated pricing and timeline.
        </Text>
        <Flex my="42px" direction="column" gap="12px" w="100%">
          <Text fontWeight={500} mb="6px">
            Mobile Availability
          </Text>
          <Flex gap="12px" flexWrap="wrap" mb="32px">
            <CheckboxGroup
              onChange={setPreferredMobileAvailability}
              value={preferredMobileAvailability}
            >
              <Checkbox
                value="ANDROID"
                w="max-content"
                fontSize="14px"
                borderRadius="12px"
                bg="gray.200"
                colorScheme="gray"
                p="6px"
                px="12px"
                size="md"
                color="gray.500"
                defaultChecked
              >
                Android Devices
              </Checkbox>
              <Checkbox
                value="IOS"
                w="max-content"
                fontSize="14px"
                borderRadius="12px"
                bg="gray.200"
                colorScheme="gray"
                p="6px"
                px="12px"
                size="md"
                color="gray.500"
              >
                IOS Devices
              </Checkbox>
            </CheckboxGroup>
          </Flex>
          <Text fontWeight={500} mb="6px">
            Desktop Availability
          </Text>
          <Flex gap="12px" flexWrap="wrap" mb="32px">
            <CheckboxGroup
              onChange={setPreferredDesktopAvailability}
              value={preferredDesktopAvailability}
            >
              <Checkbox
                value="WEB"
                w="max-content"
                size="md"
                fontSize="14px"
                borderRadius="12px"
                bg="gray.100"
                colorScheme="gray"
                p="6px"
                px="12px"
                color="gray.500"
                defaultChecked
              >
                Web
              </Checkbox>
              <Checkbox
                value="IOS"
                w="max-content"
                size="md"
                fontSize="14px"
                borderRadius="12px"
                bg="gray.100"
                colorScheme="gray"
                p="6px"
                px="12px"
                color="gray.500"
              >
                Mac OS
              </Checkbox>
              <Checkbox
                value="WINDOWS"
                w="max-content"
                size="md"
                fontSize="14px"
                borderRadius="12px"
                bg="gray.100"
                colorScheme="gray"
                p="6px"
                px="12px"
                color="gray.500"
              >
                Windows
              </Checkbox>
              <Checkbox
                value="MOBILE"
                w="max-content"
                size="md"
                fontSize="14px"
                borderRadius="12px"
                bg="gray.100"
                colorScheme="gray"
                p="6px"
                px="12px"
                color="gray.500"
              >
                Mobile site
              </Checkbox>
            </CheckboxGroup>
          </Flex>
          <Flex flexDir="column" className="timeline" mb="42px">
            <Text fontWeight={500}>Development Speed</Text>
            <Text className="description" color="gray.500">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </Text>
            <Flex py="42px" pt={0} w="60%" gap="12px" flexWrap="wrap">
              <Text fontWeight={500} color="tomato">
                1 day adds up as 1%, Hence 1% increase in base amount.
              </Text>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: showBox ? 1 : 0 }}
                transition={{ duration: 0.5 }}
                style={{
                  position: "relative",
                  marginBottom: "24px",
                  padding: "8px",
                  background: "tomato",
                  color: "white",
                  borderRadius: "md",
                  textAlign: "center",
                  fontWeight: 600,
                  transform: `translateX(${developmentSpeed}%)`,
                  transition: "transform 0.3s ease",
                }}
              >
                {weeks?.toFixed(1)} Weeks - {formatCurrency(cost)}
              </motion.div>
              <Slider
                aria-label="slider-ex-4"
                defaultValue={0}
                value={developmentSpeed}
                onChange={handleSliderChangeSpeed}
                onChangeEnd={handleSliderChangeEnd}
                step={1}
                min={0}
                max={100}
              >
                <SliderTrack bg="gray.200">
                  <SliderFilledTrack bg="tomato" />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color="tomato" as={HiClock} />
                </SliderThumb>
                {markers.map((value) => (
                  <SliderMark
                    key={value}
                    value={value}
                    mt="1"
                    ml="-10"
                    fontSize="sm"
                  >
                    <Flex
                      flexDir="column"
                      mt={6}
                      justifyContent="center"
                      textAlign="center"
                    >
                      <Text as="p" fontWeight={500}>
                        {interpolateValue(
                          baseWeeksMin,
                          baseWeeksMax,
                          value
                        ).toFixed(1)}{" "}
                        Weeks
                      </Text>
                      <Text as="p" fontWeight={600}>
                        {formatCurrency(
                          interpolateValue(baseCostMin, baseCostMax, value)
                        )}
                      </Text>
                    </Flex>
                  </SliderMark>
                ))}
              </Slider>
            </Flex>
          </Flex>
          <Flex flexDir="column" mb="62px">
            <Text fontWeight={500}>UI Development</Text>
            <Text className="description" color="gray.500">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.{" "}
            </Text>
            <Flex py="42px" pt={0} w="60%" gap="12px" flexWrap="wrap">
              <Slider
                aria-label="slider-ex-4"
                value={sliderValue}
                onChange={handleSliderChange}
                step={50}
                min={0}
                max={50}
              >
                <SliderTrack bg="gray.200">
                  <SliderFilledTrack bg="primary.500" />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color="primary.500" as={HiClock} />
                </SliderThumb>
                <SliderMark value={0} mt="1" ml="-10" fontSize="sm">
                  <Flex
                    flexDir="column"
                    mt={6}
                    justifyContent="center"
                    textAlign="center"
                  >
                    <Flex
                      flexDir="row"
                      gap="6px"
                      alignItems="center"
                      color="primary.500"
                    >
                      <BsFillEmojiSurpriseFill />
                      <Text as="p" fontWeight={500}>
                        You Have Design
                      </Text>
                    </Flex>
                    <Text as="p" fontWeight={600}>
                      {formatCurrency(5000)}
                    </Text>
                    {sliderValue === 0 && (
                      <Flex justifyContent="center">
                        <Input
                          type="file"
                          id="designInput"
                          name="contentVideoLink"
                          onChange={handleFileChange}
                          display="none"
                        />
                      </Flex>
                    )}
                  </Flex>
                </SliderMark>
                <SliderMark value={50} mt="1" ml="-20" fontSize="sm">
                  <Flex
                    flexDir="column"
                    mt={6}
                    justifyContent="center"
                    textAlign="center"
                    w="160px"
                  >
                    <Flex gap="6px" alignItems="center" color="secondary.500">
                      <BsFillEmojiSunglassesFill />
                      <Text as="p" fontWeight={500}>
                        GROOT will handle it
                      </Text>
                    </Flex>
                    <Text as="p" fontWeight={600}>
                      INCLUDED
                    </Text>
                  </Flex>
                </SliderMark>
              </Slider>
            </Flex>
          </Flex>
          <Flex flexDir="column" className="prototype" mb="62px">
            <Flex
              p="22px"
              w="60%"
              gap="12px"
              flexWrap="wrap"
              alignItems="start"
              bg="linear-gradient(90deg, var(--chakra-colors-primary-500) 0%, var(--chakra-colors-secondary-500) 65.1%, var(--chakra-colors-ternary-500) 100%);"
              boxShadow="0 0 100px 0 var(--chakra-colors-ternary-500)"
              borderRadius={18}
            >
              <Checkbox
                w="max-content"
                size="md"
                fontSize="14px"
                borderRadius="12px"
                colorScheme="white"
                color="white.500"
                isChecked={isCheckedProtoType}
                onChange={handleCheckboxChange}
              ></Checkbox>
              <Flex
                flexDir="column"
                alignItems="start"
                justifyContent="start"
                position="relative"
                top={-2}
              >
                <Text fontWeight={500} color="white" fontSize={21}>
                  Clickable Prototype
                </Text>
                <Text fontWeight={500} color="white">
                  Get a visual representation of your app to test with users.
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDir="column" mb="62px" position="relative">
            <Text fontWeight={500}>Phases Distribution</Text>
            <Text className="description" color="gray.500">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.{" "}
            </Text>
            <Flex pt="102px" pb="42px" w="60%" gap="12px" position="relative">
              <Flex
                w="100%"
                borderRadius="5px"
                position="relative"
                h="5px"
                bg="primary.500"
              >
                {_PhaseDistribution?.current?.weeks?.map((item, index) => {
                  const positionPercentage =
                    (index / (_PhaseDistribution?.current?.weeks?.length - 1)) * 100;
                  return (
                    <Flex
                      key={index}
                      position="absolute"
                      flexDir="column"
                      left={`${positionPercentage}%`}
                      transform={`translateX(-50%) rotate(-45deg)`}
                      {...(index % 2 === 0
                        ? { bottom: "100%", mb: 7 }
                        : { top: "100%", mt: 7 })}
                      justifyContent="center"
                      textAlign="center"
                      w="max-content"
                    >
                      <Flex
                        flexDir="row"
                        gap="6px"
                        alignItems="center"
                        color="primary.500"
                      >
                        <BsFillEmojiSurpriseFill />
                        <Text as="p" fontWeight={500}>
                          {`${formatDateToReadable(
                            item?.startDate
                          )} - ${formatDateToReadable(item?.endDate)}`}
                        </Text>
                      </Flex>
                      <Text as="p" fontWeight={600}>
                        {`${item?.Feature.length} Feature`}
                      </Text>
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDir="column" className="timeline" mb="42px">
            <Text fontWeight={500}>Number of users</Text>
            <Text className="description" color="gray.500">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.{" "}
            </Text>
            <Flex py="42px" pt={0} w="60%" gap="12px" flexWrap="wrap">
              <Slider
                aria-label="slider-ex-4"
                defaultValue={0}
                value={userCount}
                onChange={_handleChangeUser}
                step={25}
                min={0}
                max={100}
              >
                <SliderTrack bg="gray.200">
                  <SliderFilledTrack bg="#832be1" />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color="#832be1" as={HiUsers} />
                </SliderThumb>
                {dataPointsUser.map(
                  ({ value, users, cost, icon: Icon, color }) => (
                    <SliderMark
                      key={value}
                      value={value}
                      mt="1"
                      ml="-10"
                      fontSize="sm"
                    >
                      <Flex
                        flexDir="column"
                        mt={6}
                        justifyContent="center"
                        textAlign="center"
                      >
                        <Flex
                          flexDir="row"
                          gap="6px"
                          alignItems="center"
                          color={color}
                        >
                          <Icon />
                          <Text as="p" fontWeight={500}>
                            {users} Users
                          </Text>
                        </Flex>
                        <Text as="p" fontWeight={600}>
                          {formatCurrency(cost)}
                        </Text>
                      </Flex>
                    </SliderMark>
                  )
                )}
              </Slider>
            </Flex>
          </Flex>
          <Flex flexDir="column">
            <p className="subHeading followedLine width maxContent theme danger">
              IMPORTANT*
            </p>
            <p className="description">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.{" "}
            </p>
            <Flex flexDir="column">
              <Checkbox
                w="max-content"
                size="md"
                fontSize="14px"
                borderRadius="12px"
                colorScheme="gray"
                color="gray.500"
                defaultChecked
                isChecked={agree}
                onChange={() => setAgree(!agree)}
              >
                Yes, I understand the impact of changing delivery details.
              </Checkbox>
            </Flex>
          </Flex>
        </Flex>
        <Flex position="fixed" w="100%" left={0} bottom={0} zIndex={100}>
          <CheckoutFooter
            heading="OYO, Mobile App"
            description="Continue towards payment details for your idea by moving on to the next step"
            _function={_handlePlanDelivery}
            _loading={LoadingAdditionalCreate}
            _nextStep={_nextStep}
            _agree={agree}
          />
        </Flex>
      </Flex>
    </>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  account: state.Account,
  project: state.Project,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
  updateProjectActiveIndex: (activeIndex) =>
  __dispatch({ type: "PROJECT_ACTIVE_INDEX_UPDATE", activeIndex }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORT
 */
import React from "react"; // Npm: React library.
import _ from "lodash"; // Npm: Underscore for utility.
import { connect } from "react-redux";
import jsPDF from "jspdf";
import { useMutation, useSubscription } from "@apollo/client";
import { Country, State, City } from "country-state-city";
import { formatCurrency } from "components/CurrencyFormater";
import { nanoid } from "nanoid";

/*
 * CHAKRA UI
 */
import {
  Checkbox,
  Flex,
  Input,
  Stack,
  Text,
  RadioGroup,
  Radio,
  Select,
  Heading,
  Box,
  useDisclosure,
  FormControl,
  FormLabel,
} from "@chakra-ui/react"; // Npm: Chakra UI component library.

/*
 * ASSETS
 */

/*
 * PACKAGES
 */
import CheckoutFooter from "components/CheckoutFooter";
import InstallmentPlanModal from "components/InstallmentPlanModal";
import { validateField, validateForm } from "./index.form.validate";

/*
 * MUTATIONS
 */
import BillingCreate from "./__mutation__/index.billingCreate.mutation";
import AccountProjectUpdate from "./__mutation__/index.accountProjectUpdate.mutation";

/*
 * SUBSCRIPTION
 */
import AdditionalRead from "./__subscription__/index.userInfoRead.subscription";

/*
 * STYLES
 */
import "./index.css";

/*
 * OBJECTS
 */
const Index = ({ project, _nextStep, updateProjectActiveIndex }) => {
  /*
   * STATE
   */
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstNameRef = React.useRef(null);
  const lastNameRef = React.useRef(null);
  const phoneRef = React.useRef(null);
  const emailRef = React.useRef(null);
  const companyNameRef = React.useRef(null);
  const addressRef = React.useRef(null);
  const countryRef = React.useRef(null);
  const codeRef = React.useRef(null);
  const stateRef = React.useRef(null);
  const cityRef = React.useRef(null);
  const gstNumberRef = React.useRef(null);
  const registrationTypeRef = React.useRef(null);
  const panNumberRef = React.useRef(null);
  const tanNumberRef = React.useRef(null);
  const gstTanNumberRef = React.useRef(null);
  const [gstStatus, setGstStatus] = React.useState(false);
  const [taxStatus, setTaxStatus] = React.useState(false);
  const [gstRegistered, setGstRegistered] = React.useState("NO");
  const [panNumber, setPanNumber] = React.useState("NO");
  const [tds, setTds] = React.useState("NO");
  const [govEntity, setGovEntity] = React.useState("NO");

  const [countries, setCountries] = React.useState([]);
  const [states, setStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);

  const [paymentPlan, setPaymentPlan] = React.useState("UPFRONT");
  const [billingCycle, setBillingCycle] = React.useState("MONTHLY");
  const [agreeTerms, setAgreeTerms] = React.useState(true);
  const _AdditionalRead = React.useRef([]);
  const [forceReRender, setForceReRender] = React.useState([]);
  const _requestId = React.useRef("BHANU");

  const [errors, setErrors] = React.useState({});

  const [
    MutationBillingCreate,
    { loading: LoadingBillingCreate },
  ] = useMutation(BillingCreate);

  const [
    MutationAccountProjectUpdate,
    { loading: LoadingAccountProjectUpdate },
  ] = useMutation(AccountProjectUpdate);

  console.log(
    useSubscription(AdditionalRead, {
      variables: {
        accountProjectId: project?.accountProjectDetails?.accountProjectId,
        installmentType: paymentPlan === "EMI" ? billingCycle : paymentPlan,
      },
      onData: ({ data, error }) => {
        // If error is caught, then report failure.
        if (error) return console.error(error);

        // Make sure data is not empty.
        if (
          _.isEmpty(data) ||
          (!_.isEmpty(data?.data) &&
            (_.isEmpty(data?.data?.AdditionalRead) ||
              !_.isArray(data?.data?.AdditionalRead)))
        ) {
          return toast("Something is not right.");
        }

        _AdditionalRead.current = data?.data?.AdditionalRead?.[0];
        // Force re-render
        return setForceReRender(nanoid());
      },
      onError: (e) => console.log("-==werwer", e),
    }),
    "AdditionalRead"
  );

  /*
   * FUNCTION
   */
  const _handlePaymentDetails = async () => {
    const refs = {
      firstName: firstNameRef,
      lastName: lastNameRef,
      phone: phoneRef,
      email: emailRef,
      companyName: companyNameRef,
      address: addressRef,
      country: countryRef,
      code: codeRef,
      state: stateRef,
      city: cityRef,
      gstNumber: gstNumberRef,
      registrationType: registrationTypeRef,
      panNumber: panNumberRef,
      tanNumber: tanNumberRef,
      gstTanNumber: gstTanNumberRef,
    };

    const conditions = {
      gstStatus,
      taxStatus,
      gstRegistered,
      panNumber,
      tds,
      govEntity,
    };

    const newErrors = validateForm(refs, conditions);
    setErrors(newErrors);

    // If there are validation errors, stop the process
    if (!Object.values(newErrors).every((error) => !error)) return;

    try {
      // First Mutation: BillingCreate
      const billingResponse = await MutationBillingCreate({
        variables: {
          fullName: `${firstNameRef.current.value} ${lastNameRef.current.value}`,
          GST: gstNumberRef.current ? gstNumberRef?.current?.value : "",
          GstTan: gstTanNumberRef.current
            ? gstTanNumberRef?.current?.value
            : "",
          PAN: panNumberRef.current ? panNumberRef?.current?.value : "",
          email: emailRef.current.value,
          TAN: tanNumberRef.current ? tanNumberRef?.current?.value : "",
          address: addressRef.current.value,
          city: cityRef.current.value,
          country: countryRef.current.value,
          paymentType: paymentPlan,
          phone: phoneRef.current.value,
          pincode: codeRef.current.value,
          companyname: companyNameRef.current.value,
          registrationType: registrationTypeRef.current
            ? registrationTypeRef?.current?.value
            : "",
          state: stateRef.current.value,
          installments: _AdditionalRead?.current?.installments,
          totalCost: _AdditionalRead?.current?.incrementedCost,
          requestId: _requestId.current,
        },
      });

      // Check if the first mutation was successful
      if (
        !_.isEmpty(billingResponse?.data?.BillingCreate) &&
        "PUBLISHED_SUCCESSFULLY" ===
          billingResponse?.data?.BillingCreate?.status
      ) {
        // Second Mutation: AccountProjectUpdate

        const accountProjectResponse = await MutationAccountProjectUpdate({
          variables: {
            accountProjectId: project?.accountProjectDetails?.accountProjectId,
            tracker: "BILLING",
          },
        });

        if (
          !_.isEmpty(accountProjectResponse?.data?.AccountProjectUpdate) &&
          "PUBLISHED_SUCCESSFULLY" ===
            accountProjectResponse?.data?.AccountProjectUpdate?.status
        ) {
          updateProjectActiveIndex(4);
        }
      }
    } catch (error) {
      console.error(
        "Error processing payment details or updating project",
        error
      );
    }
  };

  const downloadInvoice = () => {
    const doc = new jsPDF();
    doc.text("Invoice", 10, 10);
    doc.text("Upfront offer", 10, 20);
    doc.text(
      "Pay upfront and get 12% discount on your total Buildcard",
      10,
      30
    );
    doc.text("Amount: ₹38,61,936.52", 10, 40);
    doc.save("invoice.pdf");
  };

  const handleCountryChange = (e) => {
    const countryCode = e.target.value;
    countryRef.current.value = countryCode;
    setStates(State.getStatesOfCountry(countryCode));
    setCities([]);
    stateRef.current.value = "";
    cityRef.current.value = "";
  };

  const handleStateChange = (e) => {
    const stateCode = e.target.value;
    const countryCode = countryRef.current.value;
    stateRef.current.value = stateCode;
    setCities(City.getCitiesOfState(countryCode, stateCode));
    cityRef.current.value = "";
  };

  /*
   * USE EFFECT
   */
  React.useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  return (
    <Flex className="studioAppInformation">
      <Flex direction="column" gap="30px" w="50%" p="0px 0px 40px 0px">
        <h3 className="heading font weight200 gradient">Billing Details</h3>
        <Flex gap="10px" align="center">
          <Flex
            w="100%"
            fontWeight="400"
            fontSize="15px"
            lineHeight="17px"
            letterSpacing="1%"
            direction="column"
            gap="16px"
          >
            <FormControl isRequired isInvalid={errors.firstName}>
              <FormLabel fontSize="15px" lineHeight="14.52px" fontWeight={500}>
                First Name.
              </FormLabel>
              <Input
                className="styled-input"
                placeholder='e.g. "Bhanu"'
                _placeholder={{ color: "white" }}
                ref={firstNameRef}
                onChange={(e) => {
                  firstNameRef.current.value = e.target.value;
                  setErrors({
                    ...errors,
                    firstName: validateField("firstName", e.target.value),
                  });
                }}
                type="text"
              />
              {errors.firstName && (
                <Text color="red.500">{errors.firstName}</Text>
              )}
            </FormControl>
          </Flex>

          <Flex
            w="100%"
            fontWeight="400"
            fontSize="15px"
            lineHeight="17px"
            letterSpacing="1%"
            direction="column"
            gap="16px"
          >
            <FormControl isRequired isInvalid={errors.lastName}>
              <FormLabel fontSize="15px" lineHeight="14.52px" fontWeight={500}>
                Last Name.
              </FormLabel>
              <Input
                className="styled-input"
                placeholder='e.g. "Singh"'
                _placeholder={{ color: "white" }}
                ref={lastNameRef}
                onChange={(e) => {
                  lastNameRef.current.value = e.target.value;
                  setErrors({
                    ...errors,
                    lastName: validateField("lastName", e.target.value),
                  });
                }}
                type="text"
              />
              {errors.lastName && (
                <Text color="red.500">{errors.lastName}</Text>
              )}
            </FormControl>
          </Flex>
        </Flex>
        <Flex gap="10px" align="center">
          <Flex
            w="100%"
            fontWeight="400"
            fontSize="15px"
            lineHeight="17px"
            letterSpacing="1%"
            direction="column"
            gap="16px"
          >
            <FormControl isRequired isInvalid={errors.phone}>
              <FormLabel fontSize="15px" lineHeight="14.52px" fontWeight={500}>
                Phone.
              </FormLabel>
              <Input
                className="styled-input"
                placeholder="Contact Number"
                _placeholder={{ color: "white" }}
                ref={phoneRef}
                onChange={(e) => {
                  phoneRef.current.value = e.target.value;
                  setErrors({
                    ...errors,
                    phone: validateField("phone", e.target.value),
                  });
                }}
                type="number"
              />
              {errors.phone && <Text color="red.500">{errors.phone}</Text>}
            </FormControl>
          </Flex>

          <Flex
            w="100%"
            fontWeight="400"
            fontSize="15px"
            lineHeight="17px"
            letterSpacing="1%"
            direction="column"
            gap="16px"
          >
            <FormControl isRequired isInvalid={errors.email}>
              <FormLabel fontSize="15px" lineHeight="14.52px" fontWeight={500}>
                Email.
              </FormLabel>
              <Input
                className="styled-input"
                placeholder="Email"
                _placeholder={{ color: "white" }}
                ref={emailRef}
                onChange={(e) => {
                  emailRef.current.value = e.target.value;
                  setErrors({
                    ...errors,
                    email: validateField("email", e.target.value),
                  });
                }}
                type="email"
              />
              {errors.email && <Text color="red.500">{errors.email}</Text>}
            </FormControl>
          </Flex>
        </Flex>
        <Flex
          w="100%"
          fontWeight="400"
          fontSize="15px"
          lineHeight="17px"
          letterSpacing="1%"
          direction="column"
          gap="16px"
        >
          <FormControl isRequired isInvalid={errors.companyName}>
            <FormLabel fontSize="15px" lineHeight="14.52px" fontWeight={500}>
              Company Name.
            </FormLabel>
            <Input
              className="styled-input"
              placeholder="Company Name"
              _placeholder={{ color: "white" }}
              ref={companyNameRef}
              onChange={(e) => {
                companyNameRef.current.value = e.target.value;
                setErrors({
                  ...errors,
                  companyName: validateField("companyName", e.target.value),
                });
              }}
              type="text"
            />
            {errors.companyName && (
              <Text color="red.500">{errors.companyName}</Text>
            )}
          </FormControl>
        </Flex>
        <Flex
          w="100%"
          fontWeight="400"
          fontSize="15px"
          lineHeight="17px"
          letterSpacing="1%"
          direction="column"
          gap="16px"
        >
          <FormControl isRequired isInvalid={errors.address}>
            <FormLabel fontSize="15px" lineHeight="14.52px" fontWeight={500}>
              Address.
            </FormLabel>
            <Input
              className="styled-input"
              placeholder="e.g. Street Number 7"
              _placeholder={{ color: "white" }}
              ref={addressRef}
              onChange={(e) => {
                addressRef.current.value = e.target.value;
                setErrors({
                  ...errors,
                  address: validateField("address", e.target.value),
                });
              }}
              type="text"
            />
            {errors.address && <Text color="red.500">{errors.address}</Text>}
          </FormControl>
        </Flex>
        <Flex gap="10px" align="center">
          <Flex
            w="100%"
            fontWeight="400"
            fontSize="15px"
            lineHeight="17px"
            letterSpacing="1%"
            direction="column"
            gap="16px"
          >
            <FormControl isRequired isInvalid={errors.country}>
              <FormLabel fontSize="15px" lineHeight="14.52px" fontWeight={500}>
                Country.
              </FormLabel>
              <Select
                className="styled-select"
                placeholder="Select Country"
                style={{ color: "white" }}
                ref={countryRef}
                onChange={(e) => {
                  handleCountryChange(e);
                  setErrors({
                    ...errors,
                    country: validateField("country", e.target.value),
                  });
                }}
              >
                {countries.map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </Select>
              {errors.country && <Text color="red.500">{errors.country}</Text>}
            </FormControl>
          </Flex>

          <Flex
            w="100%"
            fontWeight="400"
            fontSize="15px"
            lineHeight="17px"
            letterSpacing="1%"
            direction="column"
            gap="16px"
          >
            <FormControl isRequired isInvalid={errors.code}>
              <FormLabel fontSize="15px" lineHeight="14.52px" fontWeight={500}>
                Postcode/ZIP/Pin
              </FormLabel>
              <Input
                className="styled-input"
                placeholder='e.g. "305901"'
                _placeholder={{ color: "white" }}
                ref={codeRef}
                onChange={(e) => {
                  codeRef.current.value = e.target.value;
                  setErrors({
                    ...errors,
                    code: validateField("code", e.target.value),
                  });
                }}
                type="number"
              />
              {errors.code && <Text color="red.500">{errors.code}</Text>}
            </FormControl>
          </Flex>
        </Flex>

        <Flex gap="10px" align="center">
          <Flex
            w="100%"
            fontWeight="400"
            fontSize="15px"
            lineHeight="17px"
            letterSpacing="1%"
            direction="column"
            gap="16px"
          >
            <FormControl isRequired isInvalid={errors.state}>
              <FormLabel fontSize="15px" lineHeight="14.52px" fontWeight={500}>
                State.
              </FormLabel>
              <Select
                className="styled-select"
                placeholder="Select State"
                style={{ color: "white" }}
                ref={stateRef}
                onChange={(e) => {
                  handleStateChange(e);
                  setErrors({
                    ...errors,
                    state: validateField("state", e.target.value),
                  });
                }}
                disabled={states.length === 0}
              >
                {states.map((state) => (
                  <option key={state.isoCode} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </Select>
              {errors.state && <Text color="red.500">{errors.state}</Text>}
            </FormControl>
          </Flex>

          <Flex
            w="100%"
            fontWeight="400"
            fontSize="15px"
            lineHeight="17px"
            letterSpacing="1%"
            direction="column"
            gap="16px"
          >
            <FormControl isRequired isInvalid={errors.city}>
              <FormLabel fontSize="15px" lineHeight="14.52px" fontWeight={500}>
                City.
              </FormLabel>
              <Select
                className="styled-select"
                placeholder="Select City"
                style={{ color: "white" }}
                ref={cityRef}
                onChange={(e) => {
                  cityRef.current.value = e.target.value;
                  setErrors({
                    ...errors,
                    city: validateField("city", e.target.value),
                  });
                }}
                disabled={cities.length === 0}
              >
                {cities.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </Select>
              {errors.city && <Text color="red.500">{errors.city}</Text>}
            </FormControl>
          </Flex>
        </Flex>
        <Checkbox
          w="max-content"
          size="md"
          fontSize="14px"
          borderRadius="12px"
          colorScheme="gray"
          color="gray.500"
          defaultChecked
          isChecked={gstStatus}
          onChange={(e) => setGstStatus(e.target.checked)}
        >
          Use GSTIN (optional).
        </Checkbox>
        {gstStatus && (
          <Flex
            w="100%"
            fontWeight="400"
            fontSize="15px"
            lineHeight="17px"
            letterSpacing="1%"
            direction="column"
            gap="16px"
          >
            <FormControl isRequired isInvalid={errors.gstNumber}>
              <FormLabel fontSize="15px" lineHeight="14.52px" fontWeight={500}>
                GST number.
              </FormLabel>

              <Input
                className="styled-input"
                placeholder='e.g. "2343wedsXXXXX"'
                _placeholder={{ color: "white" }}
                ref={gstNumberRef}
                onChange={(e) => {
                  gstNumberRef.current.value = e.target.value;
                  setErrors({
                    ...errors,
                    gstNumber: validateField("gstNumber", e.target.value),
                  });
                }}
                type="text"
              />
              {errors.gstNumber && (
                <Text color="red.500">{errors.gstNumber}</Text>
              )}
            </FormControl>
          </Flex>
        )}
        <Checkbox
          w="max-content"
          size="md"
          fontSize="14px"
          borderRadius="12px"
          colorScheme="gray"
          color="gray.500"
          defaultChecked
          isChecked={taxStatus}
          onChange={(e) => setTaxStatus(e.target.checked)}
        >
          Tax Details.
        </Checkbox>
        {taxStatus && (
          <Flex direction="column" gap="16px">
            <Text fontSize="15px" lineHeight="17px" fontWeight="500">
              Are you registered under GST?
              <Text as="span" color="red.500">
                {" "}
                *
              </Text>
            </Text>
            <RadioGroup onChange={setGstRegistered} value={gstRegistered}>
              <Stack direction="row">
                <Radio value="YES">Yes</Radio>
                <Radio value="NO">No</Radio>
              </Stack>
            </RadioGroup>

            {gstRegistered === "YES" && (
              <>
                <FormControl isRequired isInvalid={errors.gstNumber}>
                  <FormLabel
                    fontSize="15px"
                    lineHeight="14.52px"
                    fontWeight={500}
                  >
                    What is your GST Number?
                  </FormLabel>
                  <Input
                    className="styled-input"
                    placeholder='e.g. "06ABCDE12XXXX"'
                    _placeholder={{ color: "white" }}
                    ref={gstNumberRef}
                    onChange={(e) => {
                      gstNumberRef.current.value = e.target.value;
                      setErrors({
                        ...errors,
                        gstNumber: validateField("gstNumber", e.target.value),
                      });
                    }}
                    type="text"
                  />
                  {errors.gstNumber && (
                    <Text color="red.500">{errors.gstNumber}</Text>
                  )}
                </FormControl>

                <FormControl isRequired isInvalid={errors.registrationType}>
                  <FormLabel
                    fontSize="15px"
                    lineHeight="14.52px"
                    fontWeight={500}
                  >
                    What is your registration type?
                  </FormLabel>
                  <Select
                    className="styled-select"
                    placeholder='e.g. "Regular"'
                    style={{ color: "white" }}
                    _placeholder={{ color: "white" }}
                    ref={registrationTypeRef}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        registrationType: validateField(
                          "registrationType",
                          e.target.value
                        ),
                      });
                    }}
                  >
                    <option value="regular">Regular</option>
                    <option value="composition">Composite Dealer</option>
                    <option value="composition">Overseas</option>
                  </Select>
                  {errors.registrationType && (
                    <Text color="red.500">{errors.registrationType}</Text>
                  )}
                </FormControl>
              </>
            )}

            <Text fontSize="15px" lineHeight="17px" fontWeight="500">
              Do you have a PAN number?
              <Text as="span" color="red.500">
                {" "}
                *
              </Text>
            </Text>
            <RadioGroup onChange={setPanNumber} value={panNumber}>
              <Stack direction="row">
                <Radio value="YES">Yes</Radio>
                <Radio value="NO">No</Radio>
              </Stack>
            </RadioGroup>

            {panNumber === "YES" && (
              <>
                <FormControl isRequired isInvalid={errors.panNumber}>
                  <FormLabel
                    fontSize="15px"
                    lineHeight="14.52px"
                    fontWeight={500}
                  >
                    What is your PAN number?
                  </FormLabel>
                  <Input
                    className="styled-input"
                    placeholder='e.g. "06ABCDE12XXXX"'
                    _placeholder={{ color: "white" }}
                    ref={panNumberRef}
                    onChange={(e) => {
                      panNumberRef.current.value = e.target.value;
                      setErrors({
                        ...errors,
                        panNumber: validateField("panNumber", e.target.value),
                      });
                    }}
                    type="text"
                  />
                  {errors.panNumber && (
                    <Text color="red.500">{errors.panNumber}</Text>
                  )}
                </FormControl>
              </>
            )}

            <Text fontSize="15px" lineHeight="17px" fontWeight="500">
              Will you be deducting TDS?
              <Text as="span" color="red.500">
                {" "}
                *
              </Text>
            </Text>
            <RadioGroup onChange={setTds} value={tds}>
              <Stack direction="row">
                <Radio value="YES">Yes</Radio>
                <Radio value="NO">No</Radio>
              </Stack>
            </RadioGroup>

            {tds === "YES" && (
              <>
                <FormControl isRequired isInvalid={errors.tanNumber}>
                  <FormLabel
                    fontSize="15px"
                    lineHeight="14.52px"
                    fontWeight={500}
                  >
                    What is your TAN number?
                  </FormLabel>
                  <Input
                    className="styled-input"
                    placeholder='e.g. "06ABCDE12XXXX"'
                    _placeholder={{ color: "white" }}
                    ref={tanNumberRef}
                    onChange={(e) => {
                      tanNumberRef.current.value = e.target.value;
                      setErrors({
                        ...errors,
                        tanNumber: validateField("tanNumber", e.target.value),
                      });
                    }}
                    type="text"
                  />
                  {errors.tanNumber && (
                    <Text color="red.500">{errors.tanNumber}</Text>
                  )}
                </FormControl>
              </>
            )}

            <Text fontSize="15px" lineHeight="17px" fontWeight="500">
              Are you a Gov entity seeking to deduct TDS under GST?
              <Text as="span" color="red.500">
                {" "}
                *
              </Text>
            </Text>
            <RadioGroup onChange={setGovEntity} value={govEntity}>
              <Stack direction="row">
                <Radio value="YES">Yes</Radio>
                <Radio value="NO">No</Radio>
              </Stack>
            </RadioGroup>

            {govEntity === "YES" && (
              <>
                <FormControl isRequired isInvalid={errors.gstTanNumber}>
                  <FormLabel
                    fontSize="15px"
                    lineHeight="14.52px"
                    fontWeight={500}
                  >
                    What is your GST TAN?
                  </FormLabel>
                  <Input
                    className="styled-input"
                    placeholder='e.g. "06ABCDE12XXXX"'
                    _placeholder={{ color: "white" }}
                    ref={gstTanNumberRef}
                    onChange={(e) => {
                      gstTanNumberRef.current.value = e.target.value;
                      setErrors({
                        ...errors,
                        gstTanNumber: validateField(
                          "gstTanNumber",
                          e.target.value
                        ),
                      });
                    }}
                    type="text"
                  />
                  {errors.gstTanNumber && (
                    <Text color="red.500">{errors.gstTanNumber}</Text>
                  )}
                </FormControl>
              </>
            )}
          </Flex>
        )}
        <Text>
          <span style={{ fontWeight: "700" }}>
            Please note : It's your responsibility to provide the correct tax
            information (above).
          </span>{" "}
          Groot.ai isn't liable for any issues with the tax benefits, if you
          don't. Also any tax benefits apply to future instalments and won't be
          adjusted for past instalments.
        </Text>
        <h3 className="heading font weight200 gradient">Payment plan</h3>
        <Text fontSize="20px" fontWeight="500">
          Choose your preferred payment plan?
        </Text>
        <RadioGroup onChange={setPaymentPlan} value={paymentPlan}>
          <Stack direction="column" spacing={5}>
            <Box
              p={5}
              shadow="lg"
              borderWidth="1px"
              borderRadius="md"
              bg="#f5f5f5"
            >
              <Radio value="UPFRONT">
                <Text fontSize="lg" fontWeight="bold">
                  Upfront payment
                </Text>
              </Radio>
              {paymentPlan === "UPFRONT" && (
                <>
                  <Box bg="primary.500" p={5} mt={4} borderRadius="md">
                    <Heading as="h4" size="lg" color="white" mb={2}>
                      Upfront offer
                    </Heading>
                    <Text color="white" fontSize="md">
                      Pay upfront and get 12% discount on your total Buildcard
                    </Text>
                    <Text fontSize="2xl" fontWeight="bold" color="white" mt={2}>
                      {formatCurrency(
                        _AdditionalRead?.current?.incrementedCost
                      )}
                    </Text>
                  </Box>
                  <Text
                    cursor="pointer"
                    color="teal.500"
                    mt={2}
                    onClick={downloadInvoice}
                  >
                    Download invoice
                  </Text>
                </>
              )}
            </Box>

            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              borderRadius="md"
              bg="#f5f5f5"
            >
              <Radio value="EMI">
                <Text fontSize="lg" fontWeight="bold">
                  You don't need to pay it all now
                </Text>
              </Radio>
              {paymentPlan === "EMI" && (
                <>
                  <Text mt={4}>
                    Choose from our convenient monthly or weekly payment plan
                  </Text>
                  <RadioGroup
                    onChange={setBillingCycle}
                    value={billingCycle}
                    mt={4}
                  >
                    <Stack direction="row" spacing={5}>
                      <Radio value="WEEKLY">
                        <Text fontSize="lg" fontWeight="bold">
                          Weekly
                        </Text>
                      </Radio>
                      <Radio value="MONTHLY">
                        <Text fontSize="lg" fontWeight="bold">
                          Monthly
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  {billingCycle && (
                    <Box mt={4}>
                      <Heading as="h4" size="md" mt={4}>
                        Installment Details
                      </Heading>
                      <Text>Payment amount adjusts with project stage.</Text>
                      <Text>
                        Development:{" "}
                        {`${
                          _AdditionalRead?.current?.installments?.[0]?.dueDate
                        } - ${
                          _AdditionalRead?.current?.installments?.[
                            _AdditionalRead?.current?.installments?.length - 1
                          ]?.dueDate
                        }`}
                      </Text>
                      <Text cursor="pointer" color="teal.500" onClick={onOpen}>
                        See installment plan
                      </Text>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Stack>
        </RadioGroup>

        <Checkbox
          w="max-content"
          size="md"
          fontSize="14px"
          borderRadius="12px"
          colorScheme="gray"
          color="gray.500"
          defaultChecked
          isChecked={agreeTerms}
          onChange={(e) => setAgreeTerms(e.target.checked)}
        >
          Check this box if you’ve read and agree to our Terms & Conditions .
        </Checkbox>
      </Flex>
      <Flex position="fixed" w="100%" left={0} bottom={0} zIndex={100}>
        <CheckoutFooter
          heading="OYO, Mobile App"
          description="Continue to refine your idea by moving on to the next step"
          _nextStep={_nextStep}
          _function={_handlePaymentDetails}
          _loading={LoadingBillingCreate}
        />
      </Flex>

      <InstallmentPlanModal
        isOpen={isOpen}
        onClose={onClose}
        installments={_AdditionalRead?.current?.installments}
        billingCycle={billingCycle}
      />
    </Flex>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  account: state.Account,
  project: state.Project,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
  updateProjectActiveIndex: (activeIndex) =>
    __dispatch({ type: "PROJECT_ACTIVE_INDEX_UPDATE", activeIndex }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation($googleToken: String!) {
    AccountLoginWithGoogle(googleToken: $googleToken) {
      email
      displayName
      token
      status
      message
    }
  }
`

/*
 * EXPORTS
 */
export default Index

/*
 * IMPORTS
 */
import React from 'react' // React library.
import PropTypes from 'prop-types' // NPM: Prop types library.
import Markdown from 'react-markdown' // NPM: Markdown.js library
import remarkGfm from 'remark-gfm' // NPM: Remark GFM library.
import rehypeRaw from 'rehype-raw' // NPM: Re-hype raw library.
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter' // NPM: React syntax highlighter library.
import { prism } from 'react-syntax-highlighter/dist/cjs/styles/prism' // NPM: React syntax highlighter library.
import { connect } from 'react-redux' // NPM: React-redux library.
import { FiCopy } from 'react-icons/fi' // NPM: React-icons library.
import { Button, Flex } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * STYLES
 */
import './index.css'


/*
 * OBJECTS
 */
const Index = ({data}) => {
  // Const Assignment

  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const suffixes = ["th", "st", "nd", "rd"];
    const day = date.getUTCDate(); // Get the day of the month
    const month = date.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' }); // Get the short month name
    const year = date.getUTCFullYear(); // Get the full year
  
    const suffix = (day % 10 > 3 || Math.floor(day / 10) === 1) ? suffixes[0] : suffixes[day % 10];
  
    return `${day}${suffix} ${month} ${year}`;
  }

  const markdown = `
  <img src='${data?.ticketImage}' width='240px' height='240px' alt='technology Image' />

  <h1 class='heading font weight900'>${data?.ticketCategory}</h1>
  <p class='description font white weight500'><span class='font gradient position unset'>Published On : </span>${formatDate(data?.createdAt)}</p>
  
  ${data?.ticketDescription}
  `

  // Object assignment.
  const CopyCode = __children => navigator.clipboard.writeText(__children[0].props.children[0])

  // Component assignment.
  const Pre = ({ children }) => <pre className='copyCodeToClipboard'>
    <Button icon={<FiCopy className='icon stroke black' size={20} />} size={21} onClick={() => CopyCode(children)} />
    {children}
  </pre>

  // PropType assignment.
  Pre.propTypes = {
    'children': PropTypes.object.isRequired
  }

  // Return Component.
  return (
    <Flex flexDir='column' className='mdConversation'>
      <Markdown
        className='markDownContainer'
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        lineNumber={true}
        components={{
          'pre': Pre,
          code({ inline, className, children, ...props }) {
            // Get the language.
            const match = (/language-(\w+)/u).exec(className || '')

            // Return component.
            return !inline && match ? (
              <SyntaxHighlighter
                style={prism}
                language={match[1]}
                {...props}>{String(children).replace(/\n$/u, '')}</SyntaxHighlighter>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            )
          }
        }}>{markdown}</Markdown>
    </Flex>
  )
}


/*
 * REDUX
 */
const _MapStateToProps = state => ({ 'theme': state.Theme })


/*
 * EXPORTS
 */
export default connect(_MapStateToProps)(Index)

/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $orderId: String
    $paymentId: String
    $signature: String
    $installmentId: ID!
  ) {
    RazorpayPaymentUpdate(
      orderId: $orderId
      paymentId: $paymentId
      signature: $signature
      installmentId: $installmentId
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

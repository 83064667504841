
  /*
 * IMPORTS
 */
  import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

  /*
   * GRAPH
   */
  const Index = gql`
  mutation MyMutation($ticketId: ID!, $ticketStatus: Boolean!) {
      TicketStatus(ticketId: $ticketId, ticketStatus: $ticketStatus) {
        message
        status
      }
    }
  `;
  
  /*
   * EXPORTS
   */
  export default Index;
  
/*
 * IMPORTS
 */
import React from "react"; // Npm: react.js library.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import { useMutation } from "@apollo/client"; // Npm: Apollo client for GraphQL.
import { HiPencil, HiTrash } from "react-icons/hi2"; // Npm: React icons.
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlinePlusSm } from "react-icons/hi";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react"; // Npm: Chakra UI components.

/*
 * STYLES
 */
import { menuStyle } from "./index.style";

/*
 * PACKAGES
 */
import FeaturesSelectCard from "components/FeaturesSelectCard";

/*
 * MUTATION
 */
import AccountProjectDelete from "./__mutation__/index.featureRemove.mutation";
import FeatureMove from "./__mutation__/index.featureMove.mutation";

/*
 * OBJECTS
 */
const Index = ({ account, id, featureId,featureCostId, project , featuresSpecificID }) => {
  /*
   * STATE
   */
  const [featuresId, setFeaturesId] = React.useState();

  const _requestId = React.useRef("BHANU");

  const {
    isOpen: isOpenFeatureMoveModal,
    onOpen: onOpenFeatureMoveModal,
    onClose: onCloseFeatureMoveModal,
  } = useDisclosure();

  const [
    MutationAccountProjectDelete,
    { loading: LoadingAccountProjectDelete },
  ] = useMutation(AccountProjectDelete);

  const [MutationFeatureMove, { loading: LoadingFeatureMove }] = useMutation(FeatureMove);

  /*
   * FUNCTION
   */
  const _handleFeatureMove = async (id) => {
    try {
      const response = await MutationFeatureMove({
        variables: {
          featureId: featureId,
          oldFeaturesId: featuresSpecificID,
          newfeaturesId: featuresId,
          featureCostId: featureCostId,
          accountProjectId: project?.accountProjectDetails?.accountProjectId
        },
      });
      if (
        !_.isEmpty(response?.data?.FeatureMove) &&
        "PUBLISHED_SUCCESSFULLY" === response?.data?.FeatureMove?.status
      ) {
        onCloseFeatureMoveModal();
        setFeaturesId(null);
      }
    } catch (error) {
      console.log(error , "Features Move Failed");

    }
  };

  const _handleFeatureRemove = async (ID) => {
    try {
      const response = await MutationAccountProjectDelete({
        variables: {
          featureId: featureId,
          featuresId: featuresSpecificID,
          loginRequestId: _requestId?.current,
          accountProjectId: project?.accountProjectDetails?.accountProjectId, 
        },
      });
      if (
        !_.isEmpty(response?.data?.AccountProjectDelete) &&
        "PUBLISHED_SUCCESSFULLY" ===
          response?.data?.AccountProjectDelete?.status
      ) {
        console.log("Feature Removed Successfully");
      }
    } catch (error) {
      console.log(error , "Feature Removed Failed");
    }
  };

 

  return (
    <>
      <Flex>
        <Menu placement="left">
          <MenuButton
            as={Button}
            margin="auto"
            borderRadius="8px"
            color="gray.500"
            bg="transparent"
          >
            <BsThreeDotsVertical size={18} />
          </MenuButton>
          <MenuList style={menuStyle} overflow="auto">
            <MenuItem
              onClick={() => onOpenFeatureMoveModal()}
              gap="10px"
              display="flex"
              color="gray.500"
              minH="40px"
            >
              <HiPencil size={18} />
              <span>Move Feature</span>
            </MenuItem>
            <MenuItem
              gap="10px"
              display="flex"
              color="gray.500"
              minH="40px"
              onClick={() => _handleFeatureRemove(id)}
            >
              <HiTrash />
              <span>Remove Feature</span>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <FeaturesSelectCard
        isOpen={isOpenFeatureMoveModal}
        onClose={onCloseFeatureMoveModal}
        loading={LoadingFeatureMove}
        setFeaturesId={setFeaturesId}
        featuresId={featuresId}
        functionality={_handleFeatureMove}
      />
    </>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
  account: state.Account,
  project: state.Project,
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
  updateAccountProjectDetails: (accountProjectDetails) =>
    __dispatch({ type: "ACCOUNT_PROJECT_DETAILS_UPDATE", accountProjectDetails }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

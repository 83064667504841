/*
 * IMPORTS
 */
import Stats from 'three/examples/jsm/libs/stats.module' // NPM: Three.js stats library.
import {
  AmbientLight,
  CanvasTexture,
  DirectionalLight,
  Mesh,
  MeshStandardMaterial,
  Object3D,
  OctahedronGeometry,
  SphereGeometry,
  PerspectiveCamera,
  Scene,
  TextureLoader,
  WebGLRenderer,
  PointLight
} from 'three' // NPM: Three.js library.


/*
 * CLASS
 */
class AnimatedSphere {
  // Constructor.
  constructor(options) {
    // Variable assignment.
    const {
      damping,
      primaryColor,
      secondaryColor,
      ternaryColor,
      topView = true,
      container,
      debug = false
    } = options


    // Property assignment.
    this.isAnimationRunning = true
    this.autoRotateSpeed = 0.1
    this.debug = debug
    this.scene = new Scene()
    this.damping = damping
    this.camera = new PerspectiveCamera(45, window.innerWidth / 2 / window.innerHeight, 0.1, 1000)
    this.renderer = new WebGLRenderer({ 'antialias': true, 'alpha': true })
    this.renderer.setPixelRatio(window.devicePixelRatio)

    // Only initialize if mode is debug.
    if (this.debug) {
      // Const assignment.
      this.stats = new Stats()

      // Update stats properties.
      this.stats.dom.style.position = 'fixed'
      this.stats.dom.style.top = '0px'
      this.stats.dom.style.right = '0px'

      // Append stats.
      document.body.appendChild(this.stats.dom)
    }

    // Set renderer properties.
    container.appendChild(this.renderer.domElement)

    // Add class properties.
    container.classList.add('sphereLoaded')

    // Add container to the context.
    this.container = container

    // Setup lights.
    this.SetupLights()

    // Async initialization.
    this.init(primaryColor, secondaryColor, ternaryColor, topView).then(() => {
      // Setup Mouse controls.
      this.SetupMouseControls()

      // Start animation.
      this.animate()
    })

    // Event listener.
    window.addEventListener('resize', this.onWindowResize)
  }

  // Async initialization.
  async init(__primaryColor, __secondaryColor, __ternaryColor, __topView) {
    // Error handling.
    try {
      // Const assignment.
      const _textures = await this.LoadTextures()

      // Setup sphere.
      this.SetupSphere(__primaryColor, __secondaryColor, __ternaryColor, __topView, _textures)
    } catch (error) {
      // Report failure.
      throw error
    }
  }

  /*
   * LOAD TEXTURES
   * Loads up the textures for the sphere asynchronously.
   */
  async LoadTextures() {
    // Return promise.
    return new Promise(__resolve => {
      // Const assignment.
      const _textureLoader = new TextureLoader()
      const _textures = {
        'waterNormalMap': _textureLoader.load(`${process.env.PUBLIC_URL}/textures/water/normal.jpg`),
        'waterHeightMap': _textureLoader.load(`${process.env.PUBLIC_URL}/textures/water/displacement.png`),
        'waterRoughness': _textureLoader.load(`${process.env.PUBLIC_URL}/textures/water/rough.jpg`),
        'waterAmbientOcclusion': _textureLoader.load(`${process.env.PUBLIC_URL}/textures/water/occlusion.jpg`)
      }

      // Resolve texture.
      __resolve(_textures)
    })
  }


  /*
   * SETUP LIGHTS
   * Sets up the lights for the scene.
   * @return {void}
   */
  SetupLights() {
    // Add ambient light.
    this.scene.add(new AmbientLight(0x3311DB, 1.5))

    // Const assignment.
    const dirLight = new DirectionalLight(0xffffff, 20.0)

    // Update light properties.
    dirLight.position.set(20, 20, 20)
    dirLight.castShadow = false
    dirLight.shadow.mapSize.width = 1096
    dirLight.shadow.mapSize.height = 1096

    // Const assignment.
    const d = 2

    // Update shadow camera properties.
    dirLight.shadow.camera.left = d
    dirLight.shadow.camera.right = d
    dirLight.shadow.camera.top = d
    dirLight.shadow.camera.bottom = -d

    // Update target properties.
    const target = new Object3D()

    // Update target position.
    target.position.z = 20
    dirLight.target = target
    dirLight.target.updateMatrixWorld()
    dirLight.shadow.camera.lookAt(0, 0, 20)

    // Update scene with light.
    this.scene.add(dirLight)
  }


  /*
   * SETUP SPHERE
   * Sets up the sphere for the scene.
   * @param {string} primaryColor - The primary color of the sphere.
   * @param {string} secondaryColor - The secondary color of the sphere.
   * @param {string} ternaryColor - The ternary color of the sphere.
   * @param {boolean} topView - The top view of the sphere.
   * @param {object} textures - The textures of the sphere.
   * @return {void}
   */
  SetupSphere(primaryColor, secondaryColor, ternaryColor, topView, textures) {
    // Const assignment.
    const canvas = document.createElement('canvas')

    // Update canvas properties.
    canvas.width = 256
    canvas.height = 256

    // Const assignment.
    const ctx = canvas.getContext('2d')
    const gradient = ctx.createRadialGradient(128, 128, 0, 128, 128, 128)

    // Update gradient properties.
    gradient.addColorStop(0.3, primaryColor || '#0000ff')
    gradient.addColorStop(0.5, secondaryColor || '#ffffff')
    gradient.addColorStop(0.7, primaryColor || '#ffffff')
    gradient.addColorStop(0.9, ternaryColor || '#ffffff')

    // Update canvas properties.
    ctx.fillStyle = gradient
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    // Const assignment.
    const canvasTexture = new CanvasTexture(canvas)

    // Update sphere properties.
    this.geometry = new SphereGeometry(6, 120, 120)
    this.sphere = new Mesh(this.geometry, new MeshStandardMaterial({
      'map': canvasTexture,
      'normalMap': textures.waterNormalMap,
      'displacementMap': textures.waterHeightMap,
      'displacementScale': 0.1,
      'roughnessMap': textures.waterRoughness,
      'roughness': 0,
      'aoMap': textures.waterAmbientOcclusion,
      'transparent': true,
      'opacity': 1
    }))

    // Update sphere properties.
    this.sphere.receiveShadow = true
    this.sphere.castShadow = true
    this.sphere.rotation.x = topView ? -30 : -Math.PI / 1.4
    this.sphere.position.z = -30
    this.geometryPositionCount = this.geometry.attributes.position.count
    this.positionArray = JSON.parse(JSON.stringify(this.geometry.attributes.position.array))
    this.normalsArray = JSON.parse(JSON.stringify(this.geometry.attributes.normal.array))

    // Update scene with sphere.
    this.scene.add(this.sphere)
  }


  /*
   * SETUP MOUSE CONTROLS
   * Sets up the mouse controls for the scene.
   * @return {void}
   */
  SetupMouseControls() {
    // Property assignment.
    this.previousMousePosition = {
      'x': 0,
      'y': 0
    }
    this.isDragging = false

    // Object assignment.
    const onMouseMove = event => {
      // Const assignment.
      const deltaMove = {
        'x': event.clientX - this.previousMousePosition.x,
        'y': event.clientY - this.previousMousePosition.y
      }

      // Check if dragging.
      if (this.isDragging) {
        // Const assignment.
        const rotationSpeed = 0.01

        // Update sphere properties.
        this.sphere.rotation.y += deltaMove.x * rotationSpeed
        this.sphere.rotation.x += deltaMove.y * rotationSpeed
      }

      // Update mouse position.
      this.previousMousePosition = {
        'x': event.clientX,
        'y': event.clientY
      }
    }

    // Object assignment.
    const onMouseDown = event => {
      // Update dragging status.
      this.isDragging = true

      // Update mouse position.
      this.previousMousePosition = {
        'x': event.clientX,
        'y': event.clientY
      }
    }
    const onMouseUp = () => {
      // Update dragging status.
      this.isDragging = false
    }

    // Event listener.
    document.addEventListener('mousemove', onMouseMove, false)
    document.addEventListener('mousedown', onMouseDown, false)
    document.addEventListener('mouseup', onMouseUp, false)
  }


  /*
   * ANIMATE
   * Animates the sphere.
   * @return {void}
   */
  animate = () => {
    let i

    // Check if animation is running.
    if (!this.isAnimationRunning) return

    // Auto-rotate sphere.
    this.sphere.rotation.z -= this.autoRotateSpeed / 100
    this.sphere.rotation.y -= this.autoRotateSpeed / 100

    // Const assignment.
    const now = Date.now() / 200
    const { renderer, camera, scene, damping } = this

    // Iterate all vertices
    for (i = 0 ;i < this.geometryPositionCount ;i++) {
      // Const assignment.
      const ix = i * 3
      const iy = i * 3 + 1
      const iz = i * 3 + 2

      // Use uvs to calculate wave
      const uX = this.geometry.attributes.uv.getX(i) * Math.PI * 16
      const uY = this.geometry.attributes.uv.getY(i) * Math.PI * 16

      // Calculate current vertex wave height
      const _xAngle = (uX + now)
      const _xSin = Math.sin(_xAngle) * damping
      const _yAngle = (uY + now)
      const _yCos = Math.cos(_yAngle) * damping

      // Set new position
      this.geometry.attributes.position.setX(i, this.positionArray[ix] + this.normalsArray[ix] * (_xSin + _yCos))
      this.geometry.attributes.position.setY(i, this.positionArray[iy] + this.normalsArray[iy] * (_xSin + _yCos))
      this.geometry.attributes.position.setZ(i, this.positionArray[iz] + this.normalsArray[iz] * (_xSin + _yCos))
    }

    // Update position.
    this.geometry.attributes.position.needsUpdate = true

    // Update geometry vertex.
    this.geometry.computeVertexNormals()

    // Render scene.
    renderer.render(scene, camera)

    // Only initialize if mode is debug.
    if (this.debug) {
      // Update stats.
      this.stats.update()
    }

    // Request animation frame.
    requestAnimationFrame(this.animate)
  }


  /*
   * ON WINDOW RESIZE
   * Event handler for window resize.
   * @return {void}
   */
  onWindowResize = () => {
    // Update camera properties.
    this.camera.aspect = window.innerWidth / 2 / window.innerHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(window.innerWidth / 2, window.innerHeight / 2)
    this.renderer.setPixelRatio(window.devicePixelRatio)
  }


  /*
   * FADE OUT
   * Fades out the sphere and pauses the animation.
   * @return {void}
   */
  fadeOut() {
    if (this.sphere && this.sphere.material) {
      this.sphere.material.transparent = true // Enable transparency
      this.sphere.material.opacity = 0.5 // Set target opacity when faded out
      this.isAnimationRunning = false // Update animation status
    }
  }

  /*
   * FADE IN
   * Fades in the sphere and resumes the animation.
   * @return {void}
   */
  fadeIn() {
    console.log(this.sphere)
    if (this.sphere && this.sphere.material) {
      this.sphere.material.transparent = true // Enable transparency
      this.sphere.material.opacity = 1 // Set target opacity when faded in
      this.isAnimationRunning = true // Update animation status
    }
  }


  /*
   * DISPOSE
   * Disposes of the sphere.
   * @return {void}
   */
  dispose() {
    // Remove event listeners.
    window.removeEventListener('resize', this.onWindowResize)
    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('mousedown', this.onMouseDown)
    document.removeEventListener('mouseup', this.onMouseUp)

    // Stop animation.
    this.renderer.dispose()

    // Remove element from dom.
    this.renderer.domElement.remove()
  }
}


/*
 * EXPORTS
 */
export default AnimatedSphere

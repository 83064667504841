/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  mutation MyMutation(
    $displayName: String!
    $accountprojectId: ID!
  ) {
    CustomFeaturesCreate(
      input: {
        displayName: $displayName
        accountprojectId: $accountprojectId
      }
    ) {
      message
      status
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

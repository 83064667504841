/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  subscription MySubscription($featuresId: ID) {
    FeatureRead(featuresId: $featuresId) {
      days
      description
      displayName
      id
      isRecommended
      message
      status
      FeatureCost {
        cost
        id
      }
      thumbnailStoredAt
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

/*
 * IMPORT
 */
import React from "react"; // Npm: React library.
import { useHistory } from "react-router-dom"; // Npm: React router dom.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import {
  Flex,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react"; // Npm: Chakra UI components.
import { GiCelebrationFire } from "react-icons/gi"; // Npm: React icons.

/*
 * PACKAGES
 */
import TableSpinner from "components/TableSpinner";

/*
 * STYLES
 */
import { cellStyle, headerStyle, rowStyle } from "./index.style.js";

/*
 * OBJECTS
 */
const Index = ({ query, updateTicketId }) => {
  // Hook assignment.

  const _historyRef = useHistory();
  const _tableHeaderHeightRef = React.useRef(99);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const formattedDate = () =>
      date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

    return formattedDate();
  }

  // Return component.
  return (
    <TableContainer
      flex={1}
      w="100%"
      position="relative"
      borderRadius="15px"
      outline="1px solid"
      outlineColor="gray.200"
    >
      <Table variant="simple" size="md">
        <Thead ref={_tableHeaderHeightRef}>
          <Tr style={headerStyle}>
            <Td style={rowStyle} color="gray.500">
              Sr. No.
            </Td>
            <Td style={rowStyle} color="gray.500">
              Ticket ID
            </Td>
            <Td style={rowStyle} color="gray.500">
              <Text textAlign="left">Issue</Text>
            </Td>
            <Td style={rowStyle} color="gray.500">
              Ticket Category
            </Td>
            <Td style={rowStyle} color="gray.500">
              CreateAt
            </Td>
            <Td style={rowStyle} color="gray.500">
              Last Activity
            </Td>
            <Td style={rowStyle} color="gray.500">
              Status
            </Td>
          </Tr>
        </Thead>
        {0 === query?.length ? (
          <TableSpinner
            isLoading={true}
            isEmpty={true}
            title="No tickets found"
            chopHeightFromHundredPercentage={
              _tableHeaderHeightRef?.current?.clientHeight
            }
          />
        ) : (
          <Tbody style={cellStyle}>
            {query?.map((item, index) => {
              return (
                <Tr
                  key={index}
                  borderBottomWidth="1px"
                  borderColor="gray.100"
                  _hover={{ bg: "gray.100", cursor: "pointer" }}
                  onClick={() => {
                    _historyRef.push("/support/conversation");
                    updateTicketId(item?.id);
                  }}
                >
                  <Td style={rowStyle}>{`${index + 1}.`}</Td>
                  <Td style={rowStyle}>
                    <Text>{item?.ticketNumber}</Text>
                  </Td>
                  <Td style={rowStyle}>
                    <Text
                      display="flex"
                      flexDir="row"
                      alignItems="center"
                      gap="6px"
                    >
                      <Text as="span" color="red.500">
                        <GiCelebrationFire
                          color={
                            item?.ticketSeverity === 1
                              ? "red"
                              : item?.ticketSeverity === 2
                              ? "orange"
                              : "blue"
                          }
                          size={18}
                        />
                      </Text>
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: item?.ticketDescription?.slice(0, 50),
                        }}
                      />
                    </Text>
                  </Td>
                  <Td style={rowStyle}>
                    <Text>{item?.ticketCategory}</Text>
                  </Td>
                  <Td style={rowStyle}>
                    <Text>{formatDate(item?.createdAt)}</Text>
                  </Td>
                  <Td style={rowStyle}>
                    <Text>{formatDate(item?.updatedAt)}</Text>
                  </Td>
                  <Td style={rowStyle}>
                    <Text color={item?.ticketStatus ? "green" : "grey"}>
                      {item?.ticketStatus ? "Active" : "Closed"}
                    </Text>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        )}
      </Table>
    </TableContainer>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({ account: state.Account });
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
  updateTicketId: (ticketId) =>
    __dispatch({ type: "TICKET_ID_UPDATE", ticketId }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  subscription MySubscription($loginRequestId: ID!) {
    ProjectRead(loginRequestId: $loginRequestId) {
      techStack
      thumbnailStoredAtlist
      projectType
      description
      displayName
      id
      message
      status
      createdAt
      Journey {
        createdAt
        description
        displayName
        id
        Features {
          createdAt
          description
          displayName
          id
          Feature {
            days
            description
            createdAt
            displayName
            id
            FeatureCost {
              cost
              customisedCost
              createdAt
              id
            }
          }
        }
      }
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  subscription MySubscription($featureIds: [ID]!) {
    FeatureDurationRange(featureIds: $featureIds) {
      actualCost
      actualDays
      maxCost
      minDays
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

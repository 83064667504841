/*
 * IMPORTS
 */
import { gql } from "@apollo/client"; // NPM: Apollo client for handling graphql request.

/*
 * GRAPH
 */
const Index = gql`
  subscription MySubscription($skip: Int, $take: Int, $loginRequestId: ID) {
    TicketRead(skip: $skip, take: $take, loginRequestId: $loginRequestId) {
      ticketCategory
      status
      message
      id
      ticketDescription
      ticketImage
      ticketNumber
      ticketSeverity
      ticketStatus
      updatedAt
      createdAt
    }
  }
`;

/*
 * EXPORTS
 */
export default Index;

/*
 * IMPORT
 */
import "reactflow/dist/style.css"; // Npm: React Flow stylesheet.
import React from "react"; // Npm: React library.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import { HiArrowDownCircle, HiArrowUpCircle } from "react-icons/hi2"; // Npm: React icons.
import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  Image,
} from "@chakra-ui/react"; // Npm: Chakra UI components.
import { FiSearch } from "react-icons/fi"; // Npm: React icons.
import { useQuery } from "@apollo/client";

/*
 * PACKAGES
 */
import ProfileCards from "components/ProfileCards";
/*
 * STYLES
 */
import "./index.css";

import ProfileDetail from "./__query__/index.profileData.query";

/*
 * OBJECTS
 */
const Index = ({ account }) => {
  const _ProfileDetail = useQuery(ProfileDetail)

  // Return component.
  return (
    <Flex className="studio">
      <section className="gradientWrapper topRight round">
        <canvas className="gradient gradient1 color theme" />
        <div className="overlay occlusion" />
      </section>
      <Flex
        h="100%"
        flexDir="row"
        w="100%"
        alignItems="top"
        justifyContent="space-between"
        px="102px"
        py="52px"
      >
        <Flex w="100%" h="100%" flexDir="column">
          <Text
            as="h1"
            className="heading font gradient greeting"
            lineHeight="unset"
          >
            <span style={{ fontWeight: 200 }}>Profile</span>,{" "}
            <span style={{ fontWeight: 900 }}>Section</span>
          </Text>
          <Flex
            mt={42}
            zIndex={100}
            position="relative"
            justifyContent="flex-start"
            alignItems="center"
            gap="12px"
            className="stepContainer"
          >
            <Flex>
              <h3 className="subHeading width maxContent followedLine">
                Build your profile
              </h3>
            </Flex>
          </Flex>
          <Flex w="100%" gap="12px" my="42px" position="relative" zIndex={1000}>
            <Text fontSize="lg" color="gray.500">
              Exclusive Powerhouse of databases, ensuring maximum performance,
              scalability, and security for your data-driven applications and
              websites at affordable pricing.
            </Text>
          </Flex>
          <Flex
            position="absolute"
            width="10px"
            height="100%"
            boxShadow="0 0 400px 40px #FFF"
            left={0}
          />
          <ProfileCards list={_ProfileDetail?.data?.ProfileDetail} />
        </Flex>
        <Flex w="30%">
          <Flex
            as="form"
            w="100%"
            h={`calc(100vh - 300px)`}
            flexDir="column"
            borderRadius="24px"
            bg={`linear-gradient(210deg, var(--chakra-colors-primary-500) 0%, var(--chakra-colors-secondary-500) 100.1%, var(--chakra-colors-ternary-500) 200%)`}
            overflow="hidden"
            boxShadow="0 0 100px 0 rgba(0, 0, 0, 0.33)"
          >
            <Flex flexDir="column" p="32px" color="white">
              <Flex gap="12px" alignItems="center" mb="18px">
                <Text fontSize="xl">
                  {_ProfileDetail?.data?.ProfileDetail?.fullName}
                </Text>
              </Flex>
              <Text fontSize="md">
                {_ProfileDetail?.data?.ProfileDetail?.email}
              </Text>
            </Flex>
            <Flex px="32px" pb="32px" flexDir="column" gap="22px">
              <FormControl isRequired>
                <FormLabel color="white">Detailed Issue</FormLabel>
                <Textarea
                  fontSize={["Clamp(13px, 1.5vw, 15px)"]}
                  placeholder='e.g. "Payment is not processing"'
                  _placeholder={{ color: "white" }}
                  color="white"
                  name="displayName"
                  borderRadius={22}
                  border="none"
                  h="120px"
                  resize="none"
                  bg="primary.700"
                  p="26px"
                  type="textArea"
                />
              </FormControl>

              <Button
                h="52px"
                fontSize="sm"
                mt="12px"
                w="max-content"
                ml="auto"
                mr="0"
                p="22px"
                py="24px"
                color="white"
                boxShadow="0 0 100px 10px rgba(255, 255, 255, 0.032)"
                bg="primary.500"
                gap="6px"
                _hover={{ bg: "primary.700" }}
                type="submit"
              >
                Submit Ticket
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({ account: state.Account });
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);

/*
 * IMPORT
 */
import React from "react"; // Npm: React library.
import _ from "lodash"; // Npm: Underscore for utility.
import { connect } from "react-redux"; // Npm: React Redux for state management.
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  Flex,
} from "@chakra-ui/react"; // Npm: Chakra UI components.

/*
 * PACKAGES
 */
import TableSpinner from "components/TableSpinner";
import FeatureDropDown from "components/FeatureDropDown";
import {  formatCurrency  } from "components/CurrencyFormater";

/*
 * STYLES
 */
import { cellStyle, headerStyle, rowStyle } from "./index.style.js";

/*
 * OBJECTS
 */
const Index = ({ list }) => {
  // Hook assignment.
  const _tableHeaderHeightRef = React.useRef(99);

  // Return component.
  return (
    <TableContainer
      flex={1}
      minH="520px"
      position="relative"
      display="flex"
      borderRadius="15px"
      outline="1px solid"
      outlineColor="primary.600"
      backgroundColor="primary.500"
      overflowY="scroll"
    >
      <Table variant="simple" size="md">
        <Thead ref={_tableHeaderHeightRef}>
          <Tr style={headerStyle}>
            <Td style={rowStyle}>Feature Name</Td>
            <Td style={rowStyle}>Fixed Cost</Td>
            <Td style={rowStyle}>Customization</Td>
            <Td style={rowStyle}>Total Cost</Td>
            <Td style={rowStyle}>Actions</Td>
          </Tr>
        </Thead>
        {!list ? (
          <TableSpinner
            isLoading={!list}
            chopHeightFromHundredPercentage={
              _tableHeaderHeightRef?.current?.clientHeight
            }
          />
        ) : (
          <Tbody style={cellStyle}>
            {list?.map(
              (item, index) => (
                <>
                  <Tr>
                    <Td style={rowStyle}>
                      <Flex gap="20px">
                        <Flex
                          w="30px"
                          justify="center"
                          align="center"
                          h="30px"
                          bg="#190793"
                          borderRadius="50%"
                        >
                          {item?.Feature?.length}
                        </Flex>
                        {item?.displayName}
                      </Flex>
                    </Td>
                    <Td style={rowStyle}>
                      {formatCurrency(
                        _.sumBy(item?.Feature, (add) => add?.FeatureCost?.cost)
                      )}
                    </Td>
                    <Td style={rowStyle}>
                      {formatCurrency(
                        _.sumBy(
                          item?.Feature,
                          (add) => add?.FeatureCost?.customisedCost
                        )
                      )}
                    </Td>
                    <Td style={rowStyle}>
                      {formatCurrency(
                        _.sumBy(
                          item?.Feature,
                          (add) => add?.FeatureCost?.cost
                        ) +
                          _.sumBy(
                            item?.Feature,
                            (add) => add?.FeatureCost?.customisedCost
                          )
                      )}
                    </Td>
                  </Tr>
                  {item?.Feature?.map((feature, featureIndex) => (
                    <Tr key={featureIndex}>
                      <Td style={rowStyle}>{feature?.displayName}</Td>
                      <Td style={rowStyle}>
                        {formatCurrency(feature?.FeatureCost?.cost)}
                      </Td>
                      <Td style={rowStyle}>
                        {formatCurrency(feature?.FeatureCost?.customisedCost)}
                      </Td>
                      <Td style={rowStyle}>
                        {formatCurrency(
                          feature?.FeatureCost?.cost +
                            feature?.FeatureCost?.customisedCost
                        )}
                      </Td>
                      <Td style={rowStyle}>
                        <FeatureDropDown featureId={feature?.id} featureCostId={feature?.FeatureCost?.id} list={list} featuresSpecificID={item?.id} />
                      </Td>
                    </Tr>
                  ))}
                </>
              )
            )}
          </Tbody>
        )}
      </Table>
    </TableContainer>
  );
};

/*
 * REDUX
 */
const _MapStateToProps = (state) => ({
 
});
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (d) => __dispatch({ type: "ACCOUNT_UPDATE", Account: d }),
});

/*
 * EXPORTS
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
